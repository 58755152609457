<ng-container *ngIf="totalPages > 0">
  <nav aria-label="" [ngClass]="topPaginate ? 'topPaginate' : ''">
    <ul class="pagination pagination-sm justify-content-center">
      <li [className]="hasPrevious() ? 'page-item' : 'page-item disabled'">
        <a
          (click)="setPage(currentPage - 1)"
          class="page-link"
          href="javascript:void(0);"
        >
          <i class="fas fa-chevron-left"></i>
        </a>
      </li>
      <ng-container *ngFor="let page of pages">
        <li *ngIf="page !== currentPage" class="page-item">
          <a
            (click)="setPage(page)"
            class="page-link"
            href="javascript:void(0);"
          >
            {{ page }}
          </a>
        </li>
        <li
          *ngIf="page === currentPage"
          aria-current="page"
          class="page-item active"
        >
          <span class="page-link">
            {{ page }}
            <span class="sr-only">({{ "current" | translate: "fe" }})</span>
          </span>
        </li>
      </ng-container>
      <li [className]="hasNext() ? 'page-item' : 'page-item disabled'">
        <a
          (click)="setPage(currentPage + 1)"
          class="page-link"
          href="javascript:void(0);"
        >
          <i class="fas fa-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</ng-container>
