import { Injectable } from '@angular/core';
import * as parser from 'cron-parser';

import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { ParsedCron } from '../models/parsedCron.model';
import { CronJobsFrequency } from '../contracts/contracts';

enum periods {
  weekly = 0,
  monthly = 1,
  yearly = 2,
  every_minute = 3,
  hourly = 4,
  daily = 5
}

@Injectable({
  providedIn: 'root',
})
export class CronService {
  private readonly hoursInOneDay: number = 24;
  private readonly saturdayNumber: number = 6;
  private readonly weekDays = [
    this._translate.transform('sunday', 'labels').toLowerCase(),
    this._translate.transform('monday', 'labels').toLowerCase(),
    this._translate.transform('tuesday', 'labels').toLowerCase(),
    this._translate.transform('wednesday', 'labels').toLowerCase(),
    this._translate.transform('thursday', 'labels').toLowerCase(),
    this._translate.transform('friday', 'labels').toLowerCase(),
    this._translate.transform('saturday', 'labels').toLowerCase(),
  ];
  private readonly periodicity = [
    this._translate.transform('weekly'),
    this._translate.transform('monthly'),
    this._translate.transform('yearly'),
    this._translate.transform('every_minute'),
    this._translate.transform('every_hour'),
    this._translate.transform('daily')
  ];
  private readonly months = [
    this._translate.transform('Form_crontab_january', 'fe'),
    this._translate.transform('Form_crontab_february', 'fe'),
    this._translate.transform('Form_crontab_march', 'fe'),
    this._translate.transform('Form_crontab_april', 'fe'),
    this._translate.transform('Form_crontab_may', 'fe'),
    this._translate.transform('Form_crontab_june', 'fe'),
    this._translate.transform('Form_crontab_july', 'fe'),
    this._translate.transform('Form_crontab_august', 'fe'),
    this._translate.transform('Form_crontab_september', 'fe'),
    this._translate.transform('Form_crontab_october', 'fe'),
    this._translate.transform('Form_crontab_november', 'fe'),
    this._translate.transform('Form_crontab_december', 'fe'),
  ];

  constructor(private _translate: TranslatePipe) {}

  public adjustHourOverflow(freq: CronJobsFrequency): CronJobsFrequency {
    if (freq.hours[0] < 0) {
      freq.hours = [this.hoursInOneDay + freq.hours.shift()];
      freq.daysOfWeek.forEach((weekDay) => {
        if (weekDay === 0) {
          weekDay = this.saturdayNumber;
        } else {
          weekDay = weekDay - 1;
        }
      });
    }
    if (freq.hours[0] >= this.hoursInOneDay) {
      freq.hours = [freq.hours.shift() - this.hoursInOneDay];
      freq.daysOfWeek.forEach((weekDay) => {
        if (weekDay === this.saturdayNumber) {
          weekDay = 0;
        } else {
          weekDay = weekDay + 1;
        }
      });
    }
    return freq;
  }

  public addZeroToOneDigitNumber(number: number | string): number | string {
    if (number < 10) number = `0${number}`;
    return number;
  }

  public makeCronReadable(propertyName: string, dataArray: any[]): any[] {
    const cronData: ParsedCron = new ParsedCron();

    dataArray?.forEach((data) => {
      if (data[propertyName]) {
        try {
          cronData.periodicity = this._getPeriod(data[propertyName]);
          cronData.cronValue = parser.parseExpression(data[propertyName]);
          cronData.parsedDate = new Date(cronData.cronValue.next().toString());
          cronData.timeZoneCorrectDate = this._adjustTimezone(cronData.parsedDate);
          cronData.dayOfWeek = this._getWeekDays(cronData.cronValue, cronData.parsedDate, cronData.timeZoneCorrectDate);
          cronData.monthDays = this._getMonthDays(cronData.cronValue, cronData.parsedDate, cronData.timeZoneCorrectDate);
          cronData.hours = cronData.timeZoneCorrectDate.getHours();
          cronData.minutes = cronData.timeZoneCorrectDate.getMinutes();

          cronData.hours = this.addZeroToOneDigitNumber(cronData.hours);
          cronData.minutes = this.addZeroToOneDigitNumber(cronData.minutes);
          this._setString(data, propertyName, cronData);
        } catch (error) {}
      }
    });
    return dataArray;
  }

  private _getWeekDays(cronValue: any, cronDate: Date, timezoneCorrectDate: Date): string {
    if (!(cronValue && cronValue._fields)) { return ''; }
    const selectedWeekDays = cronValue._fields.dayOfWeek;
    const readableDays = [];
    if (selectedWeekDays.length > 1 && selectedWeekDays.length < 7) {
      selectedWeekDays.forEach((day) => {
        readableDays.push(this.weekDays[day].substring(0, 3));
      });
      return readableDays.join(', ');
    }
    return this.weekDays[
      selectedWeekDays.shift() -
        (cronDate.getDate() - timezoneCorrectDate.getDate())
    ];
  }

  private _getMonthDays(cronValue: any, cronDate: Date, timezoneCorrectDate: Date): string {
    if (!(cronValue && cronValue._fields)) { return ''; }
    const selectedMonthDays = cronValue._fields.dayOfMonth;
    const readableDays: Array<string> = [];
    if (selectedMonthDays.length > 1 && selectedMonthDays.length < 32) {
      selectedMonthDays.forEach((day) => {
        readableDays.push(day + this._getNumberSuffix(day));
      });
      return readableDays.join(', ');
    }
    readableDays.push((selectedMonthDays.shift()).toString());
    return readableDays[0] + this._getNumberSuffix(+readableDays[0]);
  }

  private _getPeriod(cron): ParsedCron['periodicity'] {
    const numberOfAsterisks = cron.split('*').length - 1;
    let periodicity: string;
    let period: number;
    let isFinalCharacterNumber: number | boolean = +cron[cron.length - 1];

    if (isFinalCharacterNumber === 0) {
      isFinalCharacterNumber = true;
    }
    if (numberOfAsterisks === 2 && isFinalCharacterNumber) {
      periodicity = this.periodicity[0];
      period = periods.weekly;
    }
    if (numberOfAsterisks === 2 && !isFinalCharacterNumber) {
      periodicity = this.periodicity[1];
      period = periods.monthly;
    }
    if (numberOfAsterisks === 1) {
      periodicity = this.periodicity[2];
      period = periods.yearly;
    }
    if (numberOfAsterisks === 5) {
      periodicity = this.periodicity[3];
      period = periods.every_minute;
    }
    if (numberOfAsterisks === 4) {
      periodicity = this.periodicity[4];
      period = periods.hourly;
    }
    if (numberOfAsterisks === 3) {
      periodicity = this.periodicity[5];
      period = periods.daily;
    }
    return {
      periodicity,
      period
    };
  }

  private _setString(data: any, propertyName: string, cronData: ParsedCron): void {
    const numberSuffix: string = this._getNumberSuffix(cronData.parsedDate.getDate());

    switch (cronData.periodicity.period) {
      case periods.hourly:
        data[propertyName] = cronData.periodicity.periodicity
          .replace('%s', cronData.minutes.toString());
        break;
      case periods.daily:
        data[propertyName] = cronData.periodicity.periodicity
          .replace('%s', cronData.hours.toString())
          .replace('%s', cronData.minutes.toString());
        break;
      case periods.weekly:
        data[propertyName] = cronData.periodicity.periodicity
          .replace('%s', cronData.dayOfWeek)
          .replace('%s', cronData.hours.toString())
          .replace('%s', cronData.minutes.toString());
        break;
      case periods.monthly:
        data[propertyName] = cronData.periodicity.periodicity
          .replace('%s', cronData.monthDays)
          .replace('%s', cronData.hours.toString())
          .replace('%s', cronData.minutes.toString());
        break;
      case periods.yearly:
        cronData.hours = this.addZeroToOneDigitNumber(cronData.hours);
        cronData.minutes = this.addZeroToOneDigitNumber(cronData.minutes);
        data[propertyName] = cronData.periodicity.periodicity
          .replace('%s', cronData.parsedDate.getDate().toString())
          .replace('%s', this.months[cronData.parsedDate.getMonth()])
          .replace('%s', cronData.hours.toString())
          .replace('%s', cronData.minutes.toString())
          .replace('%d', numberSuffix);
        break;
      default:
        data[propertyName] = `
          ${cronData.periodicity.periodicity}
        `;
        break;
    }
  }

  private _getNumberSuffix(number: number): string {
    switch (number) {
      case 1:
        return 'st';
        break;
      case 2:
        return 'nd';
        break;
      case 3:
        return 'rd';
        break;
      default:
        return 'th';
        break;
    }
  }

  private _adjustTimezone(date: Date): Date {
    date = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const minutesInAnHour = 60;
    const differenceInHours = timezoneOffset / minutesInAnHour;
    date.setHours(date.getHours() - differenceInHours);
    return date;
  }
}
