import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStart, Router } from '@angular/router';
import { ModalActionModel } from '../../../_models/modal/modal-action.model';
import { ModalReasonModel } from '../../../_models/modal/modal-reason.model';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-ui-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
})
export class UiModalFormComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public smallTitle: string;
  @Input() public explanationTitle: string;
  @Input() public valid = true;
  @Input() public readonly = false;
  @Input() public confirm = false;
  @Input() public btnSave = true;
  @Input() public btnSaveText: 'btn_update' | 'btn_save' = 'btn_save';
  @Input() public btnCancelText: 'btn_cancel' | 'btn_close' = 'btn_cancel';
  @Input() public btnCancelMain = false;
  @Input() public buttonLoading: boolean;
  @Input() public buttonDisabled = false;
  @Input() public skipAllTouchCheck = false;
  @Output() public modalEvent = new EventEmitter<string>();
  @ViewChild('modalContent')
  private _modalContent: ElementRef;
  private _currentUrl: string = this.router.url;
  private _sweetAlert = new SweetAlertService();
  @Input() public loading = false;
  public emitBeforeClosing = false;

  constructor(
    protected _ngbModal: NgbModal,
    public router: Router,
    public activeModal: NgbActiveModal
  ) {
    this.listenToRoute();
  }

  ngAfterViewInit(): void {
    this._focusOnFirstField();

    if (this.readonly) {
      this._disableAllInputs();
    }
  }

  protected _confirmDiscard(action: ModalActionModel): void {
    this._sweetAlert.alert(
      {
        title: 'confirmDelete',
        text: 'confirmDiscard',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          this._closeModal(action);
        }
      }
    );
  }

  protected _closeModal(action: ModalActionModel): void {
    if (this.emitBeforeClosing) {
      this.modalEvent.emit(action);
    }
    switch (action) {
      case ModalActionModel.CLOSE:
        this.activeModal.dismiss(ModalReasonModel.CLOSE);
        break;
      case ModalActionModel.CANCEL:
        this.activeModal.dismiss(ModalReasonModel.CANCEL);
        break;
      case ModalActionModel.SAVE:
        break;
    }
    if (!this.emitBeforeClosing) {
      this.modalEvent.emit(action);
    }
  }

  public save(): void {
    if (!this.skipAllTouchCheck) {
      this._makeAllTouched();
    }
    this._closeModal(ModalActionModel.SAVE);
  }

  public close(): void {
    if (!this.readonly && this.confirm) {
      this._confirmDiscard(ModalActionModel.CLOSE);
    } else {
      this._closeModal(ModalActionModel.CLOSE);
    }
  }

  public cancel(): void {
    if (!this.readonly && this.confirm) {
      this._confirmDiscard(ModalActionModel.CANCEL);
    } else {
      this._closeModal(ModalActionModel.CANCEL);
    }
  }

  public listenToRoute(): void {
    this.router.events.forEach((event) => {
      if (
        event instanceof NavigationStart &&
        !event.url.includes(this._currentUrl)
      ) {
        this._closeModal(ModalActionModel.CLOSE);
      }
    });
  }

  private _makeAllTouched(): void {
    const formControls = document.querySelectorAll('.form-control');
    Array.from(formControls).forEach((field) => {
      field.classList.add('submitted-input');
    });
  }

  private _focusOnFirstField(): void {
    const firstInput = this._getFirstInput();
    if (firstInput) {
      firstInput.focus();
    }
  }

  private _getFirstInput(): HTMLInputElement {
    const modalContent = this._modalContent.nativeElement;
    const nonCheckboxInput = 'input:not([type="radio"]):not([type="checkbox"])';

    return (
      modalContent.querySelector(nonCheckboxInput) ||
      modalContent.querySelector('select')
    );
  }

  private _disableAllInputs(): void {
    setTimeout(() => {
      const modalContent = this._modalContent.nativeElement;
      const form = modalContent.querySelector('form');
      if (!form) {
        return;
      }

      const formElements = Array.from(form.elements);
      formElements.forEach((element: HTMLInputElement) => {
        element.disabled = true;
      });
    });
  }
}
