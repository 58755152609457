import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { AclService } from '../services/acl.service';
import { StorageService } from '../../../_services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AclGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _aclService: AclService,
    private _storageService: StorageService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;
    const locale = route.data.acl.locale;
    const permission = route.data.acl.permission;

    return this.checkAccess(locale, permission, url);
  }

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    const url = window.location.pathname;
    const locale = route.data.acl.locale;
    const permission = route.data.acl.permission;

    return this.checkAccess(locale, permission, url);
  }

  public checkAccess(locale, permission, url): boolean {
    if (permission === 'free') {
      return true;
    }

    if (permission === 'loggedIn') {
      return this.checkLoggedIn(url);
    }

    if (
      locale === undefined ||
      permission === undefined ||
      !this._aclService.can(permission, locale, url)
    ) {
      this._router.navigate(['/error/not-authorized']);
      return false;
    }

    return true;
  }

  public checkLoggedIn(url): boolean {
    const hasToken = this._storageService.getItem('token') !== null;

    if (!hasToken) {
      this._router.navigate(['/login'], { queryParams: { returnUrl: url } });
      return false;
    }

    return true;
  }
}
