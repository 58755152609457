import { isInteger } from 'src/app/core/_helpers/util';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {
}

export class DateTimeModel implements NgbDateTimeStruct {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  timeZoneOffset: number;
  dateObject: Date;

  constructor(date?: Partial<DateTimeModel>) {
    Object.assign(this, date);
  }

  public static fromLocalString(dateString: string): DateTimeModel {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);
    return DateTimeModel.fromDate(date);
  }

  public static fromNow(): DateTimeModel {
    return DateTimeModel.fromDate(new Date());
  }

  /**
   * Transforma uma string de data em um objeto DateTimeModel
   * Este método permite converter datas em formatos diferentes
   * Este método sempre considera que a data está no formato UTC
   *
   * @param dateString String de data a ser convertida
   * @param format Formato da string de data
   * @returns DateTimeModel
   */
  public static fromFormat(dateString: string, format: string): DateTimeModel {
    const date = Moment.utc(dateString, format).toDate();
    return DateTimeModel.fromDate(date);
  }

  public static fromDate(date: Date): DateTimeModel {
    const invalidDate = isNaN(date.valueOf());
    if (invalidDate) {
      return null;
    }

    return new DateTimeModel({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
      timeZoneOffset: date.getTimezoneOffset(),
      dateObject: date,
    });
  }

  public static fromUnixTimeStamp(timestamp: number): DateTimeModel {
    if (isNaN(timestamp)) {
      return null;
    }

    const date = new Date(timestamp * 1000);
    return DateTimeModel.fromDate(date);
  }

  format(format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    const date = Moment(this.dateObject);
    return date.format(format);
  }

  subtract(amount?: DurationInputArg1, unit?: DurationInputArg2): DateTimeModel {
    const date = Moment(this.dateObject);
    return DateTimeModel.fromDate(
      date.subtract(amount, unit).toDate(),
    );
  }

  add(amount?: DurationInputArg1, unit?: DurationInputArg2): DateTimeModel {
    const date = Moment(this.dateObject);
    return DateTimeModel.fromDate(
      date.add(amount, unit).toDate(),
    );
  }

  toString(): string {
    if (isInteger(this.year) && isInteger(this.month) && isInteger(this.day)) {
      const year = this.year.toString().padStart(2, '0');
      const month = this.month.toString().padStart(2, '0');
      const day = this.day.toString().padStart(2, '0');

      if (!this.hour) {
        this.hour = 0;
      }
      if (!this.minute) {
        this.minute = 0;
      }
      if (!this.second) {
        this.second = 0;
      }
      if (!this.timeZoneOffset) {
        this.timeZoneOffset = new Date().getTimezoneOffset();
      }

      const hour = this.hour.toString().padStart(2, '0');
      const min = this.minute.toString().padStart(2, '0');
      const sec = this.second.toString().padStart(2, '0');

      const tzo = -this.timeZoneOffset;
      const dif = tzo >= 0 ? '+' : '-';
      const pad = (num) => {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

      const isoStr = `${pad(year)}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(min)}:${pad(sec)}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
      return isoStr;
    }

    return null;
  }
}
