import { TranslatePipe } from '../../../../core/_pipes/pipes/translate.pipe';
import { EmailAction, EmailActionOption } from '../../models/email-action.model';
import { Injectable } from '@angular/core';

export enum EmailActionLocation {
  READ_MESSAGE,
  TRACKER_LIST,
}

@Injectable({
  providedIn: 'root',
})
export class EmailActionService {
  constructor(private _translate: TranslatePipe) {
  }

  public getOption(location: EmailActionLocation = EmailActionLocation.READ_MESSAGE): EmailActionOption[] {
    const listOptions = [];
    if (location === EmailActionLocation.TRACKER_LIST) {
      listOptions.push({
        id: EmailAction.ExportData,
        name: this._translate.transform('btn_export_data', 'labels'),
      });
    }

    return listOptions.concat([
      {
        id: EmailAction.Release,
        name: this._translate.transform('release', 'labels'),
      },
      {
        id: EmailAction.ReleaseAV,
        name: this._translate.transform('releaseAv', 'labels'),
      },
      {
        id: EmailAction.Delete,
        name: this._translate.transform('delete'),
      },
      {
        id: EmailAction.Redirect,
        name: this._translate.transform('rules_redirect'),
      },
      {
        id: EmailAction.Phishing,
        name: this._translate.transform('report') + ' Phishing',
      },
      {
        id: EmailAction.Spam,
        name: this._translate.transform('report') + ' Spam',
      },
      {
        id: EmailAction.NotSpam,
        name: this._translate.transform('report') + ' Not-Spam',
      },
      {
        id: EmailAction.SenderBlocked,
        name: this._translate.transform('senderToBlockedList', 'labels'),
      },
      {
        id: EmailAction.SenderTrusted,
        name: this._translate.transform('senderToTrustedList', 'labels'),
      },
    ]);
  }
}
