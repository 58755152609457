import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class UiPaginationComponent implements OnChanges {
  @Input() public totalPages;
  @Input() public currentPage;
  @Input() public topPaginate: boolean;
  @Output() public requestPage = new EventEmitter();

  public pages = [];
  public pagesAvailable = 5;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage || (changes.totalPages && changes.totalPages.currentValue > 0)) {
      this.pages = [];
      this.pages.push(
        this.currentPage
          ? this.currentPage
          : 1,
      );
      this.addPages();
    }
  }

  public addPages(order = 'after') {
    this.pages.sort((a, b) => a - b);
    if (this.pages.length === this.pagesAvailable || this.pages.length === this.totalPages) {
      return;
    }

    if (order === 'before') {
      const onePrevious = this.pages[0] - 1;
      if (onePrevious > 0) {
        this.pages.push(onePrevious);
      }
      this.addPages('after');
    }

    if (order === 'after') {
      const lastKey = this.pages.length - 1;
      const oneNext = this.pages[lastKey] + 1;

      if (oneNext <= this.totalPages) {
        this.pages.push(oneNext);
      }
      this.addPages('before');
    }
  }

  public setPage(page) {
    this.currentPage = page;
    this.requestPage.emit(page);
    this.currentPage = page;
  }

  public hasPrevious() {
    return this.currentPage > 1;
  }

  public hasNext() {
    return this.currentPage < this.totalPages;
  }
}
