import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseFormWithRootService } from '../../../../../core/_ui/components/form/base-form-with-root.service';

@Injectable({
  providedIn: 'root',
})
export class EmailFlowInboundService extends BaseFormWithRootService {

  constructor(protected _http: HttpClient) {
    super(_http);
    super.setUrlPath('/v1/inbound');
  }

  public getInbound(perPage: number = null): Observable<any> {
    return this._http.get(perPage ? this.urlPath + '?per-page=' + perPage : this.urlPath);
  }

  public automaticServers(): Observable<any> {
    return this._http.post(this.urlPath + '/automatic-servers', {});
  }

}
