import { parse } from 'url';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthenticationService } from '../_services/authentication.service';
import { copyWithoutReference } from './util';

export interface ErrorInterceptorResponse {
  message: string;
  arrayErrorMessages?: string[] | string;
  code: number;
  getOriginalResponse?: () => HttpErrorResponse;
  others?: ErrorInterceptorResponse | any;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private error: ErrorInterceptorResponse | null = null;
  private requestUrl: any;

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.requestUrl = parse(request.url, false, true);

    return next.handle(request).pipe(
      catchError((err) => {
        this.error = null;

        switch (err.status) {
          case 401:
            this.error401(err);
            break;
          case 400:
          case 403:
            this.error40X(err);
            break;
          case 500:
            this.error500(err);
            break;
          default:
            this.defaultError(err);
            break;
        }

        return throwError(() => this.error);
      }),
    );
  }

  error401(err) {
    if (this.requestUrl.href.includes('/v1/set-password')) {
      return;
    }

    if (this.requestUrl.href.indexOf('/v1/login') !== -1) {
      this.error40X(err, true);
    } else {
      this.authenticationService.logout();
      location.reload();
    }
  }

  error500(err) {
    this.error40X(err, true);
  }

  error40X(err, useStatusText: boolean = false) {
    const errors = this.getErrorMessages(err.error);
    if (errors !== null && typeof errors === 'object') {
      const arrayErrorMessages = this.generateErrorsList(errors);
      if (useStatusText && err.statusText) {
        arrayErrorMessages.unshift(err.statusText);
      }
      this.setError(err, arrayErrorMessages);
    } else if (useStatusText && err.statusText) {
      this.setError(err, [err.statusText]);
    } else {
      this.defaultError(err);
    }
  }

  defaultError(err) {
    const error2 = copyWithoutReference(err.error);
    const localError: Partial<ErrorInterceptorResponse> = {};
    if (error2.message) {
      localError.message = error2.message;
      // localError['message'] = error2.message;
      delete error2.message;
    } else {
      localError.message = error2.messages;
      // localError['message'] = error2.messages;
      delete error2.messages;
    }
    if (error2.code) {
      localError.code = error2.code;
      // localError['code'] = error2.code;
      delete error2.code;
    } else {
      localError.code = err.status;
      // localError['code'] = err.status;
    }
    // localError['others'] = error2;
    localError.others = error2;

    this.error = localError as ErrorInterceptorResponse;
  }

  private generateErrorsList(error): any[] {
    if (typeof error === 'string') {
      return [error];
    }

    const arrayErrorMessages = [];
    const errorClone = [];

    Object.keys(error).forEach((key) => {
      if (!Array.isArray(error[key])) {
        errorClone[key] = [error[key]];
      } else {
        errorClone[key] = error[key];
      }

      errorClone[key].forEach((er) => {
        arrayErrorMessages.push(er);
      });
    });

    return arrayErrorMessages;
  }

  private getErrorMessages(error: any): string[] {
    let errorMessages = [];
    for (const errorType of [
      'errors',
      'messages',
      'message',
    ]) {
      if (errorMessages.length === 0) {
        errorMessages = error[errorType] ?? [];
        if (errorMessages !== null && typeof errorMessages === 'string') {
          errorMessages = [errorMessages];
        }
      }
    }
    return errorMessages;
  }

  private setError(err: any, arrayErrorMessages: any[] = []) {
    this.error = {
      message: err.error.messages ?? err.error.message,
      arrayErrorMessages,
      code: err.status,
      getOriginalResponse: () => err,
    };
  }
}
