import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

import { Fields } from '../../models/archive-message';
import {
  MessageLocale,
  MessageType,
} from '../../../shared/message/models/message.model';
import { MessageService } from '../../../shared/message/service/message.service';
import { QuarantineService } from '../../services/quarantine/quarantine.service';
import { OriginalComponent } from '../email-original/original.component';
import { SqlDateModel } from '../../../../core/_models/sql-date/sql-date.model';
import { Flag } from '../../../administration/archive/flags/models/flag';
import { modalDefaultOptions } from '../../../../core/_models/modal/modal-default-options.model';

@Component({
  selector: 'app-email-read',
  templateUrl: './email-read.component.html',
  styleUrls: ['./email-read.component.scss'],
})
export class EmailReadComponent implements OnInit {
  @Input() public emailId: any;
  @Input() public id: any;
  public emailLoaded = false;
  public emailService$: Observable<Fields>;
  public email: Fields;
  public selectedEmails: any;
  public successMessage: string;
  public errorMessage: any;
  public sqlDateModel = SqlDateModel;
  public messageLocale = MessageLocale.modal;

  constructor(
    private _ngbModal: NgbModal,
    private quarantineService: QuarantineService,
    public ngbActiveModal: NgbActiveModal,
    private _messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.emailService$ = this.quarantineService.getEmail(this.emailId);
    this.getEmail();
  }

  public getEmail(): void {
    this.emailService$.subscribe((response) => {
      this.setEmailResponse(response);
    });
  }

  public setEmailResponse(response: Fields): void {
    this.email = response;
    this.setAttachmentNames();

    this.selectedEmails = [
      {
        esId: this.id,
        id: this.emailId,
        currentFlags: this.emailFlags,
      },
    ];
    this.emailLoaded = true;
  }

  public setAttachmentNames(): void {
    if (
      this.email.attachments.length > 0 &&
      !this.email.attachments[0]['message']
    ) {
      this.email.attachments.forEach((attachment) => {
        if (attachment.name) {
          const nameWithoutExtension: string[] = attachment.name.split('.');
          nameWithoutExtension?.pop();
          nameWithoutExtension?.join('');

          if (nameWithoutExtension[0]?.length > 15) {
            const extension = attachment.name.split('.').pop();
            return (attachment.shortname = `
            ${nameWithoutExtension[0].substring(0, 15)}...${extension}
          `);
          }

          attachment.shortname = attachment.name;
        }
      });
    }
  }

  public onSaveChange(event): void {
    this._messageService.resetMessageService();

    this.successMessage = event.successMessage;
    this._setError(event.errorMessage);

    if (event.removed) {
      this.ngbActiveModal.close(event);
    }

    if (this.successMessage) {
      this._messageService.setMessageConfig(
        this.successMessage,
        MessageType.success,
        MessageLocale.modal,
      );
    }
  }

  public openModal(emailId): void {
    const dialog: NgbModalRef = this._ngbModal.open(
      OriginalComponent,
      modalDefaultOptions,
    );
    dialog.componentInstance.emailId = emailId;
  }

  public get emailFlags(): Flag[] {
    return this.email.flags;
  }

  private _setError(message): void {
    if (this.errorMessage) {
      this._messageService.setMessageConfig(
        message,
        MessageType.error,
        MessageLocale.modal,
      );
    }
  }
}
