<ng-container [formGroup]="control">
  <mat-form-field
    appearance="fill"
    [ngClass]="{ error: formService.hasError(control, id) }"
    floatLabel="always"
  >
    <mat-select
      [formControlName]="id"
      [id]="id"
      [attr.disabled]="getReadonly"
      [disabled]="disabled"
      [placeholder]="placeholder"
      (selectionChange)="onChange($event)"
      #matSelect
    >
      <mat-option *ngFor="let item of collection" [value]="item.value">
        {{ getLabel(item) | translate: 'labels' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-ui-error-form
    [hasError]="formService.hasError(control, id)"
    [control]="control.controls[id]"
  ></app-ui-error-form>
</ng-container>
