<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ title | translate }}
    <small *ngIf="readonly"> - {{ 'readonly' | translate }} </small>
    <small *ngIf="smallTitle"> - {{ smallTitle }} </small><br />
    <label
      *ngIf="explanationTitle"
      [ngClass]="{ description: 'font-weight-normal' }"
      >{{ explanationTitle | translate: 'labels' }}</label
    >
  </h4>

  <button
    (click)="close()"
    aria-label="Close"
    class="close"
    type="button"
    [disabled]="buttonLoading"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div #modalContent class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button
    (click)="cancel()"
    [class.btn-light]="!(readonly || btnCancelMain)"
    [class.btn-primary]="readonly || btnCancelMain"
    [class.cancel-btn]="!(readonly || btnCancelMain)"
    class="btn"
    type="button"
    [disabled]="buttonLoading"
  >
    {{ btnCancelText | translate }}
  </button>

  <button
    (click)="save()"
    *ngIf="!readonly && btnSave"
    [attr.data-cy]="'btnAdd' + title"
    [disabled]="!valid || buttonLoading || buttonDisabled"
    class="btn btn-primary"
    type="button"
  >
    <ng-container *ngIf="!buttonLoading">
      {{ btnSaveText | translate }}
    </ng-container>
    <img
      class="loading-spinner"
      *ngIf="buttonLoading"
      src="../../../../../assets/icones/general/btn-loading.svg"
    />
  </button>
</div>
