import { Subscription } from 'rxjs';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AppInjector } from '../../../../app-injector.service';
import { MessageLocale, MessageType } from '../../../../gws/shared/message/models/message.model';
import { cleanList, FieldListModel } from '../../models/list/field-list.model';
import { MessageService } from '../../../../gws/shared/message/service/message.service';
import { CsvComponent } from '../../../../gws/shared/import-source/component/upload-csv/csv.component';
import { modalDefaultOptions } from '../../../_models/modal/modal-default-options.model';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';
import { ColumnsTypeModel } from '../../../../gws/shared/list/models/columns-type.model';
import { ListActionModel } from '../../../_models/list/list-action.model';
import { isRoot } from '../../../_helpers/util';

@Directive()
export abstract class BaseListDirective implements OnInit, OnDestroy {
  private _messageSubscribe$: Subscription;
  private _defaultPageSize = 10;
  private _sweetAlert = new SweetAlertService();
  public reloadOnModalClose = false;
  public loading = true;
  public list: FieldListModel;
  public component;
  public dialog: NgbModalRef;
  public currentPageSize = this._defaultPageSize;
  public currentPage = 1;
  public currentFilter: string;
  public modalOptions = modalDefaultOptions;

  public get isRoot() {
    return isRoot();
  }

  protected _messageService: MessageService;

  protected constructor(protected _ngbModal: NgbModal, protected _service) {
    const injector = AppInjector.getInjector();
    this._messageService = injector?.get(MessageService);
    this._messageService?.resetMessageService();
  }

  public abstract get columns(): ColumnsTypeModel[];

  ngOnInit(): void {
    this.getList();
    this.setSubscribes();
  }

  ngOnDestroy(): void {
    this._messageSubscribe$?.unsubscribe();
  }

  public setSubscribes(): void {
    this._messageSubscribe$ = this._messageService?.messageConfig.subscribe(
      (messageConfig) => {
        if (this._messageService.checkHasMessage(messageConfig, 'list')) {
          this.getList(this.currentPage);
          this.dialog && this.dialog.close();
        }
      },
    );
  }

  public getList(page = 1, pageSize?: number) {
    this.loading = true;
    this.currentPageSize = pageSize || this.currentPageSize;

    if (this.currentFilter) {
      return this.search({
        filter: this.currentFilter,
        page: this.currentPage,
      });
    }

    this._service.getList(page, this.currentPageSize).subscribe({
      next: (list) => {
        this.list = list;
        this.loading = false;
        this.getListCallback();
      },
      error: () => {
        this.list = cleanList;
        this.loading = false;
      },
    });
  }

  public delete(id): void {
    this._sweetAlert.alert(
      {
        title: 'delete',
        text: 'confirmDelete',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this._service
            .delete(id)
            .toPromise()
            .then((res) => {
              this.defaultSuccess(res);
            })
            .catch((error) => {
              this.defaultError(error);
            });
        },
      },
      (result) => {
      },
    );
  }

  public defaultSuccess(res) {
    if (this.list.rows.length > 1 || this.currentPage === 1) {
      this.getList(this.currentPage);
    } else {
      this.currentPage--;
      this.getList(this.currentPage);
    }
    this._messageService?.setMessageConfig(res.messages || res.message);
  }

  public defaultError(error) {
    if (error.message || error.messages) {
      this._messageService.setMessageConfig(
        error.message || error.messages,
        MessageType.error,
        MessageLocale.list,
      );
    }
    if (error.arrayErrorMessages && error.arrayErrorMessages.length > 0) {
      this._messageService.setMessageConfig(
        error.arrayErrorMessages,
        MessageType.error,
        MessageLocale.list,
      );
    }
  }

  public signInCustomer(id): void {
    this._sweetAlert.alert(
      {
        title: 'tooltip_signin_customer',
        text: 'confirmDelete',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          this._service.authInCustomer(id).subscribe({
            next: (success) => {
              this._messageService?.setMessageConfig(
                success.messages || success.message,
              );
            },
            error: (error) => {
              this._messageService?.setMessageConfig(
                error['arrayErrorMessages'] ||
                error['message'] ||
                error['messages'],
                MessageType.error,
              );
            },
          });
        }
      },
    );
  }

  public resetPassword(id): void {
    this._sweetAlert.alert(
      {
        title: 'tooltip_reset_password',
        text: 'confirmDelete',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          this._service.resetPassword(id).subscribe({
            next: (success) => {
              this._messageService?.setMessageConfig(
                success.messages || success.message,
              );
            },
            error: (error) => {
              this._messageService?.setMessageConfig(
                error['arrayErrorMessages'] ||
                error['message'] ||
                error['messages'],
                MessageType.error,
              );
            },
          });
        }
      },
    );
  }

  public unlockAdmin(id): void {
    this._sweetAlert.alert(
      {
        title: 'tooltip_unlock_admin',
        text: 'confirmDelete',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          this._service.unlockAdmin(id).subscribe({
            next: (success) => {
              this._messageService?.setMessageConfig(
                success.messages || success.message
              );
            },
            error: (error) => {
              this._messageService?.setMessageConfig(
                error['arrayErrorMessages'] ||
                error['message'] ||
                error['messages'],
                MessageType.error
              );
            },
          });
        }
      }
    );
  }

  public manipulateRecord(id: number | string | null = null, copy: boolean = false, readonly: boolean = false): void {
    const dialog: NgbModalRef = this._ngbModal.open(
      this.component,
      this.modalOptions,
    );

    dialog.componentInstance.permissions = this.list.permissions;
    dialog.componentInstance.readonly = readonly;

    if (id !== null) {
      const locate = copy ? 'copyId' : 'id';
      dialog.componentInstance[locate] = id;
    }

    this.manipulateRecordCallback(id, copy, dialog);
    dialog.result.then(
      () => {
      },
      () => {
        if (this.reloadOnModalClose) {
          this.getList(this.currentPage);
        }
      },
    );
    this.dialog = dialog;
  }

  public importNow(id, connectionType): void {
    this._sweetAlert.alert(
      {
        title: 'tooltip_import_now',
        text: 'confirm_import_now',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          this._service.importNow(id, connectionType).subscribe({
            next: (save) => {
              this._messageService?.setMessageConfig(
                save.message || save.messages,
              );
            },
            error: (err) => {
              this._messageService?.setMessageConfig(
                err.message || err.messages,
                MessageType.error,
              );
            },
          });
        }
      },
    );
  }

  public makeModalSmall() {
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
      windowClass: 'modal-xsm custom',
    };
  }

  public importNowFile(id, connectionType): void {
    this.makeModalSmall();
    const dialog: NgbModalRef = this._ngbModal.open(
      CsvComponent,
      this.modalOptions,
    );
    this.modalOptions = modalDefaultOptions;

    dialog.componentInstance.id = id;
    dialog.componentInstance.service = this._service;
    dialog.componentInstance.connectionType = connectionType;

    this.dialog = dialog;
  }

  public changeStatus(event): void {
    let obj: any;
    this._service.getOne(event.id).subscribe((one) => {
      obj = one.fields;
      obj.enabled = event.enabled;
      this._service.manipulateRecord(obj, obj.id).subscribe({
        next: (save) => {
          this.getList(this.currentPage);
          this._messageService?.setMessageConfig(save.messages || save.message);
        },
        error: (error) => {
          this.getList(this.currentPage);
          this._messageService?.setMessageConfig(
            error.arrayErrorMessages || error.message || error.messages,
            MessageType.error,
          );
        },
      });
    });
  }

  public refreshList() {
    this.getList(this.currentPage);
  }

  public actionList(event) {
    const action = event.hasOwnProperty('action') ? event.action : event.event;

    switch (action) {
      case ListActionModel.EDIT:
      case ListActionModel.NEW:
      case ListActionModel.ADD:
        this.manipulateRecord(event.id);
        break;
      case ListActionModel.COPY:
        this.manipulateRecord(event.id, true);
        break;
      case ListActionModel.DELETE:
        this.delete(event.id);
        break;
      case ListActionModel.SIGN_IN_CUSTOMER:
        this.signInCustomer(event.id);
        break;
      case ListActionModel.RESET_PASSWORD:
        this.resetPassword(event.id);
        break;
      case ListActionModel.UNLOCK_ADMIN:
        this.unlockAdmin(event.id);
        break;
      case ListActionModel.IMPORT_NOW:
        const method =
          event.connectionType.toLowerCase() === 'manual'
            ? 'importNowFile'
            : 'importNow';
        this[method](event.id, event.connectionType.toLowerCase());
        break;
      case ListActionModel.SEND_DIGEST:
        this.sendDigest(event.email);
        break;
      case ListActionModel.CHANGE_STATUS:
        this.changeStatus(event);
        break;
      case ListActionModel.PAGINATION:
        this.pagination(event);
        break;
      case ListActionModel.SHOW:
        this.manipulateRecord(event.id, false, true);
        break;
      case ListActionModel.SIGN_IN:
        this.signInEvent(event);
        break;
      case ListActionModel.SEARCH:
        this.search(event.object);
        break;
      case ListActionModel.CHANGE_PAGE_SIZE:
        this.changePageSize(event);
        break;
      case ListActionModel.REFRESH_LIST:
        this.refreshList();
        break;
    }
  }

  public sendDigest(email: string) {
    this._service.allowEmailDigest(email).subscribe({
      next: (res) => {
        this._messageService?.setMessageConfig(
          res.statusCode === 200 ? res.message : 'digest_not_possible',
          res.statusCode === 200 ? MessageType.success : MessageType.warning,
          MessageLocale.list,
        );
      },
      error: (error) => {
        this._messageService?.setMessageConfig(
          error.arrayErrorMessages || error.message || error.messages,
          MessageType.error,
        );
      },
    });
  }

  public signInEvent(event) {
  }

  public search(objToSearch) {
    if (this.currentPageSize) {
      objToSearch['per-page'] = this.currentPageSize;
    }

    this.loading = true;
    this.currentFilter = objToSearch.filter;
    this._service.search(objToSearch).subscribe((list) => {
      this.list = list;
      this.loading = false;
      this.searchCallback();
    });
  }

  public changePageSize(event): void {
    this.currentPageSize = event.pageSize;
    this.getList(1, event.pageSize);
  }

  public pagination(event) {
    const page = event.page ? event.page : event.id;
    this.currentPage = page;
    this.getList(page, this.currentPageSize);
  }

  public setComponent(component) {
    this.component = component;
  }

  public setMessageSubscribe(_messageSubscribe$: Subscription): void {
    this._messageSubscribe$ = _messageSubscribe$;
  }

  public getListCallback(): any {
  }

  public searchCallback(): any {
  }

  public manipulateRecordCallback(id: number | string | null = null, copy: boolean = false, dialog: NgbModalRef): any {
  }

  public get sweetAlertService() {
    return this._sweetAlert;
  }
}
