<app-loading *ngIf="loading"></app-loading>
<app-ui-modal-form
  *ngIf="!loading"
  [readonly]="true"
  [readonly]="readonly"
  [title]="'show_original' | translate"
  [btnSaveText]="'btn_download'"
  [btnCancelText]="'btn_close'"
  (modalEvent)="showEvent($event)"
  [valid]="!downloaded"
>
  <pre class="scroll">{{ rawEmail }}</pre>
</app-ui-modal-form>
