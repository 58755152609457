<div class="input-group">
  <div class="tag-search-input">
    <ng-container [formGroup]="tagForm">
      <div class="email-tracker-select-search">
        <app-ui-labeled-select
          id="filter-select"
          [formControlName]="'option'"
          [control]="tagForm"
          [id]="'option'"
          [collection]="filters"
          [colClass]="'placeholder'"
          (change)="checkSelect(); checkRefresh('select')"
        ></app-ui-labeled-select>
      </div>
    </ng-container>
    <ng-container [formGroup]="searchForm">
      <input
        #tagInput
        *ngIf="!useSelect"
        type="text"
        class="form-control tag-input"
        [placeholder]="'tracker_search_placeholder' | translate: 'labels'"
        (keypress)="searchF($event); checkRefresh('input')"
        (paste)="searchF($event); checkRefresh('input')"
      />
      <app-ui-labeled-searchable-select
        *ngIf="useSelect"
        [control]="searchForm"
        [resetAfterSelect]="true"
        [formControlName]="'search'"
        [id]="'search'"
        [colClass]="'w-100'"
        [collection]="getItemArray"
        [placeholder]="
          (filterDisplay === 'flags'
            ? 'tracker_select_flag'
            : 'tracker_select_status'
          ) | translate: 'labels'
        "
        [(selectValue)]="selectValue"
        (change)="emitFromSelect($event)"
      ></app-ui-labeled-searchable-select>
    </ng-container>
  </div>

  <div class="input-group-append">
    <button
      *ngIf="!isRefresh"
      (click)="onAddTag()"
      class="btn btn-primary search-btn"
    >
      <i class="fas fa-search"></i> {{ 'btn_search' | translate }}
    </button>
    <button
      *ngIf="isRefresh"
      (click)="refreshList()"
      class="btn btn-primary search-btn"
      [disabled]="loading"
    >
      <i class="fas fa-sync-alt"></i> {{ 'refresh' | translate: 'labels' }}
    </button>
  </div>
</div>
