import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormService } from '../../../services/form/form.service';

@Component({
  selector: 'app-ui-labeled-searchable-select',
  templateUrl: './labeled-searchable-select.component.html',
  styleUrls: ['./labeled-searchable-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabeledSearchableSelectComponent),
      multi: true,
    },
  ],
})
export class LabeledSearchableSelectComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Output() public change = new EventEmitter();
  @Input() public label: string;
  @Input() public id: string;
  @Input() public colClass: string;
  @Input() public valueField = 'id';
  @Input() public labelField = 'name';
  @Input() public collection: any[] = [];
  @Input() public control: FormGroup;
  @Input() public readonly = false;
  @Input() public translationFile = 'labels';
  @Input() public dropdownWidth: number;
  @Input() public resetAfterSelect = false;
  @Input() public placeholder = '';
  @Input() public isModal = false;
  @Input() public selectValue?: any;
  @Output() public selectValueChange: EventEmitter<any> = new EventEmitter<any>();
  public value = '';

  public searchControl: FormControl = new FormControl();
  public searchFilterCtrl: FormControl = new FormControl();
  public filteredCollection: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public destroySubject = new Subject<void>();
  public selectModel: any;

  ngOnInit() {
    this.searchControl.setValue(null);
    this.filteredCollection.next(this.collection.slice());

    this.searchFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.filterCollection();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  protected setInitialValue() {
    this.filteredCollection
      .pipe(take(1), takeUntil(this.destroySubject))
      .subscribe(() => {
        this.singleSelect.compareWith = (a, b) => a && b && a.id === b.id;
      });
  }

  protected filterCollection() {
    if (!this.collection) {
      return;
    }
    let search = this.searchFilterCtrl.value;
    if (!search) {
      this.filteredCollection.next(this.collection.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCollection.next(
      this.collection.filter(
        (bank) => bank.name.toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  constructor(public formService: FormService) {
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.singleSelect.setDisabledState(isDisabled);
  }

  public getLabel(item: any): string {
    try {
      return this.labelField ? item[this.labelField] : item;
    } catch (e) {
      return '';
    }
  }

  public onChange(event: Event) {
    if (this.resetAfterSelect) {
      this.selectModel = '';
      this.control.reset();
      this.searchControl.reset();
    }
    this.change.emit(event);
  }

  private _onChange(_: any) {
  }

  private _onTouched() {
  }
}
