import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '../../../_services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  private _shallowModule: string[] = [];
  private _middleModule: string[] = [];
  private _deepModule: string[] = [];

  constructor(
    private _router: Router,
    private _storageService: StorageService
  ) {}

  public setModules(moduleList, locale = null): void {
    switch (locale) {
      case null:
        locale = '_shallowModule';
        break;
      case '_shallowModule':
        locale = '_middleModule';
        break;
      case '_middleModule':
        locale = '_deepModule';
        break;
      default:
        return;
    }

    moduleList?.forEach((module) => {
      this[locale].push(module.aliasId);
      locale !== '_deepModule' && this.setModules(module.items, locale);
    });
  }

  // LastChance foi criado pensando no CYPRESS;
  public can(permission, locale, url, lastChance = false): boolean {
    const listModule = this['_' + locale];

    if (listModule.length === 0) {
      if (lastChance) {
        this._storageService.removeItem('menu');
        this._storageService.removeItem('token');
        this._router.navigate(['/login'], { queryParams: { returnUrl: url } });
        return true;
      }

      const menu = JSON.parse(this._storageService.getItem('menu'));

      if (menu !== null) {
        this.setModules(menu);
      }

      return this.can(permission, locale, url, true);
    }

    return listModule.includes(permission);
  }
}
