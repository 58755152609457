import { SearchHelpItem } from '../search-help/search-help-item.model';

export class SearchTagItem {
  public item: SearchHelpItem;
  public id: number;

  public get display() {
    return this.item.searchItem;
  }

  public get name() {
    return this.display;
  }

  public set name(name: string) {
    this.item.searchItem = name;
  }

  public get showUpper() {
    return this.display.charAt(0).toUpperCase() + this.display.slice(1);
  }

  constructor(id: number, searchHelpItem: SearchHelpItem) {
    this.id = id;
    this.item = searchHelpItem;
  }
}
