import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ColumnsTypeModel } from '../../models/columns-type.model';
import {
  FieldListModel,
  PermissionsListModel,
} from '../../models/field-list.model';
import {
  AbstractListDirective,
  AdditionalActionModel,
} from 'src/app/core/_ui/components/list/abstract-list';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SortListModel } from '../../models/sort-list.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['../../../../../../../src/style/custom/_lists.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ display: 'none' })),
      state('expanded', style({ height: '20px' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListComponent
  extends AbstractListDirective
  implements OnInit, OnChanges
{
  public displayedColumns;
  public array = Array;

  @Output() public outputAction = new EventEmitter();
  @Output() public sortAction = new EventEmitter<SortListModel>();
  @Input() public massActionsActive: boolean;
  @Input() public pushTop = true;
  @Input() public list: FieldListModel;
  @Input() public title: string;
  @Input() public columns: ColumnsTypeModel[];
  @Input() public additionalAction: AdditionalActionModel[] = [];
  @Input() public currentPageSize = 10;
  @Input() public listSizes: number[] = [10, 50, 100, 500, 1000];
  @Input() public selectedIds: string[] = [];
  @Input() public loading = true;
  @Input() public selectable = false;
  @Input() public showNewBtn = true;
  @Input() public hasActions = true;
  @Input() public switchable = false;
  @Input() public hasMessage = true;
  @Input() public hasPagination = true;
  @Input() public hasSearchBox: boolean;
  @Input() public layoutFixed = false;
  @Input() public colGroupValue = [];
  @Input() public hasPageSizeSelector = true;
  @Input() public emailTracker = false;
  @Input() public innerTranslate = false;
  @Input() public contentRow = false;
  hasContent = (i: number, row: object) => row.hasOwnProperty('status');
  expandedElement: any;

  @Input()
  public lineClick(event, row, index): void {}
  @Input()
  public lineDoubleClick(event, row, index): void {}

  ngOnInit() {
    this.displayedColumns = this.columns.map((column) => column.id);
    if (this.hasActions) {
      this.displayedColumns.push('actions');
    }
    if (this.selectable) {
      this.displayedColumns.unshift('table-select');
    }
    if (this.switchable) {
      this.displayedColumns.push('toggle-select');
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    const massActions = simpleChanges.massActionsActive;
    if (massActions?.currentValue === false) {
      this.checkboxAll = this.massActionsActive;
    }

    if (simpleChanges.list && this.selectedIds && this.selectedIds.length) {
      this.setCheckboxModel();
      this.selectedIds.forEach((id) => {
        simpleChanges.list.currentValue?.rows.forEach((row, index) => {
          if (row.hasOwnProperty('id') && row['id'] === id) {
            this.checkboxModel[index] = true;
          }
        });
      });
    }
  }

  public showNew(): boolean {
    try {
      return (
        this.permissions['C'] &&
        this.permissions['C'].find((v) => v === 'exec') &&
        this.showNewBtn
      );
    } catch (error) {
      return false;
    }
  }

  public sortData(event) {
    this.sortAction.emit(event);
  }

  public setAction(event): void {
    this.outputAction.emit(event);
  }

  public get records(): any[] {
    return this.list?.rows.map((row) => {
      if (!row.hasOwnProperty('type')) {
        row['type'] = 'text';
      }
      return row;
    });
  }

  public onPageSizeChange(pageSize: number): void {
    this.outputAction.emit({ action: 'change_page_size', pageSize });
  }

  public get pageDetails() {
    return {
      currentPage: this.list.currentPage,
      currentPageSize: this.currentPageSize,
    };
  }

  public get permissions(): PermissionsListModel {
    return this.list?.permissions;
  }

  public get isIntermediate(): boolean {
    return !!this.checkboxModel.filter(Boolean).length && !this.checkboxAll;
  }
}
