<div class="form-row subtitle">
  <label>{{ 'date_range_modal' | translate: 'labels' }}</label>
  <label class="description">{{
    'date_range_modal_description' | translate: 'labels'
  }}</label>
</div>

<div class="form-row mt-4 justify-content-around" [formGroup]="formGroup">
  <div class="col-5">
    <label>{{ 'from' | translate: 'labels' }}</label>
    <mat-form-field>
      <input
        class="input-hidden"
        matInput
        [ngxMatDatetimePicker]="pickerFrom"
        [(ngModel)]="data.options.startDate"
        [max]="data.options.maxDate"
        formControlName="date"
      />
      <div class="date">
        {{ data.options.startDate?.format('DD/MM/YYYY, HH:mm:ss') ?? '' }}
      </div>
      <mat-datepicker-toggle
        class="calendar-button"
        matSuffix
        [for]="pickerFrom"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerFrom
        [showSeconds]="true"
      ></ngx-mat-datetime-picker>
    </mat-form-field>
  </div>

  <div class="col-5">
    <label>{{ 'to' | translate: 'labels' }}</label>
    <mat-form-field>
      <input
        class="input-hidden"
        matInput
        [ngxMatDatetimePicker]="pickerTo"
        [(ngModel)]="data.options.endDate"
        [min]="data.options.startDate"
        [max]="data.options.maxDate"
        formControlName="date2"
      />
      <div class="date">
        {{ data.options.endDate?.format('DD/MM/YYYY, HH:mm:ss') ?? '' }}
      </div>
      <mat-datepicker-toggle
        class="calendar-button"
        matSuffix
        [for]="pickerTo"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerTo
        [showSeconds]="true"
      ></ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
</div>

<div class="text-center mt-4">
  <button
    (click)="dialogRef.close()"
    type="button"
    class="btn btn-outline-primary mr-4"
  >
    {{ 'cancel' | translate }}
  </button>

  <button
    (click)="confirmDate()"
    type="button"
    class="btn btn-primary outline-primary"
  >
    {{ 'btn_ok' | translate }}
  </button>
</div>
