import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _formGroup: FormGroup;
  private _id: string;

  constructor() {}

  public hasError(formControl: FormGroup, id: string) {
    this._formGroup = formControl;
    this._id = id;
    return this.isInvalid && (this.wasTouched || this.hasValidationErrors);
  }

  public get isInvalid() {
    return (
      this._formGroup?.controls[this._id] !== undefined &&
      this._formGroup?.controls[this._id].invalid
    );
  }

  public get wasTouched(): boolean {
    return (
      this._formGroup?.controls[this._id].dirty ||
      this._formGroup?.controls[this._id].touched ||
      this._element?.classList.contains('submitted-input')
    );
  }

  public get hasValidationErrors(): ValidationErrors {
    return (
      this._formGroup?.controls[this._id].asyncValidator !== null &&
      this._formGroup?.controls[this._id].errors
    );
  }

  public get _element(): HTMLElement {
    const input = document.querySelector(`input#${this._id}`);
    return input as HTMLElement;
  }
}
