import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private storage: Storage = sessionStorage;

  private storageSub = new BehaviorSubject<any>(null);
  private menuSub = new BehaviorSubject<any>(null);

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  watchMenuStorage(): Observable<any> {
    return this.menuSub.asObservable();
  }

  setItem(key: string, data: any) {
    this.checkStorage();
    this.storage.setItem(key, data);
    if (key === 'menu') {
      this.menuSub.next('changed');
    } else {
      this.storageSub.next('changed');
    }
  }

  getItem(key: string): string | null {
    this.checkStorage();
    return this.storage.getItem(key);
  }

  removeItem(key: string) {
    this.checkStorage();
    this.storage.removeItem(key);
    this.storageSub.next('changed');
  }

  setLocalStorage() {
    if (sessionStorage.length > 0) {
      let currentKeys = Object.keys(sessionStorage).reduce((obj, str) => {
        obj[str] = sessionStorage.getItem(str);
        return obj;
      }, {});

      currentKeys = JSON.parse(JSON.stringify(currentKeys));
      sessionStorage.clear();

      Object.keys(currentKeys).forEach((obj) => {
        localStorage.setItem(obj, currentKeys[obj]);
      });
    }
    this.storage = localStorage;
  }

  checkStorage() {
    const localKeep = localStorage.getItem('keep');
    const sessionKeep = sessionStorage.getItem('keep');

    // if (localKeep === 'true' || sessionKeep === 'true') {
    this.setLocalStorage();
    // }
  }
}
