<div class="form-row subtitle mt-4">
  <label>{{ "email_tracker_verdict" | translate: "titles" }}</label>
  <label class="description">
    {{ "veredict_explanation" | translate: "fe" }}
  </label>
</div>
<app-list
  [list]="list"
  [hasSearchBox]="false"
  [columns]="columns"
  [loading]="loading"
  [currentPageSize]="allInfo"
  [hasSearchBox]="false"
  [hasPageSizeSelector]="false"
  [hasActions]="false"
  [hasMessage]="false"
>
  <!-- <app-search-list
    (objToSearch)="actionList($event)"
    search-box
  ></app-search-list> -->
</app-list>
