import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Flag } from 'src/app/gws/administration/archive/flags/models/flag';

@Injectable({
  providedIn: 'root',
})
export class FlagService {
  constructor(private http: HttpClient) {}

  getFlags(): Observable<Flag[]> {
    return this.http
      .get<Flag[]>('/v1/archive-flags/archive-flags')
      .pipe(map((res: any) => res.rows));
  }

  getEmailTrackerFlags(): Observable<Flag[]> {
    return this.http
      .get<Flag[]>('/v1/archive-messages/flags?page=1&per-page=1000')
      .pipe(map((res: any) => res.rows));
  }
}
