<app-menu></app-menu>
<div class="container-fluid gatefy-content gatefy-fluid">
  <mat-progress-spinner
    class="float-right"
    [value]="currentTime"
    [diameter]="30"
    (click)="switchTimer()"
  ></mat-progress-spinner>

  <div class="form-row subtitle">
    <label>{{ 'searchTool' | translate: 'labels' }}</label>
    <label class="description">
      {{ 'tracker_subtitle' | translate: 'fe' }}
    </label>
  </div>

  <div #floatingHeader class="sticky" [ngClass]="{ 'sticky-2': changeStyle }">
    <app-email-quick-finder
      #quickFinder
      [massActionsActive]="massActionsActive"
      [checked]="checked"
      [buttonLoading]="buttonLoading"
      [loading]="loading"
      [moreThanOneMail]="checked > 1"
      (filterEvent)="search($event)"
      (releaseSelected)="doAction($event)"
      (refresh)="search()"
    ></app-email-quick-finder>
    <div *ngIf="this.list" class="form-row subtitle results-wrapper">
      <label *ngIf="!isSearch" class="search-results">
        {{
          ('trackerSearchPlaceholder' | translate: 'labels').replace(
            '%s',
            this.list?.totalCount
          )
        }}
      </label>
      <label *ngIf="isSearch" class="search-results">
        {{
          ('trackerSearchResult' | translate: 'labels').replace(
            '%s',
            this.list?.totalCount
          )
        }}
      </label>
    </div>
    <app-ui-pagination
      *ngIf="list?.totalPages > 1"
      [currentPage]="list?.currentPage"
      [totalPages]="list?.totalPages"
      (requestPage)="actionList({ event: 'pagination', page: $event })"
      [topPaginate]="true"
      [ngClass]="!changeStyle ? 'd-none' : 'd-block'"
    >
    </app-ui-pagination>
  </div>

  <div class="email-tracker-list">
    <app-list
      (actionTrigger)="actionList($event)"
      (checkboxAllClick)="checkboxClick($event)"
      (singleCheckboxClick)="checkboxClick($event)"
      (checkboxReset)="massActionsActive = false"
      (outputAction)="actionList($event)"
      (sortAction)="sortAction($event)"
      [massActionsActive]="massActionsActive"
      [loading]="loading"
      [list]="list"
      [title]="'Menu_emailTrackerMenu' | translate: 'fe'"
      [pushTop]="false"
      [columns]="columns"
      [hasPagination]="true"
      [hasActions]="false"
      [hasPageSizeSelector]="true"
      [selectable]="true"
      [lineDoubleClick]="lineClick"
      [emailTracker]="true"
      [colGroupValue]="[5, 5, 15, 20, 20, 15, 50]"
    ></app-list>
  </div>
</div>
