import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-list-inbound-column',
  templateUrl: './list-inbound-column.component.html',
  styleUrls: ['./list-inbound-column.component.scss'],
})
export class ListInboundColumnComponent {
  @ViewChild('checkDomain') checkDomain: ElementRef;
  @ViewChild('notVerifiedButton') notVerifiedButton: ElementRef;
  @Input() element = null;
  @Output() action = new EventEmitter();
  public pressed = false;

  constructor() {}

  public onClick(target) {
    const clickedInside =
      this.checkDomain?.nativeElement.contains(target) ||
      this.notVerifiedButton?.nativeElement.contains(target);
    if (!clickedInside) {
      this.pressed = false;
    }
  }

  public checked(event): void {
    if (event) {
      setTimeout(() => {
        this.action.emit({ action: 'refresh_list' });
      }, 1000);
    }
    setTimeout(() => {
      this.pressed = false;
    }, 1500);
  }
}
