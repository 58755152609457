import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { TranslationService } from 'src/app/core/_services/translation.service';
import { SweetAlertService } from 'src/app/core/_ui/services/sweet-alert/sweet-alert.service';
import { LicenseModel } from '../../license/models/license.model';
import { StorageService } from '../../../../core/_services/storage.service';
import { copyWithoutReference } from '../../../../core/_helpers/util';
import { AclService } from '../../../../core/_guards/acl/services/acl.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  public userInfo: LicenseModel;

  constructor(
    private _httpClient: HttpClient,
    private _sweetAlert: SweetAlertService,
    private _translate: TranslatePipe,
    private _translationService: TranslationService,
    private _aclService: AclService,
    private _storageService: StorageService
  ) {
    this.getUserInfo();
  }

  public getUserInfo() {
    this._httpClient.get<LicenseModel>('v1/me/info').subscribe((res) => {
      this.userInfo = res;
      this._translationService.currentLanguage = res.userLanguage;
      this._translationService.setNewLanguage();
      this._translationService.getTranslationFromFiles();
      const filter = copyWithoutReference(res.menu);
      const filterModule = copyWithoutReference(res.modules);
      const menu = JSON.stringify(filter);
      const module = JSON.stringify(filterModule);
      this._aclService.setModules(JSON.parse(menu));
      this._storageService.setItem('menu', menu);
      this._storageService.setItem('modules', module);
      this._storageService.setItem('eula', !!res.eula);
    });
  }

  public updateUserInfo() {
    this._httpClient.get<LicenseModel>('v1/me/info').subscribe((res) => {
      this.userInfo = res;
      const filterModule = copyWithoutReference(res.modules);
      const module = JSON.stringify(filterModule);
      this._storageService.setItem('modules', module);
    });
  }

  private _openOneTimeAlert(): void {
    setTimeout(() => {
      this._sweetAlert.alert(
        {
          title: this._translate.transform('we_have_news', 'labels'),
          text: this._translate.transform('gws_choose_language', 'fe'),
          position: 'center',
          showCancelButton: true,
          cancelButtonText: this._translate.transform('english', 'labels'),
          confirmButtonText: this._translate.transform('portugues', 'labels'),
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary modal-btn',
            cancelButton: 'btn btn-primary modal-btn',
          },
        },
        (result) => {
          const selectedLanguage = result.isConfirmed
            ? this._translationService.supportedLanguages[1]
            : this._translationService.supportedLanguages[0];
          this._saveSelectedLanguage(selectedLanguage.id).then(() => {
            this._afterLanguageSet(selectedLanguage.shortname);
          });
        }
      );
    }, 1000);
  }

  private _afterLanguageSet(selectedLanguage: string): void {
    if (this._translationService.currentLanguage !== selectedLanguage) {
      this._translationService.resetLanguage().then(() => {
        location.reload();
      });
    }
  }

  private _saveSelectedLanguage(id): Promise<void> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`v1/me/language/${id}`, null).subscribe(() => {
        resolve();
      });
    });
  }

  public acceptEULA(eula): Observable<any> {
    return this._httpClient.patch(`v1/customer/update`, eula);
  }
}
