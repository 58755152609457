<ng-container [formGroup]="control">
  <p *ngIf="prefixText" class="prefix-input" #prefix>
    {{ prefixText }}
  </p>
  <input
    appLowercase
    [class]="inputClass"
    [formControlName]="id"
    [readOnly]="readonly"
    [id]="id"
    [min]="min"
    [max]="max"
    [step]="step"
    [type]="type"
    [title]="title"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    (change)="onChange($event)"
    (keyup)="onKeyup($event)"
    (blur)="onBlur($event)"
    [lowercase]="lowercase"
    [attr.data-cy]="cy"
    autocomplete="off"
    #inputElement
    [ngClass]="{
      'is-invalid': formService?.hasError(control, id),
      'form-control-modal': isModal && !formService?.hasError(control, id)
    }"
    [mask]="mask"
    [style.paddingLeft]="getWidth"
  />
  <ng-content></ng-content>

  <app-ui-error-form
    [hasError]="formService?.hasError(control, id)"
    [control]="control?.controls[id]"
  ></app-ui-error-form>
</ng-container>
