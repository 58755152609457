import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  EventEmitter,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalTabsBaseDirective } from '../modal-tabs.base';
import { QuarantineService } from '../../../../services/quarantine/quarantine.service';
import { MessageService } from '../../../../../shared/message/service/message.service';
import { EmailReadComponent } from '../../../email-read/email-read.component';
import { Fields as FieldsArchive } from '../../../../models/archive-finder';
import { Flag } from '../../../../../administration/archive/flags/models/flag';
import {
  EmailFlag,
  SaveEvent,
} from '../../../email-action/email-action.component';
import { ApiReturnErrorModel } from '../../../../../../core/_models/api-communicator/api-return-error.model';
import {
  MessageLocale,
  MessageType,
} from 'src/app/gws/shared/message/models/message.model';

@Component({
  selector: 'app-email-tracker-modal-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class ReadComponent
  extends EmailReadComponent
  implements ModalTabsBaseDirective, OnInit, AfterViewInit
{
  @ViewChild('emailFrame') protected _emailFrame: ElementRef;
  @Input() public id = null;
  @Input() public readonly = false;
  @Input() public fields: FieldsArchive;
  @Input() public loading = true;
  @Input() public flags: Flag[];
  @Output() public flagsChanged = new EventEmitter<Flag[]>();

  public archived = false;
  public hasPermission = true;
  public hasMessage = false;
  public emailLoadedEmitter = new EventEmitter();

  constructor(
    private ngbModal: NgbModal,
    private _quarantineService: QuarantineService,
    public ngbActiveModal: NgbActiveModal,
    private messageService: MessageService
  ) {
    super(ngbModal, _quarantineService, ngbActiveModal, messageService);
  }

  ngOnInit(): void {
    this._getPermissions();
    this.emailLoadedEmitter.subscribe(() => {
      if (this.archived && this.hasMessage && this.emailLoaded) {
        this._setEmailContent();
      }
    });
  }

  ngAfterViewInit(): void {
    this.emailLoadedEmitter.next(this._setEmailContent());
  }

  protected _getPermissions(): void {
    this.archived = this.fields.archiveData && this.fields.archiveData.archived;
    if (this.archived) {
      this.emailId = this.fields.archiveData.messageId;
      this.getEmail();
    }
  }

  protected _setEmailContent(): void {
    const frameSetAction = () => {
      if (!this._emailFrame) {
        return setTimeout(frameSetAction, 150);
      }

      try {
        const emailFrame: HTMLIFrameElement = this._emailFrame.nativeElement;
        emailFrame.contentWindow.document.open();
        this.email.content = '<base target="_blank"/>\n'.concat(
          this.email.content
        );
        emailFrame.contentWindow.document.write(this.email.content);
        emailFrame.contentWindow.document.close();
        this.setFrameSize();
      } catch (e) {
        // ---
      }
    };

    frameSetAction();
  }

  public formatSize(sizeStr: string, decimals: number = 2): string {
    const size = parseInt(sizeStr);
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(size) / Math.log(k));

    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public getEmail(): void {
    this.loading = true;
    this.emailService$ = this._quarantineService.getEmail(this.emailId);
    this.emailService$.subscribe({
      next: (fields) => {
        this.loading = false;

        this.hasMessage = fields && !!fields.to;
        if (this.hasMessage) {
          this.setEmailResponse(fields);
          this.emailLoadedEmitter.next(this._setEmailContent());
        }
      },
      error: (error) => {
        if (error.code === 403) {
          this.hasPermission = false;
          this.loading = false;
        }
        this.loading = false;
      },
    });
  }

  public downloadAttachment(attachName: string) {
    this._quarantineService.getAttachment(this.emailId, attachName).subscribe({
      next: (res) => {
        const a = document.createElement('a');
        a.href = `data:file;base64,${res.data}`;
        a.download = attachName;
        a.click();
      },
      error: (err) => {
        this.messageService.setMessageConfig(
          err.message,
          MessageType.error,
          MessageLocale.modal
        );
      },
    });
  }

  public getType(name: string) {
    if (!name) {
      return 'alt';
    }
    const nameSplitted = name.split('.');
    if (nameSplitted.length < 2) {
      return 'alt';
    }
    switch (nameSplitted[nameSplitted.length - 1]) {
      case 'pdf':
        return 'pdf';
      case 'doc':
        return 'word';
      case 'docx':
        return 'word';
      case 'xls':
        return 'excel';
      case 'xlsx':
        return 'excel';
      default:
        return 'alt';
    }
  }

  public onSaveChange(event: SaveEvent): void {
    super.onSaveChange(event);
    this.renewFlags(event);
    this.flagsChanged.emit(this.emailFlags);
  }

  public renewFlags(event: SaveEvent): void {
    if (event.flags) {
      event.flags.forEach((emailFlag: EmailFlag) => {
        if (emailFlag.email === parseInt(this.emailId)) {
          const newEmailFlags: Flag[] = [];
          emailFlag.flags.forEach((flagId) => {
            const newFlag = this.getFlagById(flagId);
            if (newFlag) {
              newEmailFlags.push(newFlag);
            }
          });
          this.email.flags = newEmailFlags;
          this.setEmailResponse(this.email);
        }
      });
    }
  }

  public getFlagById(id: number | string): Flag | null {
    const flagId = id.toString();
    let returnFlag: Flag | null = null;
    this.flags.some((flag: Flag) => {
      if (flag.id.toString() === flagId) {
        returnFlag = { ...flag };
        returnFlag.id = parseInt(returnFlag.id.toString());
        return true;
      }
    });
    return returnFlag;
  }

  @HostListener('window:resize')
  public setFrameSize(): void {
    try {
      const emailFrame: HTMLIFrameElement = this._emailFrame.nativeElement;
      emailFrame.height =
        emailFrame.contentWindow.document.body.scrollHeight + 'px';
    } catch (e) {
      // ---
    }
  }
}
