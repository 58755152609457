import * as Moment from 'moment';

export class EmailFilterTracker {
  startDate: Moment.Moment;
  endDate: Moment.Moment;
  subject: string;
  sender: string;
  recipients: string;
  status: string;
  RELEASED_AT: boolean;
  flags: string;
  page = 1;
  maxPerPage = 20;
  orderBy: string;
  emailAddress: string;
  senderMTA: string;
  senderHeader: string;
  // flagApply = [];
  // flagsDenny = [];
  // rulesApply = [];
  // rulesDenny = [];
  // mtaApply = [];
  // mtaDenny = [];
  searchAll: string;
}
