import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { DomHandler } from 'src/app/core/_dom/dom-handler';

import { copyWithoutReference } from '../../../_helpers/util';
import { Message } from '../../models/messages/messages';
import { UiSwitchComponent } from '../form/switch/switch.component';

@Component({
  selector: 'app-ui-policy-list',
  templateUrl: './list.component.html',
  styleUrls: [
    '../../../../../../src/style/custom/_lists.scss',
    '../../../../../../src/app/gws/shared/list/components/list-action/action.component.scss',
  ],
})
export class UiPolicyListComponent implements OnInit, OnChanges {
  @ViewChild('enabToggle') public enabToggle: ElementRef;
  @ViewChild('table') table: MatTable<any[]>;
  @Input() public fields: any;
  @Input() public permissions: any;
  @Input() public successMessage: Message | null = null;
  @Input() public showAdd = true;
  @Input() public configRule = null;
  @Input() public reoder = true;
  @Input() public readonly = false;
  @Input() public delete = true;
  @Input() public title = 'Rules';
  public displayedColumns = ['position', 'enab', 'name', 'desc', 'actions'];

  @Output() public actionTrigger = new EventEmitter();

  @ViewChildren(UiSwitchComponent)
  public _switchList: QueryList<UiSwitchComponent>;

  public columns = [
    { id: 'enab', label: 'Status' },
    { id: 'name', label: 'Name' },
    { id: 'desc', label: 'Description' },
  ];
  rowDragging: boolean;
  draggedRowIndex: any;
  droppedRowIndex: any;

  ngOnInit(): void {
    if (!this.fields) {
      const defaultFields = { json: [] };
      this.fields = copyWithoutReference(defaultFields);
    }
    if (!this.reoder) {
      this.displayedColumns.shift();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configRule && !changes.configRule.firstChange) {
      const configRule = changes.configRule.currentValue;
      this.saveRule(configRule.position, configRule.rule);
    }
  }

  public saveRule(key, rule): void {
    if (this.fields.json) {
      this.fields.json[key] = rule;
    }
  }

  public getRows(): any[] {
    return this.fields;
  }

  public getValue(row): any[] {
    const returnRow = [];

    this.columns.forEach((column) => {
      returnRow.push(row[column.id]);
    });

    return returnRow;
  }

  public isPropertEnab(column): boolean {
    return column === 'enab';
  }

  public setAction(action, id = null, isNew = false): void {
    try {
      if (id !== null && !isNew) {
        const rows = this.getRows();
        if (rows[id]) {
          return this.actionTrigger.emit({ action, id, content: rows[id] });
        }
      }
    } catch (e) {}
    const defaultContent = {
      enab: true,
      name: '',
      desc: '',
      op: 'AND',
      par: [],
      dis: [],
    };
    return this.actionTrigger.emit({ action, id, content: defaultContent });
  }

  public baseCheckPermission(key, permission): boolean {
    if (this.permissions.hasOwnProperty(key)) {
      return (
        this.permissions[key].filter((value) => value === permission).length > 0
      );
    }
    return false;
  }

  public showForm(): boolean {
    return this.baseCheckPermission('R', 'form');
  }

  public showNew(): boolean {
    return (
      this.baseCheckPermission('C', 'exec') &&
      this.showForm() &&
      this.showAdd &&
      !this.readonly
    );
  }

  public showEdit(): boolean {
    return (
      this.baseCheckPermission('U', 'exec') && this.showForm() && !this.readonly
    );
  }

  public showDelete(): boolean {
    return this.baseCheckPermission('D', 'exec') && this.delete;
  }

  public showCopy(): boolean {
    return this.showNew() && this.showEdit();
  }

  public changeStatus(row, checked = null): void {
    if (checked !== null) {
      row.enab = checked;
    }

    this.actionTrigger.emit({
      action: 'change_status',
      id: row.id,
      enabled: row.enab,
    });
  }

  public enabledRow(row): boolean {
    if (typeof row.enab !== 'boolean') {
      row.enab = false;
    }
    return row.enab;
  }

  public sort(event): void {
    this.reorderArray(this.getRows(), event.dragIndex, event.dropIndex);
    this.actionTrigger.emit({ action: 'reorder', list: this.getRows() });
  }

  public reorderArray(value: any[], from: number, to: number): void {
    let target: number;
    if (value && from !== to) {
      if (to >= value.length) {
        target = to - value.length;
        while (target-- + 1) {
          value.push(undefined);
        }
      }
      value.splice(to, 0, value.splice(from, 1)[0]);
    }
  }

  public onRowDragStart(event, index) {
    this.rowDragging = true;
    this.draggedRowIndex = index;
    event.dataTransfer.setData('text', 'b');
  }

  public onRowDragEnd(event) {
    this.rowDragging = false;
    this.draggedRowIndex = null;
    this.droppedRowIndex = null;
  }

  public onRowDragLeave(event, rowElement) {
    const prevRowElement = rowElement.previousElementSibling;
    if (prevRowElement) {
      DomHandler.removeClass(prevRowElement, 'table-dragpoint-bottom');
    }

    DomHandler.removeClass(rowElement, 'table-dragpoint-bottom');
    DomHandler.removeClass(rowElement, 'table-dragpoint-top');
  }

  public onRowDrop(event, rowElement) {
    if (this.droppedRowIndex != null) {
      const dropIndex =
        this.draggedRowIndex > this.droppedRowIndex
          ? this.droppedRowIndex
          : this.droppedRowIndex === 0
          ? 0
          : this.droppedRowIndex - 1;

      this.sort({
        dragIndex: this.draggedRowIndex,
        dropIndex,
      });
    }

    this.onRowDragLeave(event, rowElement);
    this.onRowDragEnd(event);
    this.table.renderRows();
  }

  public onRowDragOver(event, index, rowElement) {
    if (this.rowDragging && this.draggedRowIndex !== index) {
      const rowY =
        DomHandler.getOffset(rowElement).top + DomHandler.getWindowScrollTop();
      const pageY = event.pageY;
      const rowMidY = rowY + DomHandler.getOuterHeight(rowElement) / 2;
      const prevRowElement = rowElement.previousElementSibling;

      if (pageY < rowMidY) {
        DomHandler.removeClass(rowElement, 'table-dragpoint-bottom');

        this.droppedRowIndex = index;

        if (prevRowElement) {
          DomHandler.addClass(prevRowElement, 'table-dragpoint-bottom');
        } else {
          DomHandler.addClass(rowElement, 'table-dragpoint-top');
        }
      } else {
        if (prevRowElement) {
          DomHandler.removeClass(prevRowElement, 'table-dragpoint-bottom');
        } else {
          DomHandler.addClass(rowElement, 'table-dragpoint-top');
        }

        this.droppedRowIndex = index + 1;
        DomHandler.addClass(rowElement, 'table-dragpoint-bottom');
      }
    }
  }
}
