import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UiInputComponent } from '../input/input.component';
import { randomId } from 'src/app/core/_helpers/util';
import { FormService } from '../../../services/form/form.service';

@Component({
  selector: 'app-ui-labeled-input',
  templateUrl: './labeled-input.component.html',
  styleUrls: ['./labeled-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiLabeledInputComponent),
      multi: true,
    },
  ],
})
export class UiLabeledInputComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input() public id: string;
  @Input() public min = 1;
  @Input() public max = 255;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public mask = '';
  @Input() public title = '';
  @Input() public colClass = 'w-100';
  @Input() public inputClass = 'form-control form-control-sm';
  @Input() public placeholder = '';
  @Input() public autocomplete = '';
  @Input() public readonly = false;
  @Input() public control: FormGroup;
  @Input() public cy: string;
  @Input() public isModal = false;
  @Input() public prefixText = null;
  @Input() public lowercase = false;
  @Input() public tooltip = false;
  @Input() public tooltipMessage = '';
  @Output() public blur = new EventEmitter();
  @ViewChild('input', { static: true }) private _input: UiInputComponent;

  public isRequired = false;
  public randomId = randomId();
  public value: any;

  constructor(public formService: FormService) {}

  ngOnInit() {
    const self = this;
    this._input.inputElement.nativeElement.addEventListener(
      'change',
      (event) => {
        self._onChange(this.value);
      }
    );
    this._input.inputElement.nativeElement.addEventListener(
      'keyup',
      (event) => {
        self._onChange(this.value);
      }
    );
    this._input.inputElement.nativeElement.addEventListener('blur', (event) => {
      self._onTouched();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      try {
        this.isRequired = this.control.controls[this.id].validator(
          {} as AbstractControl
        ).required;
      } catch (e) {}
    });
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._input.setDisabledState(isDisabled);
  }

  public onBlur(event: any): void {
    this._onTouched();
    this.blur.emit(event);
  }

  public onChange(event: any): void {
    this._onChange(event.target.value);
  }

  private _onChange(_: any) {}

  private _onTouched() {}
}
