<table class="mat-calendar-table" role="grid">
  <thead class="mat-calendar-table-header">
    <tr>
      <!-- For the day-of-the-week column header, we use an `<abbr>` element because VoiceOver
           ignores the `aria-label`. ChromeVox, however, does not read the full name
           for the `<abbr>`, so we still set `aria-label` on the header element. -->
      <th scope="col" *ngFor="let day of _weekdays" [attr.aria-label]="day.long">
        <abbr class="mat-calendar-abbr" [attr.title]="day.long">{{day.narrow}}</abbr>
      </th>
    </tr>
    <tr><th aria-hidden="true" class="mat-calendar-table-header-divider" colspan="7"></th></tr>
  </thead>
  <tbody mat-calendar-body
         [label]="_monthLabel"
         [rows]="_weeks"
         [todayValue]="_todayDate!"
         [startValue]="_rangeStart!"
         [endValue]="_rangeEnd!"
         [comparisonStart]="_comparisonRangeStart"
         [comparisonEnd]="_comparisonRangeEnd"
         [previewStart]="_previewStart"
         [previewEnd]="_previewEnd"
         [isRange]="_isRange"
         [labelMinRequiredCells]="3"
         [activeCell]="_dateAdapter.getDate(activeDate) - 1"
         (selectedValueChange)="_dateSelected($event)"
         (previewChange)="_previewChanged($event)"
         (keyup)="_handleCalendarBodyKeyup($event)"
         (keydown)="_handleCalendarBodyKeydown($event)">
  </tbody>
</table>
