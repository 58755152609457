<div class="card">
  <div class="circle-icon circle-icon-{{ message.name.toLowerCase() }}">
    <i
      class="{{ getIconPrefix(message.name) }} fa-{{ getIcon(message.name) }}"
    ></i>
  </div>
  <div class="card-info">
    <div class="timeline-info">
      <div class="timeline-card-title">
        {{ message.name + '_title' | translate: 'titles' }}
      </div>
      <div class="date" *ngIf="checkIfNull(message.time)">
        <i class="far fa-clock"></i>
        {{ formatDate(message.time) }}
      </div>
    </div>
    <div class="author-row" *ngIf="message.author">
      <p>{{ 'by_timeline' | translate: 'labels' }}: {{ message.author }}</p>
      <p class="text-right">
        {{ 'from_timeline' | translate: 'labels' }}: {{ message.environment }}
        {{
          message.environment === 'GWS'
            ? ('from_userTypeAdmin' | translate: 'labels')
            : ('from_userTypeEndUser' | translate: 'labels')
        }}
      </p>
    </div>
  </div>
</div>
<ng-content></ng-content>
