export enum EmailAction {
  Release = 0,
  ReleaseAV = 1,
  Delete = 2,
  Redirect = 3,
  Phishing = 4,
  Spam = 5,
  NotSpam = 6,
  SenderTrusted = 7,
  SenderBlocked = 8,
  ExportData = 9,
}

export class EmailActionOption {
  id: EmailAction;
  name: string;
}
