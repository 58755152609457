import { Injectable } from '@angular/core';
import { StorageService } from '../../../_services/storage.service';


const hrefDisabled = ['#', '/undefined'];
const SINGLE_LEVEL_WITH_IMAGE = 1;


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private storageService: StorageService) {
  }

  private getHref(href) {
    return hrefDisabled.includes(href) ? './' : href;
  }

  private formattedLevel(menu, level) {
    const defaultFormat = {
      href: this.getHref(menu.href),
      name: 'Menu_' + menu.aliasId,
      active: false,
      isCollapsed: false,
      subMenu: []
    };

    if (level === SINGLE_LEVEL_WITH_IMAGE) {
      defaultFormat['icon'] = menu.aliasId + '.png';
      defaultFormat['iconOn'] = menu.aliasId + '-on.png';
    }

    return defaultFormat;
  }

  formattedMenu(menu?, level?) {
    if (undefined === menu) {
      this.storageService.watchStorage().subscribe(data => {
        menu = JSON.parse(this.storageService.getItem('menu'));
      });
    }

    if (undefined === level) {
      level = 0;
    }

    return menu.map((configMenu) => {
      const format = this.formattedLevel(configMenu, level);
      if (typeof configMenu.items !== 'undefined' && configMenu.items.length > 0) {
        format.subMenu.push(this.formattedMenu(configMenu.items, level + 1));
      } else {
        delete format['subMenu'];
      }
      return format;
    });
  }
}
