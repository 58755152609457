<app-ui-modal-form
  (modalEvent)="save($event)"
  [readonly]="readonly"
  [valid]="valid"
  btnSaveText="send"
  title="Redirect"
  explanationTitle="redirectExplanation"
>
  <form autocomplete="off">
    <div class="form-row">
      <app-operator-array
        #array
        [value]="emails"
        id="email"
        label="Email"
        type="email"
      >
      </app-operator-array>
    </div>
  </form>
</app-ui-modal-form>
