<div
  *ngIf="!hasMiddle"
  class="wrap float-right binary"
  (click)="
    setting.value > 0 ?
      setValue((setting.value = 0)) :
      setValue((setting.value = 1))
  "
  [ngClass]="setting.value > 0 ? 'true' : 'false'"
>
  <div class="toggle">
    <span></span>
  </div>
</div>
<div
  *ngIf="hasMiddle"
  class="wrap float-right"
  (click)="
    setting.value > 0 ?
      setValue(setting.value - 1) :
      setValue((setting.value = 2))
  "
  [ngClass]="
    setting.value === 0 ? 'false' : null ||
    setting.value === 1 ? 'true' : null ||
    setting.value === 2 ? 'null' : null
  "
>
  <div class="toggle">
    <span></span>
  </div>
</div>
