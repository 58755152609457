<div *ngIf="loading" class="license-counter__loader">
  <img
    class="loading-spinner"
    src="../../../../../assets/icones/general/btn-loading.svg"
  />
</div>
<div
  *ngIf="!loading"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [ngClass]="{
    'license-counter--exceed': notHasLicense,
    'license-counter--ok': hasLicense
  }"
  class="license-counter d-flex align-items-center justify-content-center mx-3"
>
  <div class="license-counter__icon">
    <svg>
      <use [attr.href]="'/assets/icones/license/icons.svg#' + svgIcon"></use>
    </svg>
  </div>
  <div class="license-counter__bar">
    <p>
      {{ 'totalLicenses' | translate: 'labels' }}
      <span>{{ licensesInUse }}/{{ licensesAuthorized }}</span>
    </p>
    <div class="meter">
      <span [ngStyle]="{ 'width.%': width }"></span>
    </div>
  </div>
  <span
    *ngIf="notHasLicense && showAlert"
    [title]="'exceeded_licenses' | translate: 'fe'"
    class="license-counter__tp"
  >
  </span>
</div>
