import { Title } from '@angular/platform-browser';
import * as Sentry from '@sentry/browser';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { environment } from '../environments/environment';
import { StorageService } from './core/_services/storage.service';
import { sentryJwtPayload } from './core/_helpers/util';
import { RoutingStateService } from './gws/shared/screen-errors/services/routing-state.service';
import { AuthenticationService } from './core/_services/authentication.service';
import { TranslationService } from './core/_services/translation.service';
import { NgcCookieConsentService, WindowService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslatePipe } from './core/_pipes/pipes/translate.pipe';
import { CookieTreatService } from './gws/legal/services/cookie-treat.service';

Sentry.init({
  dsn: 'https://6d13d6fb4b6c46e986241100956b916f@sentry.ops.gatefy.com/13',
  release: environment.version
});

declare const dataLayer: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  private statusChangeSubscription: Subscription;
  public content = this._ccService.getConfig().content;
  public visitedFlag = false;

  constructor(
    private _routingState: RoutingStateService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _authenticationService: AuthenticationService,
    private _storageService: StorageService,
    private _translationService: TranslationService,
    private _translate: TranslatePipe,
    private _ccService: NgcCookieConsentService,
    private _cookieService: CookieTreatService
  ) {
    this._routingState.loadRouting();
    this._storageService.getItem('language')
      ? this._translationService.getUserLanguage()
      : this._translationService.getTranslationFromFiles();
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.visitedFlag) {
          dataLayer.push({
            event: 'changedRoute'
          });
        }
        this.visitedFlag = true;
      }
    });
    if (!this._ccService.hasConsented()) {
      setTimeout(() => {
        this._cookieService.deleteAllCookies();
      }, 5000);
      this._storageService.setItem('cookieConsent', this._ccService.hasConsented());
      this._storageService.removeItem('_hjid');
    }
    if (this._ccService.hasAnswered()) {
      setTimeout(() => {
        const revoke = document.querySelector('.cc-revoke');
        revoke.classList.add('d-none');
      });
    }
  }

  ngOnInit(): void {
    this.sentryConfig();
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this._activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => this._titleService.setTitle(event['title']));

  }


  ngAfterViewInit(): void {
    this.content.message = this._translate.transform('message', 'cookie');
    this.content.policy = this._translate.transform('cookie', 'cookie');
    this.content.allow = this._translate.transform('allow', 'cookie');
    this.content.deny = this._translate.transform('deny', 'cookie');
    this.content.privacyPolicyLink = this._translate.transform('privacyPolicyLink', 'cookie');
    this.content.message_custom = this._translate.transform('message_custom', 'cookie');
    this._ccService.destroy();
    this._ccService.init(this._ccService.getConfig());
    document.getElementById('cookieconsent:link').addEventListener(
      'click',
      (e) => this._router.navigate([
        this._translate.transform(
          'privacyPolicyHref',
          'cookie'
        )]
      )
    );

    this.statusChangeSubscription = this._ccService.statusChange$.subscribe((event) => {
      if (event.status === 'deny') {
        this._cookieService.deleteAllCookies();
        this._storageService.setItem('cookieConsent', this._ccService.hasConsented());
        this._storageService.removeItem('_hjid');
      }
      if (event.status === 'deny' || event.status === 'allow') {
        const revoke = document.querySelector('.cc-revoke');
        revoke.classList.remove('d-block');
        revoke.classList.add('d-none');
      }
      if (event.status === 'allow') {
        this._storageService.setItem('cookieConsent', this._ccService.hasConsented());
      }
    });
  }

  public sentryConfig(): void {
    const token = JSON.parse(this._storageService.getItem('token'));
    if (token !== null) {
      Sentry.setContext('payload', sentryJwtPayload(token));
    }
  }

  public logout(): void {
    this._authenticationService.logout();
    this._router.navigate(['/login']);
  }
}
