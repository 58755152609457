import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { MessageConfig, MessageLocale, MessageType } from '../models/message.model';
import { TranslatePipe } from '../../../../core/_pipes/pipes/translate.pipe';
import { consoleLogDevEnv } from '../../../../core/_helpers/util';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private _messageConfig = new BehaviorSubject<MessageConfig>(null);
  public hasAdviceMessage = false;
  public adviceMessage;
  public adviceMessageHeader;

  constructor(
    protected _toastr: ToastrService,
    protected _translate: TranslatePipe,
  ) {
  }

  private logDevEnv(
    type: MessageType,
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>,
  ) {
    consoleLogDevEnv({
      type,
      toastr: { message, title, override },
    });
  }

  public setMessageConfig(
    message = '',
    type = MessageType.success,
    locale = MessageLocale.list,
  ): void {
    if (Array.isArray(message)) {
      message.forEach((element) => {
        this.setMessageConfig(element, type, locale);
      });
      return;
    }
    if (typeof message === 'object') {
      const key = Object.keys(message)[0];
      this.setMessageConfig(key + ': ' + message[key], type, locale);
    }
    if (this.isValidMessage(message)) {
      if (type === MessageType.success) {
        this.logDevEnv(
          MessageType.success,
          this._translate.transform(message, 'success', []),
          this._translate.transform('success', 'titles', []),
        );
        this._toastr.success(
          this._translate.transform(message, 'success', []),
          this._translate.transform('success', 'titles', []),
        );
      } else if (type === MessageType.warning) {
        this.logDevEnv(
          MessageType.warning,
          this._translate.transform(message, 'errors', []),
          this._translate.transform('warning', 'titles', []),
        );
        this._toastr.warning(
          this._translate.transform(message, 'errors', []),
          this._translate.transform('warning', 'titles', []),
        );
      } else {
        this.logDevEnv(
          MessageType.error,
          this._translate.transform(message, 'errors', []),
          this._translate.transform('error', 'titles', []),
          {
            enableHtml: true,
          },
        );
        this._toastr.error(
          this._translate.transform(message, 'errors', []),
          this._translate.transform('error', 'titles', []),
          {
            enableHtml: true,
          },
        );
      }

      if (this.hasAdviceMessage) {
        this.adviceMessage.forEach((element) => {
          const fullMessage =
            element.split(':')[1] +
            ' ' +
            this._translate.transform(element.split(':')[0], 'errors', []);

          this._toastr.warning(
            fullMessage,
            this._translate.transform(this.adviceMessageHeader, 'errors', []),
          );
        });
      }
      this._messageConfig.next({ message, type, locale });
    }
  }

  public isValidMessage(message: string): boolean {
    return typeof message === 'string' && message !== '';
  }

  public resetMessageService(): void {
    this._messageConfig.next({
      message: '',
      type: MessageType.success,
      locale: MessageLocale.list,
    });
  }

  public checkHasMessage(messageConfig, locale): boolean {
    return (
      messageConfig !== null &&
      messageConfig.message !== '' &&
      locale === messageConfig.locale
    );
  }

  public get messageConfig(): Observable<MessageConfig> {
    return this._messageConfig.asObservable();
  }
}
