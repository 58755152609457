import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { userSettings } from 'src/app/gws/shared/user-settings/models/user-settings.model';

import { FormService } from '../../../services/form/form.service';
import { CollectionSelectModel } from '../../../models/select/collection-select.model';

@Component({
  selector: 'app-ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
  ],
})
export class UiSelectComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  @Output() public change = new EventEmitter();
  @Input() public id: string;
  @Input() public valueField = 'id';
  @Input() public labelField = 'name';
  @Input() public collection: CollectionSelectModel[] = [];
  @Input() public control: FormGroup;
  @Input() public readonly = false;
  @Input() public translationFile = 'labels';
  @Input() public width: number;
  @Input() public placeholder = '';
  @Input() public isModal = false;
  @Input() public disabled = false;
  public selectCollection: CollectionSelectModel[] = [];
  public value: any;
  public isOpen = false;
  public label: string;
  public observer: MutationObserver;
  public selected;

  @ViewChild('listBody') private _listBody: ElementRef;
  @ViewChild('selectElement', { static: true })
  private _selectElement: ElementRef;
  @ViewChild('matSelect') private _matSelect: MatSelect;
  private _onChange(_: any) {}
  private _onTouched() {}

  constructor(private _renderer: Renderer2, public formService: FormService) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      this.control.controls[this.id].value === null &&
      userSettings.includes(this.id)
    ) {
      setTimeout(() => {
        this.control.controls[this.id].setValue(-1);
      });
    }
    // set value from 'id' (or specified `valueField`) foreach item in collection
    this.collection.forEach((item) => {
      item.value = this.getValue(item);
    });
    this.selectCollection = this.collection;
  }

  ngAfterViewInit(): void {
    this._detectDisabled();
  }

  public get getReadonly() {
    return this.readonly;
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._selectElement.nativeElement,
      'disabled',
      isDisabled
    );
    this.readonly = isDisabled;
  }

  public onChange(event: any) {
    this.change.emit(event);
    const value = event;

    if (value === 'null') {
      this._onChange(null);
      return;
    }

    if (isNaN(value) || value === '') {
      this._onChange(value);
      return;
    }

    this._onChange(+value);
  }

  public onBlur() {
    this._onTouched();
  }

  public getValue(item: any): any {
    const value = this.valueField ? item[this.valueField] : item;
    return isNaN(value) || value === '' || value === null
      ? value
      : parseInt(value);
  }

  public getLabel(item: any): string {
    try {
      return this.labelField ? item[this.labelField] : item;
    } catch (e) {
      return '';
    }
  }

  public checkValue(value: string): boolean {
    return value === '' ? false : true;
  }

  public getLabelFromValue(): string {
    const value = this.control.controls[this.id].value;
    const selectedElement = this.collection.find(
      (element) => element.id?.toString() === value?.toString()
    );
    return this.getLabel(selectedElement);
  }

  public closeSelect(): void {
    if (!this.isOpen) {
      return;
    }
    setTimeout(() => {
      const listBody = this.selectList.nativeElement;
      this.isOpen = false;
      listBody.style.height = '0px';
      listBody.style.display = 'none';
    }, 150);
  }

  public onSelect(value: unknown): void {
    this.control.controls[this.id].setValue(this.getValue(value));
    this.change.emit({
      target: this._selectElement.nativeElement,
    });
  }

  public _detectDisabled(): void {
    const config = { attributes: true, childList: true, subtree: true };
    this.observer = new MutationObserver((mutationsList, observer) => {
      const target = mutationsList[0].target as HTMLInputElement;
      this.readonly = target.disabled;
    });
  }

  public blurInput(): void {
    this._selectElement.nativeElement.blur();
  }

  public get selectElement(): ElementRef {
    return this._selectElement;
  }

  public get selectList(): ElementRef {
    return this._listBody;
  }
}
