<div class="card-wrapper">
  <div class="form-row subtitle mt-4">
    <label>{{ 'main_info' | translate: 'titles' }}</label>
    <label class="description">
      {{ 'digestMailSubtitle' | translate: 'labels' }}
    </label>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="item-wrapper">
        <p>{{ 'sender' | translate: 'labels' }}</p>
        <br />
        <div class="item-name">
          {{ fields.emailData.sender }}
        </div>
      </div>
      <div
        class="item-wrapper"
        *ngIf="fields.emailData.sender_envelope"
        [ngClass]="{ 'show-more': show[0] }"
      >
        <p>
          {{ 'senderMTA' | translate: 'labels' }}
          <a
            class="show-more-button"
            *ngIf="isSenderMTAOverflowing || show[0]"
            (click)="show[0] = !show[0]"
            >{{
              show[0]
                ? ('show_less' | translate: 'labels')
                : ('show_more' | translate: 'labels')
            }}</a
          >
        </p>
        <br />
        <div
          class="item-name"
          [ngClass]="{ 'show-more': show[0] }"
          #senderMTAWrapper
        >
          {{ fields.emailData.sender_envelope }}
        </div>
      </div>
      <div class="item-wrapper" [ngClass]="{ 'show-more': show[1] }">
        <p class="select-none">
          {{ 'recipient' | translate: 'labels' }}

          <a
            class="show-more-button"
            *ngIf="isRecipientOverflowing || show[1]"
            (click)="show[1] = !show[1]"
            >{{
              show[1]
                ? ('show_less' | translate: 'labels')
                : ('show_more' | translate: 'labels')
            }}</a
          >
        </p>
        <div
          class="recipients-container item-name"
          [ngClass]="{ 'show-more': show[1] }"
          #recipientsWrapper
        >
          <ng-container
            *ngFor="let item of fields.emailData.recipients; let isLast = last"
          >
            {{ item }}
            {{ isLast ? '' : ', ' }}
          </ng-container>
        </div>
      </div>
      <div class="item-wrapper" [ngClass]="{ 'show-more': show[2] }">
        <p>
          {{ 'subject' | translate: 'labels' }}
          <a
            class="show-more-button"
            *ngIf="isSubjectOverflowing || show[2]"
            (click)="show[2] = !show[2]"
            >{{
              show[2]
                ? ('show_less' | translate: 'labels')
                : ('show_more' | translate: 'labels')
            }}</a
          >
        </p>
        <div
          class="item-name"
          [ngClass]="{ 'show-more': show[2] }"
          #subjectWrapper
        >
          {{ fields.emailData.subject }}
        </div>
      </div>
      <div class="item-wrapper">
        <p>{{ 'size' | translate: 'labels' }}</p>
        <div class="item-name">{{ fields.emailData.size }} bytes</div>
      </div>
      <div class="item-wrapper">
        <p>{{ 'digest_date' | translate: 'labels' }}</p>
        <div class="item-name">
          {{ fields.time | sqlDate: sqlDateModel.FULL_DATE_TIME }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="other-info-wrapper">
  <div class="list-wrapper">
    <div class="form-row subtitle">
      <label>{{ 'other_info' | translate: 'titles' }}</label>
      <label class="description">
        {{ 'digestListSubtitle' | translate: 'labels' }}
      </label>
    </div>
    <app-list
      class="list"
      [list]="list"
      [hasSearchBox]="false"
      [columns]="columns"
      [loading]="loading"
      [currentPageSize]="10"
      [pushTop]="false"
      [hasSearchBox]="false"
      [hasPageSizeSelector]="false"
      [hasActions]="false"
      [hasMessage]="false"
    >
      <!-- <app-search-list
        (objToSearch)="actionList($event)"
        search-box
      ></app-search-list> -->
    </app-list>
  </div>
  <ng-content></ng-content>
</div>
