import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { customerId, isRoot } from 'src/app/core/_helpers/util';
import { StorageService } from 'src/app/core/_services/storage.service';
import { AdditionalActionModel } from 'src/app/core/_ui/components/list/abstract-list';
import { ListActionModel } from '../../../../../core/_models/list/list-action.model';

@Component({
  selector: 'app-list-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ListActionComponent implements OnChanges {
  @Output() public action = new EventEmitter();
  @Input() public id;
  @Input() public type;
  @Input() public row;
  @Input() public permissions;
  @Input() public readonly = false;
  @Input() public additionalAction: AdditionalActionModel[] = [];
  public saveMode = false;
  public listAction = ListActionModel;

  constructor(private _storageService: StorageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.saveMode = this.type === 'edit';
    }
  }

  public setAction(event: { event: ListActionModel, connectionType?: any } | ListActionModel): void {
    let eventWrapper;
    if (typeof event === 'object' && event.event === ListActionModel.SEND_DIGEST) {
      eventWrapper = {
        ...event,
        email: this.row.email,
      };
    } else {
      eventWrapper =
        typeof event === 'string'
          ? { event, id: this.id }
          : { ...event, id: this.id };
    }
    this.action.emit(eventWrapper);
  }

  public baseCheckPermission(key, permission): boolean {
    if (this.permissions.hasOwnProperty(key)) {
      return (
        this.permissions[key].filter((value) => value === permission).length > 0
      );
    }
    return false;
  }

  public showForm(): boolean {
    return this.baseCheckPermission('R', 'form');
  }

  public showNew(): boolean {
    return this.baseCheckPermission('C', 'exec') && this.showForm();
  }

  public showEdit(row?): boolean {
    return (
      this.baseCheckPermission('U', 'exec') &&
      this.showForm() &&
      this.customerIdHasPermission(row)
    );
  }

  public showDelete(row?): boolean {
    return (
      this.baseCheckPermission('D', 'exec') && this.customerIdHasPermission(row)
    );
  }

  public showCopy(): boolean {
    return this.showNew() && this.showEdit();
  }

  public showSignInCustomer(): boolean {
    return this.additionalAction.includes(AdditionalActionModel.SIGNIN_CUSTOMER);
  }

  public showResetPassword(): boolean {
    return this.additionalAction.includes(AdditionalActionModel.RESET_PASSWORD);
  }

  public showUnlockAdmin(): boolean {
    if (this.row.locked)
      return this.additionalAction.includes(AdditionalActionModel.UNLOCK_ADMIN);
  }

  public showImport(): boolean {
    return this.additionalAction.includes(AdditionalActionModel.IMPORT_NOW);
  }

  public showDigest(): boolean {
    return this.additionalAction.includes(AdditionalActionModel.SEND_DIGEST);
  }

  public showSignin(): boolean {
    return (
      this.additionalAction.includes(AdditionalActionModel.SIGN_IN) &&
      this.row.sign_in
    );
  }

  public customerIdHasPermission(row?): boolean {
    let checkCustomerId = true;
    const token = this._storageService.getItem('token');

    if (token === null) {
      return false;
    }
    if (row?.hasOwnProperty('customerId')) {
      const parsedToken = JSON.parse(token);
      // TODO: buscar uma maneira melhor de validar isto. `isRoot(parsedToken)` pode causar problemas de validação das regras.
      checkCustomerId = +customerId(parsedToken) === +row['customerId'] || isRoot(parsedToken);
    }

    return checkCustomerId;
  }
}
