import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DetailsComponent } from './details/details.component';
import { VerdictComponent } from './verdict/verdict.component';
import { MtaComponent } from './mta/mta.component';
import { ReadComponent } from './read/read.component';
import { PipesModule } from '../../../../../core/_pipes/pipes.module';
import { UiModule } from '../../../../../core/_ui/ui.module';
import { EmailActionModule } from '../../email-action/email-action.module';
import { MessageModule } from '../../../../shared/message/message.module';
import { SearchModule } from 'src/app/gws/shared/search/search.module';
import { ListModule } from 'src/app/gws/shared/list/list.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        PipesModule,
        EmailActionModule,
        MessageModule,
        UiModule,
        ListModule,
        SearchModule,
        MatTooltipModule,
        MatButtonModule,
        MatTableModule
    ],
  declarations: [
    DetailsComponent,
    MtaComponent,
    ReadComponent,
    VerdictComponent,
  ],
  exports: [
    DetailsComponent,
    MtaComponent,
    ReadComponent,
    VerdictComponent,
  ]
})
export class ModalTabsModule { }
