export enum MessageLocale {
  list = 'list',
  modal = 'modal',
  settings = 'settings',
  modalCondition = 'modalCondition',
  modalDisposition = 'modalDisposition',
  emailTracker = 'emailTracker',
}

export enum MessageType {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export interface MessageConfig {
  locale: MessageLocale;
  type: MessageType;
  message: string;
}
