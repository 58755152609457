import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';

import { StorageService } from '../_services/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.storageService.getItem('token');
    const contentType = request.headers.get('Content-Type');

    if (token !== null) {
      token = JSON.parse(token);
      const newHeaders = {Authorization: token};

      if (contentType == null) {
        newHeaders['Content-Type'] = 'application/json';
      }

      request = request.clone({
        headers: new HttpHeaders(newHeaders)
      });
    }
    return next.handle(request);
  }
}
