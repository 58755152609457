export class PermissionsListModel {
  C: string[] = [];
  R: string[] = [];
  U: string[] = [];
  D: string[] = [];
  E: string[] = [];

  constructor() {}
}

export class FieldListModel {
  columns: object = [];
  currentPage = 1;
  filter = '';
  messages: string[] = [];
  permissions: PermissionsListModel = new PermissionsListModel();
  rows: object[] = [];
  rowsCount = 0;
  title = '';
  totalCount = 0;
  totalPages = 0;

  constructor() {}
}

export function getInitialListFields(): FieldListModel {
  return (new FieldListModel());
}

export const cleanList: FieldListModel = getInitialListFields();
