export enum ListActionModel {
  EDIT = 'edit',
  SHOW = 'show',
  DELETE = 'delete',
  COPY = 'copy',

  NEW = 'new',
  ADD = 'add',
  SIGN_IN_CUSTOMER = 'signin_customer',
  RESET_PASSWORD = 'reset_password',
  IMPORT_NOW = 'import_now',
  SEND_DIGEST = 'sendDigest',
  CHANGE_STATUS = 'change_status',
  PAGINATION = 'pagination',
  SIGN_IN = 'signIn',
  SEARCH = 'search',
  CHANGE_PAGE_SIZE = 'change_page_size',
  REFRESH_LIST = 'refresh_list',
  UNLOCK_ADMIN = 'unlock_admin',
}
