import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { SubFormInterface } from '../../models/form/sub-form-interface';
import {
  arrayValidator,
  IPOrSubNetValidator,
  isRegex,
  multipleDomainsValidator,
  multipleEmailValidator,
} from '../../../_helpers/validators/validators';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';
import {
  MessageLocale,
  MessageType,
} from '../../../../gws/shared/message/models/message.model';
import { MessageService } from '../../../../gws/shared/message/service/message.service';

@Component({
  selector: 'app-operator-array',
  templateUrl: './array.component.html',
  styleUrls: ['./array.component.scss'],
})
export class OperatorArrayComponent
  implements OnInit, OnChanges, SubFormInterface
{
  @Input() public id: string;
  @Input() public type = 'text';

  @Input('value')
  public set value(value) {
    if (value === null) {
      this._value = this.type === 'text' ?? [''];
      return;
    }
    this._value = value;
  }
  getLabels() {
    return this.id === 'notify_blocking' || this.id === 'notify_releasing'
      ? 'params_' + this.id
      : this.type === 'email'
      ? 'recipients'
      : 'values';
  }

  public entityForm: FormGroup;
  public emailForm: FormGroup;
  public _value;
  private _sweetAlert = new SweetAlertService();
  public newEmailList;
  public validators = {
    email: multipleEmailValidator(),
    text: arrayValidator(),
    domain: multipleDomainsValidator(),
    regex: isRegex(),
    network: IPOrSubNetValidator(),
  };

  constructor(
    private _formBuilder: FormBuilder,
    private _messageService: MessageService
  ) {
    this.entityForm = new FormGroup({
      value: new FormControl('', arrayValidator()),
    });
    setTimeout(() => {
      if (this.type !== 'text') {
        this.entityForm = new FormGroup({
          value: new FormControl('', this.validators[this.type]),
        });
      }
    }, 500);
  }

  ngOnInit() {
    this.emailForm = this._formBuilder.group({
      array: this._formBuilder.array([]),
    });
    this.initValues();
  }

  ngOnChanges(changes) {
    if (changes.type['currentValue']) {
      const newValidator = changes.type['currentValue'];
      this.entityForm.controls['value'].setValidators(
        this.validators[newValidator]
      );
    }
    this.entityForm.controls['value'].updateValueAndValidity();
  }

  public addEmail(): void {
    this.newEmailList = this.entityForm.getRawValue();
    this.newEmailList = this.newEmailList.value
      .split(';')
      .map((value) => value.trim());
    if (this.entityForm.controls['value'].valid) {
      this.newEmailList.forEach((value) => {
        if (value !== '') {
          this.formData.controls.push(value);
          this.formData.value.push(value);
        }
        const duplicate = this.hasDuplicates(this.formData.controls);
        if (duplicate) {
          this._messageService?.setMessageConfig(
            this.type === 'email' ? 'emailAlreadyExist' : 'valueAlreadyExist',
            MessageType.error,
            MessageLocale.modal
          );
          this.formData.controls.splice(duplicate, 1);
          this.formData.value.splice(duplicate, 1);
        }
      });
      this.entityForm.setValue({ value: '' });
    }
  }

  public hasDuplicates(array) {
    let i;
    for (i = 0; i < array.length; i++) {
      if (array.indexOf(array[i]) !== i) {
        return i;
      }
    }
    return false;
  }

  public initValues(): void {
    if (this._value.length > 0) {
      this._value.forEach((value) => {
        this.formData.controls.push(value);
        this.formData.value.push(value);
      });
    }
  }

  public deleteEmail(index: number): void {
    this._openConfirmDeleteDialog(() => {
      this.formData.controls.splice(index, 1);
      this.formData.value.splice(index, 1);
    });
  }

  public get formData(): FormArray {
    return this.emailForm.get('array') as FormArray;
  }

  public formId(i): string {
    return this.id + '_' + i;
  }

  public validForm(): boolean {
    return this.formData?.value?.length > 0;
  }

  public valueForm() {
    const value = [];
    this.formData.value.forEach((val, i) => {
      const idFormControl = this.id + '_' + i;
      const formValue = (this.type = 'text'
        ? val[idFormControl]
        : +val[idFormControl]); //TODO: descobrir o que isso quer dizer
      value.push(formValue);
    });
    return value;
  }

  private _openConfirmDeleteDialog(removeComponent: () => void) {
    this._sweetAlert.alert(
      {
        title: 'delete',
        text: 'confirmDelete',
        showCancelButton: true,
      },
      (result) => {
        if (result.isConfirmed) {
          removeComponent();
        }
      }
    );
  }
}
