import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AclGuard } from './core/_guards/acl/guard/acl.guard';

const routes: Routes = [
  {
    path: 'system',
    loadChildren: () =>
      import('./gws/system/system.module').then((m) => m.SystemModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        locale: 'shallowModule',
        permission: 'systemMenu',
      },
    },
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./gws/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
    canLoad: [AclGuard],
    data: {
      acl: {
        locale: 'shallowModule',
        permission: 'administrationMenu',
      },
    },
  },
  {
    path: 'protection',
    loadChildren: () =>
      import('./gws/protection/protection.module').then(
        (m) => m.ProtectionModule
      ),
    canLoad: [AclGuard],
    data: {
      acl: {
        locale: 'shallowModule',
        permission: 'protectionMenu',
      },
    },
  },
  {
    path: 'sandbox',
    loadChildren: () =>
      import('./gws/sandbox/sandbox.module').then((m) => m.SandboxModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        locale: 'shallowModule',
        permission: 'free', // 'sandboxMenu'
      },
    },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./gws/login/login.module').then((m) => m.LoginModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'free',
      },
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./gws/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'loggedIn',
      },
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./gws/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'loggedIn',
      },
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./gws/auth/auth.module').then((m) => m.AuthModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'loggedIn',
      },
    },
  },
  {
    path: 'email-tracker',
    loadChildren: () =>
      import('./gws/email/email.module').then((m) => m.EmailTrackerModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'loggedIn',
      },
    },
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./gws/legal/legal.module').then((m) => m.LegalModule),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'loggedIn',
      },
    },
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./gws/shared/screen-errors/screen-errors.module').then(
        (m) => m.ScreenErrorsModule
      ),
    canLoad: [AclGuard],
    data: {
      acl: {
        permission: 'free',
      },
    },
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
