import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../../../core/_pipes/pipes.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    ToastrModule.forRoot({ progressBar: true, timeOut: 3000, maxOpened: 1 }),
  ],
})
export class MessageModule {}
