import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '../../_services/translation.service';
import { TranslationRowsModel } from '../../_models/translation-model';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  public translate: TranslationRowsModel;

  constructor(private _service: TranslationService) {}

  public transform(value: string, key: string = 'fe', args: any[] = []): string {
    try {
      return this.getWord(value, key, args);
    } catch (e) {
      return value;
    }
  }

  public getWord(value, key, args): string {
    this.translate = this._service.getTranslate();

    if (this.translate === null) {
      return value;
    }

    let word =
      this.translate[key][value] === undefined
        ? value
        : this.translate[key][value];

    if (args.length > 0) {
      args.forEach((arg, argKey) => {
        word = word.replace(`{${argKey}}`, arg);
      });
    }

    return word;
  }
}
