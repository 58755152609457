import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

import { UiBaseDirective } from '../../../base/ui-base';

@Component({
  selector: 'app-ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UiSwitchComponent),
    multi: true,
  }],
})
export class UiSwitchComponent extends UiBaseDirective implements ControlValueAccessor, AfterViewInit, OnInit {
  @Input() public value: boolean;
  @Output() public toggle = new EventEmitter();
  public checked = false;

  @ViewChild('checkboxElement') protected _element: ElementRef;

  constructor(
    protected _renderer: Renderer2,
    protected _formBuilder: FormBuilder,
  ) {
    super(_renderer);
  }

  ngOnInit(): void {
    if (!this.control) {
      this.addControl();
    }
  }

  ngAfterViewInit(): void {
    this._element.nativeElement.disabled = this.readonly;
  }

  public addControl() {
    this.control = this._formBuilder.group({
      [this.id]: [this.value],
    });
  }

  public get checkboxElement(): ElementRef {
    return this.element;
  }

  public writeValue(obj: any): void {
    if (obj !== null && obj !== undefined) {
      this.checked = obj;
    }
  }

  public onChange(event: any) {
    this.checked = event.target.checked;
    this._onChange(event.target.checked);
    this.toggle.emit(event.target.checked);
  }
}
