import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import * as Moment from 'moment';

import { UiBaseDirective } from '../../base/ui-base';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TimeRangeModalComponent } from '../time-range-modal/time-range-modal.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('language') },
  ],
})
export class DateRangeFilterComponent
  extends UiBaseDirective
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() startDate: Moment.Moment = null;
  @Input() endDate: Moment.Moment = null;
  @Input() returnOnStart = false;
  @Input() prependIcon = false;
  @Input() useCustomRanges = true;
  @Input() inputHidden = false;
  @Input() fromTracker = false;
  @Input() timePicker = false;
  @Output() applyDateRange = new EventEmitter<any>();

  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  public currentDateRange = this.range;

  public options: any = {
    startDate: Moment().subtract(29, 'days'),
    endDate: Moment(),
    minDate: Moment().subtract(10, 'years'),
    maxDate: Moment(),
    showDropdowns: true,
    alwaysShowCalendars: true,
  };

  public constructor(
    protected _renderer: Renderer2,
    private _dialog: MatDialog
  ) {
    super(_renderer);
  }

  ngAfterViewInit() {
    this.range.valueChanges.pipe(debounceTime(200)).subscribe((event) => {
      if (event.start && event.end) {
        this.ngOnChanges();
      }
    });
  }

  openPicker() {
    const dialog = this._dialog.open(TimeRangeModalComponent, {
      data: {
        options: this.options,
      },
      disableClose: true,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.currentDateRange.controls['start'].setValue(
          this.options.startDate
        );
        this.currentDateRange.controls['end'].setValue(this.options.endDate);
      }
    });
  }

  private _setStartAndEndDates() {
    this.currentDateRange.controls['start'].setValue(
      !this.fromTracker ? this.options.startDate : Moment()
    );
    this.currentDateRange.controls['end'].setValue(this.options.endDate);
  }

  private _onApplyDateRange(): void {
    if (this.returnOnStart) {
      this.applyDateRange.emit(this.currentDateRange.value);
    }
  }

  public ngOnInit(): void {
    this._setStartAndEndDates();
    if (this.timePicker) {
      const actualTime = Moment().toDate();
      actualTime.setHours(0, 0, 0, 0);
      this.options.startDate = Moment(actualTime);
      actualTime.setHours(23, 59, 59, 999);
      this.options.endDate = Moment(actualTime);
      this.options.maxDate = Moment(actualTime);
    }
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this._onApplyDateRange();
    });
  }
}
