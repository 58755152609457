import { Injectable } from '@angular/core';
import { ApiCommunicatorService } from '../../../_services/api-communicator/api-communicator.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuideService extends ApiCommunicatorService {

  _init_() {
    this.callUrl = 'user-guide';
  }

  getUserGuide() {
    return this.apiGet();
  }
}
