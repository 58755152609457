<app-ui-modal-form
  [title]="'uploadCSV' | translate: 'titles'"
  (modalEvent)="modalEvent($event)"
  [readonly]="readonly"
  [buttonLoading]="loading"
>
  <form class="gatefy-form" autocomplete="off">
    <div class="form-row">
      <app-ui-labeled
        [label]="'csvFile' | translate: 'labels'"
        [colClass]="'col-sm-12'"
      >
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            [id]="'file'"
            accept=".csv"
            (change)="onFileChange($event)"
          />
          <label class="custom-file-label" for="file">{{
            fileName ? fileName : ('csvFile' | translate: 'labels')
          }}</label>
        </div>
      </app-ui-labeled>
    </div>
  </form>
</app-ui-modal-form>
