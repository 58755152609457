import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-labeled',
  templateUrl: './labeled.component.html',
  styleUrls: ['./labeled.component.scss'],
})
export class UiLabeledComponent implements OnInit {
  @Input() public id = '';
  @Input() public label = '';
  @Input() public colClass = 'col-sm-12';
  @Input() public isSubtitle = false;
  @Input() public hasError: boolean;
  @Input() public isRequired = false;
  @Input() public tooltip = false;
  @Input() public tooltipMessage = '';

  constructor() {}

  ngOnInit(): void {}
}
