import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormService } from '../../../services/form/form.service';
import { UiSelectComponent } from '../select/select.component';
import { FieldOptionModel } from '../../../models/form/field-form.model';
import { CollectionSelectModel } from '../../../models/select/collection-select.model';

@Component({
  selector: 'app-ui-labeled-select',
  templateUrl: './labeled-select.component.html',
  styleUrls: ['./labeled-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiLabeledSelectComponent),
      multi: true,
    },
  ],
})
export class UiLabeledSelectComponent implements ControlValueAccessor {
  @Output() public change = new EventEmitter();
  @Input() public label: string;
  @Input() public id: string;
  @Input() public colClass: string;
  @Input() public valueField = 'id';
  @Input() public labelField = 'name';
  @Input() public collection: CollectionSelectModel[] = [];
  @Input() public control: FormGroup;
  @Input() public readonly = false;
  @Input() public translationFile = 'labels';
  @Input() public dropdownWidth: number;
  @Input() public placeholder = '';
  @Input() public isModal = false;
  @Input() public tooltip = false;
  @Input() public tooltipMessage = '';
  public value = '';

  @ViewChild('select', { static: true }) private _select: UiSelectComponent;

  constructor(public formService: FormService) {
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._select.setDisabledState(isDisabled);
  }

  public onChange(event: Event) {
    this.change.emit(event);
  }

  public get selectElement(): ElementRef {
    return this._select.selectElement;
  }

  public get select() {
    return this._select;
  }

  private _onChange(_: any) {
  }

  private _onTouched() {
  }
}
