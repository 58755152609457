<div class="mt-4 content-wrapper">
  <div *ngIf="!postfixData.length && (!fields.mtoReturn || !fields.mtoReturn.length)" class="container">
    {{ 'datatable__info_empty' | translate }}
  </div>

  <!-- Postfix return  -->
  <div *ngIf="postfixData.length" class="container">
    <ng-container *ngFor="let postfixItem of postfixData; let i = index">
      <hr *ngIf="i" class="my-4"/>
      <ng-container *ngIf="postfixItem.parsed">
        <div class="mta-show-original">
          <button
            (click)="postfixPopOpen(postfixItem)"
            type="button"
            class="btn btn-primary"
            [ngbPopover]="popContent"
            autoClose="outside"
            placement="bottom-right"
          >
            {{ 'show_original' | translate }}
          </button>
        </div>
        <div
          *ngFor="let postfixParsedIndex of postfixItem.getParsedIndexes()"
          class="row"
        >
          <div class="col-3">{{ postfixParsedIndex.toUpperCase() }}=</div>
          <div
            [class.font-weight-bolder]="
              postfixParsedIndex.toUpperCase() === 'STATUS'
            "
            class="col-9"
          >
            {{ postfixItem.parsed[postfixParsedIndex] }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!postfixItem.parsed">
        {{ postfixItem.original }}
      </ng-container>
    </ng-container>
  </div>

  <!-- MTO return  -->
  <div *ngIf="fields.mtoReturn && fields.mtoReturn.length" class="container">
    <ng-container *ngFor="let mtoReturnData of fields.mtoReturn; let i = index">
      <table class="table table-striped">
        <tbody>
        <tr *ngFor="let mtoKey of mtoKeysOrder">
          <td class="col-3 p-1">{{ mtoKey.toUpperCase() }}=</td>
          <td class="col-9 p-1"
              [class.font-weight-bolder]="mtoKey === 'result'"
          >
            <ng-container *ngIf="!['logs', 'timestamp'].includes(mtoKey)">
              {{ mtoReturnData[mtoKey] }}
            </ng-container>
            <ng-container *ngIf="mtoKey === 'timestamp'">
              {{ parseTimeStamp(mtoReturnData[mtoKey]) }}
            </ng-container>
            <ng-container *ngIf="mtoKey === 'logs'">
              <ng-container *ngTemplateOutlet="tmplLogs; context: {$implicit: mtoReturnData[mtoKey]}"></ng-container>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="p-0"></td>
        </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>

<ng-template #popContent>
  <div *ngIf="postfixPopData" class="container-fluid popData">
    <div class="row wrap-text">
      {{ postfixPopData.original }}
    </div>
  </div>
</ng-template>

<ng-template #tmplLogs let-logs>
  <table class="table table-default table-hover">
    <tbody>
    <tr *ngFor="let log of logs">
      <td class="p-0">
        {{ log }}
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>
