<div class="card card-primary card-outline">
  <app-loading *ngIf="!emailLoaded; else readMail"></app-loading>
  <ng-template #readMail>
    <div class="email-read-actions d-flex justify-content-between">
      <app-email-action
        (valueChange)="onSaveChange($event)"
        [disableDropDown]="true"
        [emailsSelected]="selectedEmails"
      >
      </app-email-action>
      <button
        (click)="openModal(emailId)"
        class="btn btn-outline-primary btn-show-original"
        type="button"
      >
        {{ "Show Original" | translate: "titles" }}
      </button>
      <button
        (click)="ngbActiveModal.dismiss('')"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body p-0">
      <div class="card-header">
        <h5 class="card-title">{{ email.subject }}</h5>
        <h6>
          From: {{ email.sender }}
          <span class="email-read-time float-right">
            {{ email.date | sqlDate: sqlDateModel.FULL_DATE_TIME }}
          </span>
        </h6>
      </div>
      <div class="card-body p-0">
        <!-- TODO: Get only body -->
        <div class="email-read-message" innerHtml="{{ email.content }}"></div>
      </div>
      <div class="card-footer bg-white"></div>
    </div>
  </ng-template>
</div>
