// FROM: https://github.com/DoubleCheck/postfix-parser

import { Injectable } from '@angular/core';

export interface Postfix {
  'message-id'?: string;
  'x-gatefy-id'?: string;
  conn_use?: string;
  date?: string;
  delay?: string;
  delays?: string;
  dsn?: string;
  dsnQid?: string;
  forwardedAs?: string;
  from?: string;
  host?: string;
  msg?: string;
  nrcpt?: string;
  pid?: string;
  prog?: string;
  qid?: string;
  relay?: string;
  size?: string;
  statistics?: string;
  status?: string;
  to?: string;
  uid?: string;
  action?: string;
  mx?: string;
  err?: string;
  postscreen?: string;
  info?: string;
}

export const envEmailAddr = '<?([^>,]*)>?';
export const postfixQid = '[0-9A-F]{10,11}';     // default queue ids
export const postfixQidLong = '[0-9A-Za-z]{12,20}';  // optional 'long' ids
export const postfixQidAny = postfixQidLong + '|' + postfixQid;
export const regex = {
  syslog: /^([A-Za-z]{3} [0-9 ]{2} [\d:]{8}) ([^\s]+) ([^\[]+)\[([\d]+)\]: (.*)$/,
  'submission/smtpd': new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(client)=([^,]+), ' +
    '(sasl_method)=([^,]+), ' +
    '(sasl_username)=(.*)$',
  ),
  smtp: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(to)=' + envEmailAddr + ', ' +
    '(?:(orig_to)=' + envEmailAddr + ', )?' +
    '(relay)=([^,]+), ' +
    '(?:(conn_use)=([0-9]+), )?' +
    '(delay)=([^,]+), ' +
    '(delays)=([^,]+), ' +
    '(dsn)=([^,]+), ' +
    '(status)=(.*)$',
  ),
  'smtp-defer': new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(?:host) ([^ ]+) ' +
    '(?:said|refused to talk to me): ' +
    '(4[0-9]{2} .*)$',
    // '(?: \\(in reply to (?:end of )?([A-Z ]+) command\\))*'
  ),
  'smtp-timeout': new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    'conversation with ([^ ]+) ' +
    'timed out ' + '(.*)$',
  ),
  'smtp-reject': new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    'host ([^ ]+) ' +
    '(?:said|refused to talk to me): (5[0-9]{2}.*)$',
  ),
  'smtp-conn-err': /^connect to ([^ ]+): (.*)$/,
  'smtp-debug': new RegExp(
    '(?:(' + postfixQidAny + '): )?' +
    '(enabling PIX workarounds|' +
    'Cannot start TLS: handshake failure|' +
    'lost connection with .*|' +
    '^SSL_connect error to .*|' +
    '^warning: .*|' +
    'conversation with |' +
    'host )',
  ),
  qmgr: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(from)=' + envEmailAddr + ', ' +
    '(?:' +
    '(size)=([0-9]+), ' +
    '(nrcpt)=([0-9]+) ' +
    '|' +
    '(status)=(.*)$' +
    ')',
  ),
  'qmgr-retry': new RegExp('^(' + postfixQidAny + '): (removed)'),
  // postfix sometimes truncates the message-id, so don't require ending >
  cleanup: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '((?:resent-)?message-id)=<(.*?)>?$',
  ),
  'cleanup-info': new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(?:(info): ([^;]+)?; )?' +
    '(?:(from)=' + envEmailAddr + ' )?' +
    '(to)=' + envEmailAddr +
    ' ?$',
  ),
  pickup: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(uid)=([0-9]+) ' +
    '(from)=' + envEmailAddr,
  ),
  'pickup-retry': new RegExp(
    '^warning: ' +
    '(' + postfixQidAny + '): ' +
    '(.*)$',
  ),
  error: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(to)=' + envEmailAddr + ', ' +
    '(?:(orig_to)=' + envEmailAddr + ', )?' +
    '(relay)=([^,]+), ' +
    '(delay)=([^,]+), ' +
    '(delays)=([^,]+), ' +
    '(dsn)=([^,]+), ' +
    '(status)=(.*)$',
  ),
  'error-retry': new RegExp(
    '^warning: ' +
    '(' + postfixQidAny + '): ' +
    '(.*)$',
  ),
  bounce: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    'sender non-delivery notification: ' +
    '(' + postfixQidAny + '$)',
  ),
  'bounce-fatal': new RegExp(
    '^fatal: ' +
    '(.*?) ' +
    '(' + postfixQidAny + '): ' +
    '(.*)$',
  ),
  local: new RegExp(
    '^(?:(' + postfixQidAny + '): )?' +
    '(to)=' + envEmailAddr + ', ' +
    '(?:(orig_to)=' + envEmailAddr + ', )?' +
    '(relay)=([^,]+), ' +
    '(delay)=([^,]+), ' +
    '(delays)=([^,]+), ' +
    '(dsn)=([^,]+), ' +
    '(status)=(sent .*)$',
  ),
  forwardedAs: new RegExp('forwarded as (' + postfixQidAny + ')\\)'),
  scache: new RegExp('^statistics: (.*)'),
  postscreen: new RegExp('^(.*)'),
  postsuper: new RegExp('^(' + postfixQidAny + '): (.*)$'),
  'x-gatefy-id': /header X[\-_]Gatefy[\-_]Id: ([^\s]+) /i,
};

@Injectable({providedIn: 'root'})
export class PostfixParser {

  asObject(line: string) {
    const match = line.match(regex.syslog);
    if (!match) {
      throw new TypeError('unparsable syslog: ' + line);
    }

    const syslog = this._syslogAsObject(match);
    if (!/^postfix/.test(syslog.prog)) {
      return;
    } // not postfix, ignore

    const parsed = this.asObjectType(syslog.prog, syslog.msg);
    if (!parsed) {
      throw new TypeError('unparsable ' + syslog.prog + ': ' + syslog.msg);
    }

    ['date', 'host', 'prog', 'pid'].forEach((f) => {
      if (!syslog[f]) {
        return;
      }
      parsed[f] = syslog[f];
    });

    return parsed;
  }

  asObjectType(type: string, line: string): Postfix {
    if (!type || !line) {
      throw new SyntaxError('missing required arg');
    }
    if ('postfix/' === type.substring(0, 8)) {
      type = type.substring(8);
    }

    switch (type) {
      case 'qmgr':
      case 'pickup':
      case 'error':
      case 'submission/smtpd':
        return this._argAsObject(type, line);
      case 'smtp':
        return this._smtpAsObject(line);
      case 'cleanup':
        return this._cleanupAsObject(line);
      case 'bounce':
        return this._bounceAsObject(line);
      case 'x-gatefy-id':
        const mathId = line.match(regex[type]);
        return {
          'x-gatefy-id': mathId ? mathId[1] : null,
          ...this.asObjectType('syslog', line),
        };
    }

    const match = line.match(regex[type]);
    if (!match) {
      return;
    }

    switch (type) {
      case 'syslog':
        return this._syslogAsObject(match);
      case 'scache':
        return {statistics: match[1]};
      case 'postscreen':
        return {postscreen: match[1]};
      case 'local':
        return this.localAsObject(match);
      case 'postsuper':
        return {qid: match[1], msg: match[2]};
    }

    return this._matchAsObject(match);
  }

  protected _syslogAsObject(match) {
    return {
      date: match[1],
      host: match[2],
      prog: match[3],
      pid: match[4],
      msg: match[5],
    };
  }

  protected _matchAsObject(match) {
    match.shift();
    const obj: Postfix = {};
    const qid = match.shift();
    if (qid) {
      obj.qid = qid;
    }
    while (match.length) {
      const key = match.shift();
      const val = match.shift();
      if (key === undefined) {
        continue;
      }
      if (val === undefined) {
        continue;
      }
      obj[key] = val;
    }
    return obj;
  }

  protected _argAsObject(thing, line) {
    let match = line.match(regex[thing]);
    if (match) {
      return this._matchAsObject(match);
    }

    match = line.match(regex[thing + '-retry']);
    if (match) {
      return {qid: match[1], msg: match[2]};
    }
  }

  protected _cleanupAsObject(line): Postfix {
    let match = line.match(regex.cleanup);
    if (match) {
      return this._matchAsObject(match);
    }

    match = line.match(regex['cleanup-info']);
    if (match) {
      return this._matchAsObject(match);
    }

    return {};
  }

  protected _smtpAsObject(line): Postfix {
    let match = line.match(regex.smtp);
    if (match) {
      return this._matchAsObject(match);
    }

    match = line.match(regex['smtp-conn-err']);
    if (match) {
      return {
        action: 'delivery',
        mx: match[1],
        err: match[2],
      };
    }

    match = line.match(regex['smtp-defer']);
    if (match) {
      return {
        action: 'defer',
        qid: match[1],
        host: match[2],
        msg: match[3],
      };
    }

    match = line.match(regex['smtp-reject']);
    if (match) {
      return {
        action: 'reject',
        qid: match[1],
        host: match[2],
        msg: match[3],
      };
    }

    match = line.match(regex['smtp-timeout']);
    if (match) {
      return {
        action: 'defer',
        qid: match[1],
        host: match[2],
        msg: match[3],
      };
    }

    match = line.match(regex['smtp-debug']);
    if (!match) {
      return;
    }
    if (match[1] && match[2]) {
      return {
        qid: match[1],
        msg: match[2],
      };
    }
    return {msg: match[0]};
  }

  protected _bounceAsObject(line) {

    let match = line.match(regex.bounce);
    if (match) {
      match.shift();
      const obj: Postfix = {};
      const qid = match.shift();
      if (qid) {
        obj.qid = qid;
      }
      obj.dsnQid = match.shift();
      return obj;
    }

    match = line.match(regex['bounce-fatal']);
    if (match) {
      return {
        qid: match[2],
        msg: 'fatal: ' + match[1] + ': ' + match[3],
      };
    }
  }

  protected localAsObject(match) {
    const obj = this._matchAsObject(match);
    const m = obj.status.match(regex.forwardedAs);
    if (m) {
      obj.status = 'forwarded';
      obj.forwardedAs = m[1];
    }
    return obj;
  }

}
