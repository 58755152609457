<div
  (mouseover)="closeEveryMenu()"
  *ngIf="hasMenuOpen"
  class="close-element thin"
></div>
<div
  (mouseover)="closeEveryMenu()"
  *ngIf="hasMenuOpen"
  class="close-element"
></div>

<div class="license-wrapper">
  <app-license
    (eulaSet)="setEula($event)"
  ></app-license>
</div>

<div class="profile-wrapper">
  <app-ui-profile-menu [isOwner]="!_isOwner" (logoutEvent)="logout()"></app-ui-profile-menu>
</div>

<div class="check-hidden" #matCB>
  <mat-checkbox>{{ 'eulaConfirmCheck' | translate: 'labels' }}</mat-checkbox>
</div>

<div [ngClass]="!_isOwner ? 'sidebar' : 'sidebar anotherCustomer'">
  <div class="logo-wrapper">
    <img
      (click)="home()"
      class="gatefy-logo"
      src="../../../../../assets/icones/menu/gatefy-badge.svg"
      alt=""
    />
  </div>
  <div #wrapper class="options-wrapper">
    <div
      *ngFor="let menu of menus; let i = index"
      [class.active]="menu.active"
      class="nav-item"
      (mouseover)="openMenu(menu)"
    >
      <a class="nav-link" routerLink="{{ menu.href }}">
        <img
          class="option-image"
          src="../../../../../assets/icones/menu/{{ menu.name }}.svg"
        />
        {{ menu.name | translate }}
      </a>
      <ng-container *ngIf="menu.subMenu && menu.isCollapsed">
        <div class="connector"></div>
        <div class="sub-menu">
          <table>
            <td class="menu-column" *ngFor="let sub of menu.subMenu">
              <table class="internal-sub-menu">
                <tr>
                  <th
                    class="first-item-menu"
                    (click)="sub.isCollapsed = !sub.isCollapsed"
                  >
                    <p>{{ sub.name | translate }}</p>
                    <div class="divider"></div>
                  </th>
                </tr>

                <tr *ngFor="let subSubMenu of sub.subMenu">
                  <td>
                    <a
                      class="menu-option"
                      routerLink="{{ subSubMenu.href }}"
                      (click)="resetMessageService()"
                    >
                      {{ subSubMenu.name | translate }}
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="_isOwner" class="backRoot-wrapper">
    <a
      (click)="backAsRoot()"
      class="btn btn-outline-warning interface-swapper"
      matTooltip="{{'another_customer' | translate: 'labels'}}"
      matTooltipPosition="right"
    >
      {{ 'change_ui' | translate: 'labels' }}
    </a>
  </div>

  <div class="policy-wrapper">
    <a
      [routerLink]="'privacyPolicyHref' | translate: 'cookie'"
      class="policy-interface"
    >
      {{ 'privacy-policy' | translate: 'labels' }}
    </a>
  </div>
  <div class="manual-wrapper">
    <a class="guide-interface" href="javascript:void(0)" (click)="openUserGuideModal()">
      {{ 'user_guide' | translate: 'labels' }}
    </a>
  </div>
</div>
<app-support-btn></app-support-btn>
