<span class="gatefy-subtitle">{{ title | translate }}</span>

<div class="gatefy-content-box gatefy-encapsulated">
  <div
    class="row mb-3"
    [ngClass]="{ 'mb-5': !hasSearchBox && pushTop, 'mb-4': emailTracker }"
  >
    <div class="col-12 col-lg-7 col-md-12 col-sm-12">
      <ng-content *ngIf="hasSearchBox" select="[search-box]"></ng-content>
    </div>
    <div
      *ngIf="hasPageSizeSelector || showNew()"
      class="col-12 col-lg-5 col-md-12 push-top d-flex btns-wrapper justify-content-end actions-container"
    >
      <app-select-page-size
        *ngIf="hasPageSizeSelector"
        [numbers]="listSizes"
        [currentPageSize]="currentPageSize"
        (valueSelected)="onPageSizeChange($event)"
      ></app-select-page-size>
      <ng-container *ngIf="!loading && showNew()">
        <button
          type="button"
          data-cy="btnListAdd"
          class="btn btn-primary btn-sm btn-list-add"
          (click)="setAction({ event: 'new' })"
        >
          <span class="fa fa-plus"></span> {{ 'btn_add' | translate }}
        </button>
      </ng-container>
    </div>
  </div>
  <app-loading *ngIf="loading"></app-loading>
  <ng-container *ngIf="!loading && list.rowsCount > 0">
    <div class="table-wrapper">
      <table
        [ngClass]="{ 'layout-fixed': layoutFixed }"
        mat-table
        [dataSource]="records"
        [multiTemplateDataRows]="contentRow"
        matSort
        (matSortChange)="sortData($event)"
      >
        <colgroup>
          <ng-container *ngFor="let col of colGroupValue">
            <col span="1" [style]="'width: ' + col + '%'" />
          </ng-container>
        </colgroup>

        <ng-container matColumnDef="table-select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="checkboxAll = !checkboxAll; onCheckboxAllClick()"
              [checked]="checkboxAll"
              [indeterminate]="isIntermediate"
            >
            </mat-checkbox>
          </th>
          <td class="thin-col" mat-cell *matCellDef="let row; let i = index">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                checkboxModel[i] = !checkboxModel[i];
                onSingleCheckboxClick(row, i)
              "
              [checked]="!isDisabled(i) && checkboxModel[i]"
              [disabled]="isDisabled(i)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <ng-container *ngIf="!emailTracker">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'subject' | translate }}
            </th>
          </ng-container>
          <ng-container *ngIf="emailTracker">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'subject' | translate }}
            </th>
          </ng-container>
          <td
            [ngClass]="{
              'subject-column': row.subject.length > 150 && !sizeExpanded[i],
              tracker: emailTracker
            }"
            (click)="lineClick($event, row, i)"
            (dblclick)="lineDoubleClick($event, row, i)"
            mat-cell
            *matCellDef="let row; let i = index"
          >
            {{ row.subject }}
            <i
              class="fas large-cursor-pointer"
              [ngClass]="{
                'see-more-icon fa-chevron-circle-down': !sizeExpanded[i],
                'fa-chevron-circle-up': sizeExpanded[i]
              }"
              *ngIf="row.subject.length > 150"
              (click)="sizeExpanded[i] = !sizeExpanded[i]"
            ></i>
          </td>
        </ng-container>

        <ng-container *ngFor="let column of columns; let i = index">
          <ng-container
            *ngIf="column.id !== 'subject'"
            [matColumnDef]="column.id"
          >
            <ng-container
              *ngIf="
                !emailTracker ||
                column.id === 'status' ||
                column.id === 'readableFlag'
              "
            >
              <th mat-header-cell *matHeaderCellDef>
                {{ column.id | translate: 'labels' }}
              </th>
            </ng-container>
            <ng-container
              *ngIf="
                emailTracker &&
                column.id !== 'status' &&
                column.id !== 'readableFlag'
              "
            >
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ column.id | translate: 'labels' }}
              </th>
            </ng-container>
            <td
              [ngClass]="{ tracker: emailTracker }"
              (click)="lineClick($event, element, i)"
              (dblclick)="lineDoubleClick($event, element, i)"
              mat-cell
              *matCellDef="let element"
            >
              <ng-container
                *ngIf="
                  !array.isArray(element[column.id]) &&
                  !(column.id === 'verified') &&
                  !(column.id === 'importSource')
                "
              >
                <ng-container *ngIf="innerTranslate">
                  {{ element[column.id] | translate: 'labels' }}
                </ng-container>
                <ng-container *ngIf="!innerTranslate">
                  {{ element[column.id] | beautifyData: column }}
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="!innerTranslate && column.id === 'importSource'"
              >
                {{ element[column.id] | beautifyData: column }}&ensp;&ensp;
                <i
                  *ngIf="element['isDistributionList'] === '1'"
                  class="fas fa-envelope-open-text"
                  matTooltip="{{ 'tooltip_mailbox_list' | translate }}"
                ></i>
              </ng-container>

              <app-list-inbound-column
                *ngIf="column.id === 'verified'"
                [element]="element"
                (action)="setAction($event)"
              >
              </app-list-inbound-column>
              <!-- <app-list-record
                [record]="element[column.id]"
                [columns]="columns"
                (action)="setAction($event)"
                [type]="'text'"
                [pageDetails]="pageDetails"
              >
              </app-list-record> -->
              <ng-container *ngIf="array.isArray(element[column.id])">
                <ng-container *ngFor="let el of element[column.id]; last as l">
                  <p
                    *ngIf="
                      element[columns[0].id] === 'Ação' ||
                      element[columns[0].id] === 'Action'
                    "
                    [ngClass]="{ 'mb-0': l }"
                  >
                    {{ el }}
                  </p>
                  <ng-container
                    *ngIf="
                      !(
                        element[columns[0].id] === 'Ação' ||
                        element[columns[0].id] === 'Action'
                      )
                    "
                  >
                    {{ el }}
                  </ng-container>
                </ng-container>
                <div class="array-separator"></div>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="switchable" matColumnDef="toggle-select">
          <th class="actions-column" mat-header-cell *matHeaderCellDef>
            {{ 'status' | translate }}
          </th>
          <td class="thin-col" mat-cell *matCellDef="let row; let i = index">
            <div class="toggle-wrapper">
              <mat-slide-toggle
                (click)="$event.stopPropagation()"
                (change)="
                  checkboxModel[i] = !checkboxModel[i];
                  onSingleCheckboxClick(row, i)
                "
                [checked]="checkboxModel[i]"
                [disabled]="isDisabled(i)"
              ></mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="hasActions" matColumnDef="actions">
          <th class="actions-column" mat-header-cell *matHeaderCellDef>
            {{ 'list_actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="action-wrapper">
              <app-list-action
                [id]="element.id"
                [additionalAction]="additionalAction"
                [readonly]="element.isReadonly"
                [permissions]="permissions"
                [row]="element"
                (action)="setAction($event)"
              ></app-list-action>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="hasContent">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="element-detail"
              [@detailExpand]="element === expandedElement ? 'collapsed' : null"
            >
              <label>STATUS:&ensp;</label>
              <span class="descStatus">{{ element.status }}</span>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!contentRow">
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>

        <ng-container *ngIf="contentRow">
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="element-row"
            [class.expanded]="expandedElement === row"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['hasContent']"
            class="detail-row"
          ></tr>
        </ng-container>
      </table>
    </div>

    <app-ui-pagination
      *ngIf="list.totalPages > 1 && hasPagination"
      [currentPage]="list.currentPage"
      [totalPages]="list.totalPages"
      (requestPage)="setAction({ event: 'pagination', page: $event })"
    >
    </app-ui-pagination>
  </ng-container>
  <ng-container *ngIf="!loading && list.rowsCount === 0">
    <div class="alert alert-info" role="alert">
      {{ 'noRecords' | translate }}
    </div>
  </ng-container>
</div>
