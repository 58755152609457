import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { TranslationService } from 'src/app/core/_services/translation.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/core/_services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  private _translationService = new TranslationService(
    new HttpClient(null),
    new StorageService()
  );
  private _translate = new TranslatePipe(this._translationService);
  private readonly customSwal = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary modal-btn',
      cancelButton: 'btn btn-light modal-btn',
    },
    buttonsStyling: false,
  });

  constructor() {}

  public alert(configuration, callback) {
    this._hasMinimumConfiguration(configuration);
    configuration = this._setDefaults(configuration);
    configuration = this._setTranslations(configuration);

    this.customSwal.fire(configuration).then((result) => callback(result));
  }

  private _hasMinimumConfiguration(configuration): void {
    if (!configuration.title) {
      throw new Error(
        'O alerta exige um título (propriedade title) para a configuração mínima'
      );
    }
    if (!configuration.text) {
      throw new Error(
        'O alerta exige um texto (propriedade text) no corpo para a configuração mínima'
      );
    }
  }

  private _setDefaults(configuration) {
    configuration.icon = configuration.icon || 'warning';
    configuration.cancelButtonText =
      configuration.cancelButtonText ||
      this._translate.transform('btn_cancel', 'fe');
    configuration.confirmButtonText =
      configuration.confirmButtonText ||
      this._translate.transform('btn_confirm', 'fe');
    configuration.position = configuration.position || 'top';

    if (configuration.reverseButtons !== false) {
      configuration.reverseButtons = true;
    }

    return configuration;
  }

  public disableConfirmButton(option: boolean) {
    const button = Swal.getConfirmButton() as HTMLButtonElement;
    return (button.disabled = option);
  }

  private _setTranslations(configuration) {
    configuration.title = this._translate.transform(configuration.title);
    configuration.text = this._translate.transform(configuration.text);
    return configuration;
  }
}
