import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslatePipe } from './pipes/translate.pipe';
import { SqlDatePipe } from './pipes/sql-date.pipe';
import { HighlightSearchPipe } from './pipes/highligh.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TranslatePipe, SqlDatePipe, HighlightSearchPipe],
  exports: [TranslatePipe, SqlDatePipe, HighlightSearchPipe],
})
export class PipesModule {}
