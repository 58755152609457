<ng-container *ngIf="hasError">
  <ng-container>
    <ng-container
      *ngTemplateOutlet="messageTemplate; context: errorMessage[getErrors(control.errors)[0]]"
    ></ng-container>
  </ng-container>
</ng-container>

<ng-template #messageTemplate let-message="message" let-params="params">
  <div class="row pl15px">
    <span class="text-error">
      {{ message | translate: "errors":params }}
    </span>
  </div>
</ng-template>
