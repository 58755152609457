import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

import { ModalTabsBaseDirective } from '../modal-tabs.base';
import { SqlDateModel } from '../../../../../../core/_models/sql-date/sql-date.model';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { FieldListModel } from 'src/app/core/_ui/models/list/field-list.model';
import { ColumnsTypeModel } from '../../../../../shared/list/models/columns-type.model';

@Component({
  selector: 'app-email-tracker-modal-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent
  extends ModalTabsBaseDirective
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild('subjectWrapper') public subjectWrapper: ElementRef;
  @ViewChild('recipientsWrapper') public recipientsWrapper: ElementRef;
  @ViewChild('senderMTAWrapper') public senderMTAWrapper: ElementRef;
  public sqlDateModel = SqlDateModel;
  public list = new FieldListModel();
  public show = [false, false, false];
  public loading = true;

  constructor(
    private _translate: TranslatePipe,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.list.rows = [
      {
        field: 'Gatefy-ID',
        value: this.fields['gfId'],
      },
      {
        field: this._translate.transform('ip', 'labels'),
        value: this.fields.connection?.ip,
      },
      {
        field: this._translate.transform('language', 'labels'),
        value: this.fields.emailData?.language,
      },
      {
        field: this._translate.transform('attachmentCount', 'labels'),
        value: this.fields.emailData?.attachmentsCount,
      },
      {
        field: this._translate.transform('country', 'labels'),
        value: this.fields.emailData?.ipCountry ?? '--',
      },
      {
        field: this._translate.transform('route', 'labels'),
        value: this.fields.emailData?.mailRoute,
      },
      {
        field: this._translate.transform('spf', 'labels'),
        value: this.fields.emailData?.spf,
      },
      {
        field: this._translate.transform('spfDetails', 'labels'),
        value:
          this.fields.emailData?.spfDetails !== ''
            ? this.fields.emailData?.spfDetails
            : '--',
      },
      {
        field: this._translate.transform('dmark', 'labels'),
        value: this.fields.emailData?.dmarcResult,
      },
      {
        field: this._translate.transform('dkim', 'labels'),
        value: this.fields.emailData?.dkim_result,
      },
    ];
    this.list.rowsCount = 10;
    this.list.totalCount = 10;
    this.list.totalPages = 1;
    this.loading = false;
  }

  public get columns(): ColumnsTypeModel[] {
    return [
      { id: 'field', label: 'field' },
      { id: 'value', label: 'value' },
    ];
  }

  public get isSubjectOverflowing() {
    if (this.subjectWrapper) {
      const element = this.subjectWrapper.nativeElement;
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }
    return false;
  }

  public get isRecipientOverflowing() {
    if (this.recipientsWrapper) {
      const element = this.recipientsWrapper.nativeElement;
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }
    return false;
  }

  public get isSenderMTAOverflowing() {
    if (this.senderMTAWrapper) {
      const element = this.senderMTAWrapper.nativeElement;
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    }
    return false;
  }
}
