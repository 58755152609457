import { Component, OnInit } from '@angular/core';

import { ModalTabsBaseDirective } from '../modal-tabs.base';
import { Postfix, PostfixParser } from '../../../../../../core/_helpers/postfix-parser';
import { DateTimeModel } from '../../../../../../core/_ui/models/form/date-time.model';

export class PostfixData {
  parsed: Postfix | null = null;
  original: string;

  constructor(original: string, parsed: Postfix | null = null) {
    this.original = original;
    this.parsed = parsed;
  }

  getParsedIndexes(): string[] {
    if (!this.parsed) {
      return [];
    }
    return Object.keys(this.parsed).sort();
  }
}

@Component({
  selector: 'app-email-tracker-modal-mta',
  templateUrl: './mta.component.html',
  styleUrls: ['./mta.component.scss'],
})
export class MtaComponent extends ModalTabsBaseDirective implements OnInit {

  protected _postfixParserTypes = [
    'syslog',
    'local',
    'error',
    'smtp',
    'cleanup',
    'postsuper',
  ];

  public postfixPopData: PostfixData = null;
  public postfixData: PostfixData[] = [];

  public mtoKeysOrder = [
    'timestamp',
    'gfid',
    'server',
    'banner',
    'sender',
    'recipients',
    'result',
    'logs',
  ];

  constructor(
    protected _postfixParser: PostfixParser,
  ) {
    super();
  }

  parseTimeStamp(timestamp, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
    const dateTime = DateTimeModel.fromUnixTimeStamp(timestamp);
    return dateTime.format(format);
  }

  postfixPopOpen(postfixModalData: PostfixData) {
    this.postfixPopData = postfixModalData;
  }

  ngOnInit() {
    this._parsePostfixData();
  }

  protected _parsePostfixData() {
    if (Array.isArray(this.fields.postfixReturn) && this.fields.postfixReturn.length) {

      this.fields.postfixReturn.forEach((postfixStr, index: number) => {
        let parsed: Postfix = null;
        try {
          parsed = this._gatefyIdHeader(postfixStr);
          if (parsed) {
            this.postfixData[index] = new PostfixData(
              postfixStr, parsed,
            );
          } else {
            parsed = this._postfixParser.asObject(postfixStr);
            if (parsed) {
              this.postfixData[index] = new PostfixData(
                postfixStr, parsed,
              );
            }
          }
        } catch (e) {
          try {
            this.postfixData[index] = new PostfixData(
              postfixStr, this._postfixParser.asObjectType(
                'syslog', postfixStr,
              ),
            );
          } catch (e) {
            this.postfixData[index] = new PostfixData(
              postfixStr,
            );
          }
        }

        try {
          if (!parsed) {
            parsed = this._parseByType(
              this._postfixParser.asObjectType(
                'syslog', postfixStr,
              ),
            );

            this.postfixData[index] = new PostfixData(
              postfixStr, parsed,
            );
          }
        } catch (e) {
          this.postfixData[index] = new PostfixData(
            postfixStr,
          );
        }
      });
    }
  }

  protected _parseByType(parsedBase: Postfix) {
    try {
      this._postfixParserTypes.forEach((logType: string) => {
        if (parsedBase && parsedBase.msg) {
          const logData = this._postfixParser.asObjectType(
            logType, parsedBase.msg,
          );

          if (logData) {
            parsedBase = {
              ...parsedBase,
              ...logData,
            };
          }
        }
      });
    } catch (e) {
    }

    return parsedBase;
  }

  protected _gatefyIdHeader(line: string): Postfix | null {
    try {
      const syslog: Postfix = this._postfixParser.asObjectType(
        'x-gatefy-id', line,
      );
      if (syslog['x-gatefy-id']) {
        return this._parseByType(syslog);
      }
    } catch (e) {
    }

    return null;
  }
}
