<app-ui-labeled
  [hasError]="formService.hasError(control, id)"
  [id]="id"
  [label]="label"
  [colClass]="colClass"
  [tooltip]="tooltip"
  [tooltipMessage]="tooltipMessage"
>
  <app-ui-select
    #select
    [id]="id"
    [(ngModel)]="value"
    [control]="control"
    [readonly]="readonly"
    [valueField]="valueField"
    [labelField]="labelField"
    [collection]="collection"
    [translationFile]="translationFile"
    [width]="dropdownWidth"
    (change)="onChange($event)"
    [placeholder]="placeholder"
    [isModal]="isModal"
  ></app-ui-select>
</app-ui-labeled>
