import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { StorageService } from './storage.service';
import { TranslationRowsModel } from '../_models/translation-model';
import { userId as getUserId } from '../../core/_helpers/util';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  public url = '/v1/translations/language';
  public translate: TranslationRowsModel;
  public supportedLanguages = [{ id: 1, name: 'english', shortname: 'en-US' }, { id: 2, name: 'portugues', shortname: 'pt-BR' }];
  public currentLanguage: string = ['pt-BR', 'en-US'].indexOf(navigator.language) >= 0 ? navigator.language : 'en-US';
  private _baseFilesPath = '/assets/i18n';
  private _translationFiles = [
    'cookie',
    'digest',
    'errors',
    'fe',
    'labels',
    'success',
    'titles'
  ];

  constructor(
    private _http: HttpClient,
    private _storageService: StorageService
  ) { }

  public setTranslate(checkSession = true): void {
    if (checkSession) {
      this.loadTranslationFromSession();
      return;
    }

    if (this.translate === undefined) {
      this.getTranslationFromFiles();
    }
  }

  public loadTranslationFromSession(): TranslationRowsModel {
    const fromSession = this._storageService.getItem('translate');

    if (fromSession === null) {
      this.setTranslate(false);
    }

    this.translate = JSON.parse(fromSession);
    return this.translate;
  }

  public getTranslate(): TranslationRowsModel {
    this.loadTranslationFromSession();
    return this.translate;
  }

  public getOrDefineTranslate(translate = null): Observable<TranslationRowsModel> {
    if (translate === null) {
      const fromSession = this._storageService.getItem('translate');

      if (fromSession === null) {
        this.getTranslationFromFiles().then( () => {
          this.translate = JSON.parse(fromSession);
        });
      } else {
        this.translate = JSON.parse(fromSession);
        return of(this.translate);
      }

    } else {
      this._storageService.setItem('translate', JSON.stringify(translate));
      return of(this.translate);
    }
  }

  public getTranslationFromFiles(): Promise<void> {
    return new Promise((resolve, reject) => {
      const files = this._translationFiles;
      const translationJSON: any = {};

      if (this.currentLanguage) {
        Promise.all(
          files.map((file) =>
            fetch(`${this._baseFilesPath}/${this.currentLanguage}/${file}.json`)
              .then((resp) => resp.json())
              .then((data) => {
                translationJSON[file] = data[file];
              })
          )
        ).then((result) => {
          this._storeTranslation(translationJSON);
          resolve();
        });
      }
    });
  }

  public resetLanguage(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUserLanguage().then(() => {
        this.setNewLanguage();
        this.getTranslationFromFiles().then(() => {
          resolve();
        });
      });
    });
  }

  public setNewLanguage(): void {
    this._storageService.setItem('language', this.currentLanguage);
  }

  public getUserLanguage(): Promise<void> {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(this._storageService.getItem('token'));

      if (token) {
        return fetch(`v1/me/info`, {
          headers: {
            authorization: token,
          },
          body: null,
          method: 'GET',
          credentials: 'include'
        }).then((resp) => resp.json())
          .then((data) => {
            this.currentLanguage = data.userLanguage;
            resolve();
          });
      }

      this.currentLanguage = this._storageService.getItem('language') || 'en-US';
      resolve();
    });
  }

  private _storeTranslation(translation: TranslationRowsModel): void {
    this._storageService.setItem('translate', JSON.stringify(translation));
    this.translate = translation;
  }
}
