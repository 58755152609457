import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { AppInjector } from '../../../../../app-injector.service';
import { MessageService } from '../../../message/service/message.service';
import { MessageType } from '../../../message/models/message.model';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { ModalActionModel } from '../../../../../core/_models/modal/modal-action.model';

@Component({
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.scss'],
})
export class CsvComponent implements OnInit {
  @Input() id;
  @Input() service;
  @Input() connectionType;
  @Input() readonly = false;

  public fileName: string;
  public loading = false;

  public entityForm: FormGroup;
  private _messageService: MessageService;

  constructor(private _fb: FormBuilder, private _translate: TranslatePipe) {
    const injector = AppInjector.getInjector();
    this._messageService = injector?.get(MessageService);
  }

  ngOnInit(): void {
    this.entityForm = this._fb.group({
      file: [null],
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.entityForm.get('file').setValue(file);
    }
  }

  public checkAndSetAdviceMessage(event) {
    const createdRecord = event.created.errors;
    const updatedRecord = event.updated.errors;
    const message = [];
    let errors = createdRecord.length;

    if (
      createdRecord.length > 0 &&
      (event.updated.updated > 0 || event.updated.errors.length > 0)
    ) {
      errors = errors - (event.updated.errors.length + event.updated.updated);
    }
    if (errors > 0) {
      message.push('created: ' + errors);
    }
    if (updatedRecord.length > 0) {
      message.push('updated: ' + updatedRecord.length);
    }
    this._messageService.hasAdviceMessage = message.length > 0 ? true : false;
    this._messageService.adviceMessageHeader = 'CSV_import_advices';
    this._messageService.adviceMessage = message;
  }

  modalEvent(event) {
    if (event === ModalActionModel.SAVE && this.entityForm.valid) {
      this.loading = true;
      this.service
        .importNowFile(
          this.id,
          this.connectionType,
          this.entityForm.get('file').value
        )
        .subscribe({
          next: (save) => {
            this.loading = false;
            this.checkAndSetAdviceMessage(save.body.records);
            this.setSuccessMessage(save);
          },
          error: (error) => {
            this.loading = false;
            this.setErrorMessage(error);
          },
        })
        .add(() => {
          this._messageService.adviceMessage = null;
          this._messageService.hasAdviceMessage = false;
          this._messageService.adviceMessageHeader = null;
        });
    }
  }

  public setErrorMessage(error) {
    let message: string;
    message = error.message;
    if (error.code === 400) {
      message = this._translate.transform('csv_invalid_header', 'errors');
    }
    this._messageService.setMessageConfig(message, MessageType.error);
  }

  public setSuccessMessage(save) {
    let message: string;

    if (save.status === 200) {
      message = this._translate.transform('csv_success', 'success');
    }

    const records = save.body.records;

    message =
      message +
      this._translate.transform('csv_created_count', 'success') +
      records.created['created'] +
      ' | ' +
      this._translate.transform('csv_updated_count', 'success') +
      records.updated['updated'] +
      ' | ' +
      this._translate.transform('csv_removed_count', 'success') +
      records.removed['removed'];

    this._messageService.setMessageConfig(message);
  }
}
