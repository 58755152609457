<app-ui-labeled
  [hasError]="formService.hasError(control, id)"
  [id]="randomId"
  [label]="label"
  [colClass]="colClass"
  [isRequired]="isRequired"
  [tooltip]="tooltip"
  [tooltipMessage]="tooltipMessage"
>
  <app-ui-input
    #input
    [(ngModel)]="value"
    [id]="id"
    [randomId]="randomId"
    [min]="min"
    [max]="max"
    [type]="type"
    [readonly]="readonly"
    [title]="title"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [inputClass]="inputClass"
    [control]="control"
    [cy]="cy"
    (change)="onChange($event)"
    (blur)="onBlur($event)"
    [mask]="mask"
    [isModal]="isModal"
    [prefixText]="prefixText"
    [lowercase]="lowercase"
  >
  </app-ui-input>
</app-ui-labeled>
