import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-range-modal',
  templateUrl: './time-range-modal.component.html',
  styleUrls: ['./time-range-modal.component.scss'],
})
export class TimeRangeModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TimeRangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required]),
  });

  ngOnInit(): void {}

  public confirmDate() {
    if (this.formGroup.valid) {
      this.dialogRef.close('ok');
    }
  }
}
