import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-btn',
  templateUrl: 'support-btn.component.html',
  styleUrls: ['./support-btn.component.scss'],
})
export class SupportBtnComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
