<ng-container [formGroup]="control" *ngIf="control; else model">
  <div class="switchToggle">
    <input
      #checkboxElement
      [formControlName]="id"
      type="checkbox"
      [id]="id"
      (change)="onChange($event)"
    />
    <label [for]="id">{{ id }}</label>
  </div>
</ng-container>

<ng-template #model>
  <div class="switchToggle">
    <input
      #checkboxElement
      type="checkbox"
      [id]="id"
      [checked]="checked"
      (change)="onChange($event)"
    />
    <label [for]="id">{{ id }}</label>
  </div>
</ng-template>
