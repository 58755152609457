import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LicenseComponent } from './components/license/license.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PipesModule } from 'src/app/core/_pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MatCheckboxModule,
    NgbTooltipModule
  ],
  declarations: [
    LicenseComponent,
    LoadingComponent
  ],
  exports: [
    LicenseComponent
  ]
})
export class LicenseModule { }
