import { NgbDropdownConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Email } from '../../models/email.model';
import { TypeReport } from '../../models/type-report';
import { ActionArchives } from '../../models/action-archives';
import { QuarantineService } from '../../services/quarantine/quarantine.service';
import { TypeActionArchive } from '../../models/type-action-archive';
import { EmailRedirectComponent } from '../email-redirect/email-redirect.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArchiveFlagActionComponent } from './archiveFlags/archive-flag-action.component';
import { TypeList } from '../../models/type-list';
import { EmailActionLocation, EmailActionService } from '../../services/email-action/email-action.service';
import { EmailAction, EmailActionOption } from '../../models/email-action.model';

export interface EmailFlag {
  email: number;
  flags: number[];
  elasticId: string;
}

export interface SaveEvent {
  successMessage?: string;
  errorMessage?: any | null;
  removed?: boolean;
  updateFlag?: boolean;
  flags?: EmailFlag[];
}

@Component({
  selector: 'app-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss'],
  providers: [NgbDropdownConfig],
})
export class EmailActionComponent implements OnInit {
  @Input() public placement?: string;
  @Input() public emailsSelected: Email[];
  @Input() public disableDropDown = false;
  @Input() public setBtnClass = 'btn-outline-primary';
  @Output() public valueChange = new EventEmitter();
  @ViewChild('modalArchiveFlag') public modalArchiveFlag;

  public modal: NgbModalRef;
  public emailsFlags: EmailFlag[] = [];
  public actionsForm: FormGroup = this._formBuilder.group({
    action: [-1],
  });
  public options: EmailActionOption[] = this._emailActions.getOption(EmailActionLocation.READ_MESSAGE);

  constructor(
    private _service: QuarantineService,
    private _formBuilder: FormBuilder,
    private _ngModal: NgbModal,
    private _emailActions: EmailActionService,
  ) {
  }

  ngOnInit() {
  }

  public manageFlags(): void {
    this.modal = this._ngModal.open(ArchiveFlagActionComponent, {
      size: 'sm',
      windowClass: 'modal-xsm custom',
      backdrop: 'static',
      keyboard: false,
    });
    this.modal.componentInstance.selectedEmails = this.emailsSelected;
    this.modal.componentInstance.valueChange.subscribe((value) => {
      this.defineFlags(value);
    });
    this.modal.componentInstance.saveEvent.subscribe(() => {
      this.saveFlags(true);
    });

    this.modal.result.finally(() => {
      this.actionsForm.setValue({
        action: [-1],
      });
    });
  }

  public executeMessageAction(
    typeAction: TypeActionArchive,
    av?: boolean,
  ): void {
    const ids = this.defineIds().ids;
    const esIds = this.defineIds().esIds;
    const action = new ActionArchives();
    action.messagesIds = ids;
    action.elasticIds = esIds;
    action.av = av;

    if (typeAction === TypeActionArchive.REDIRECT) {
      const recipients = [];
      action.recipients = recipients;

      const dialog: NgbModalRef = this._ngModal.open(EmailRedirectComponent, {
        size: 'sm',
        windowClass: 'modal-xsm custom',
        backdrop: 'static',
        keyboard: false,
      });

      dialog.result.finally(() => {
        this.actionsForm.setValue({
          action: [-1],
        });
      });

      dialog.componentInstance.eventSave.subscribe((result) => {
        result.forEach((r) => {
          recipients.push(r);
        });

        dialog.close();
        this.executeAction(action, typeAction);
      });
    } else {
      this.executeAction(action, typeAction);
    }
  }

  public executeAction(action, typeAction): void {
    this._service.executeMessageAction(action, typeAction).subscribe({
      next: (result) => {
        this.onSaveChange({ successMessage: result.message });
      },
      error: (error) => {
        this.onSaveChange({
          errorMessage: error.message || error.arrayErrorMessages,
        });
      },
    });
  }

  public onSelectChange(): void {
    const form = this.actionsForm.getRawValue();

    switch (form.action) {
      case EmailAction.Release:
        return this.executeMessageAction(TypeActionArchive.RELEASE, false);
      case EmailAction.ReleaseAV:
        return this.executeMessageAction(TypeActionArchive.RELEASE, true);
      case EmailAction.Delete:
        return this.removeMessages();
      case EmailAction.Redirect:
        return this.executeMessageAction(TypeActionArchive.REDIRECT);
      case EmailAction.Phishing:
        return this.reportMessages(TypeReport.PHISHI);
      case EmailAction.Spam:
        return this.reportMessages(TypeReport.SPAM);
      case EmailAction.NotSpam:
        return this.reportMessages(TypeReport.HAM);
      case EmailAction.SenderBlocked:
        return this.senderToBlockedOrTrustedList(TypeList.BLOCKED);
      case EmailAction.SenderTrusted:
        return this.senderToBlockedOrTrustedList(TypeList.TRUSTED);
    }
  }

  public removeMessages(): void {
    const ids = this.defineIds().ids;

    this._service.removeMessages({ messagesIds: ids }).subscribe({
      next: (result) => {
        this.onSaveChange({ successMessage: result.message, removed: true });
      },
      error: (error) => {
        this.onSaveChange({ errorMessage: error.message });
      },
    });
  }

  public senderToBlockedOrTrustedList(safe: TypeList) {
    const esIds = this.defineIds().esIds;

    this._service
      .blockedOrTrustedList(esIds, safe, TypeReport.SENDER)
      .subscribe({
        next: (result) => {
          this.onSaveChange({ successMessage: result.message });
        },
        error: (error) => {
          this.onSaveChange({ errorMessage: error.arrayErrorMessages[0] });
        },
      });
  }

  public reportMessages(type: TypeReport): void {
    const ids = this.defineIds().ids;

    this._service.reportMessages({ messagesIds: ids }, type).subscribe({
      next: (result) => {
        this.onSaveChange({ successMessage: result.message });
      },
      error: (error) => {
        this.onSaveChange({ errorMessage: error.message });
      },
    });
  }

  public defineFlags(emailFlags: any): void {
    this.emailsFlags = emailFlags;
    this.emailsFlags.forEach((v) => {
      v.elasticId = this.defineIds().esIds.toString();
    });
  }

  public saveFlags(closeModal: boolean): void {
    const saveEvent: SaveEvent = {
      successMessage: '',
      updateFlag: true,
    };

    if (closeModal) {
      if (this.emailsFlags.length > 0) {
        const emails = { emails: this.emailsFlags };
        saveEvent.flags = this.emailsFlags;
        this._service.saveFlags(emails).subscribe((response) => {
          saveEvent.successMessage = response.message;
        });
      } else {
        saveEvent.successMessage = 'no flag selected';
      }
      this.modal.close();
      this.onSaveChange(saveEvent);
    }
  }

  public onSaveChange(event: SaveEvent): void {
    this.actionsForm.setValue({
      action: [-1],
    });
    this.valueChange.emit(event);
  }

  private defineIds(): { ids: string[]; esIds: string[] } {
    const ids = [];
    const esIds = [];
    this.emailsSelected.forEach((m) => {
      ids.push(m.id);
      esIds.push(m.esId);
    });
    return { ids, esIds };
  }
}
