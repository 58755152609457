export const eula_ptBR = `<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:16pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Contrato de licen&ccedil;a de usu&aacute;rio final - EULA</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Leia com aten&ccedil;&atilde;o os termos e condi&ccedil;&otilde;es antes de aceitar este contrato. Ao selecionar a op&ccedil;&atilde;o &ldquo;Aceito os termos do Contrato&rdquo;, voc&ecirc; manifestar&aacute; que aceitou estes termos e condi&ccedil;&otilde;es em sua totalidade. Caso voc&ecirc; n&atilde;o concorde com estes termos e condi&ccedil;&otilde;es, clique no campo &ldquo;Cancelar&rdquo;.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Este documento e quaisquer adendos ou anexos constituem o contrato de usu&aacute;rio final (&ldquo;Contrato&rdquo;) com valor Legal entre a Gatefy Inform&aacute;tica Ltda. (&ldquo;Gatefy&rdquo;), com sede na Rua Professora Maria Jos&eacute; Godoy, 361, Bom Retiro, Curitiba, Paran&aacute;, inscrita no CNPJ sob n&uacute;mero 30.348.743/0001-18 e a entidade que voc&ecirc; representa.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Este Contrato foi atualizado pela &uacute;ltima vez em 1 de julho de 2019.&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ao aquiescer a este Acordo, VOC&Ecirc; confirma, sob as penas da Lei, que tem autoridade para vincular essa entidade a estes&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TERMOS E CONDI&Ccedil;&Otilde;ES</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">O objetivo deste contrato &eacute; determinar as condi&ccedil;&otilde;es e as atividades de qualquer pessoa que utilize os produtos e servi&ccedil;os Gatefy como usu&aacute;rio final e se rege pelas seguintes cl&aacute;usulas:</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Defini&ccedil;&otilde;es</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Documenta&ccedil;&atilde;o&rdquo; significa as informa&ccedil;&otilde;es relacionadas aos produtos e servi&ccedil;os Gatefy, segredos comerciais, planos, projetos, oportunidades e opera&ccedil;&otilde;es.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Informa&ccedil;&otilde;es Confidenciais e Pessoais&rdquo; significa informa&ccedil;&otilde;es relacionadas a segredos comerciais ou informa&ccedil;&otilde;es pessoais regulamentadas por Lei, por pol&iacute;ticas de privacidade e seguran&ccedil;a de dados.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Open relay&rdquo; significa um servidor de e-mail configurado de modo que qualquer pessoa possa enviar e-mails por ele. Ou seja, o remetente &eacute; um usu&aacute;rio desconhecido e n&atilde;o autorizado a enviar mensagens.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Parceiro autorizado&rdquo; significa uma terceira parte que &eacute; autorizada pela Gatefy a revender produtos e servi&ccedil;os Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Spam&rdquo; e &ldquo;Bulk Email&rdquo; significam, nos termos deste Contrato, e-mails indesejados e que podem representar perigo para a seguran&ccedil;a de dados e informa&ccedil;&otilde;es do Usu&aacute;rio Final.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Termo Comercial&rdquo; significa a proposta, contendo informa&ccedil;&otilde;es tais quais o nome do revendedor, o nome do cliente e detalhes de produtos e servi&ccedil;os e prazo.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Usu&aacute;rio Final&rdquo; significa a empresa que adquire produtos e servi&ccedil;os Gatefy para seu uso pr&oacute;prio e de seus colaboradores diretos.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Licen&ccedil;a e direito</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy concede ao Usu&aacute;rio Final um direito limitado, n&atilde;o exclusivo e n&atilde;o transfer&iacute;vel de usar os produtos e servi&ccedil;os Gatefy de acordo com o n&uacute;mero de caixas postais e servi&ccedil;os contratados, e somente para o uso interno e pr&oacute;prio do Usu&aacute;rio Final. O Usu&aacute;rio Final &eacute; respons&aacute;vel por todas as a&ccedil;&otilde;es e atividades de seus usu&aacute;rios que tenham rela&ccedil;&atilde;o com os produtos e servi&ccedil;os Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final reconhece e concorda que o software e todos os materiais de apoio, documenta&ccedil;&atilde;o e programas incorporados nos produtos da Gatefy, incluindo patentes, segredos comerciais e outros direitos de propriedade, s&atilde;o de propriedade exclusiva da Gatefy e de seus fornecedores e/ou licenciadores.&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy reconhece e concorda que o Usu&aacute;rio Final det&eacute;m direitos e propriedade sobre os dados e informa&ccedil;&otilde;es que o Usu&aacute;rio Final forne&ccedil;a ao utilizar os produtos e servi&ccedil;os Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.4.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A menos que haja permiss&atilde;o prevista neste Contrato ou por escrito da Gatefy, o Usu&aacute;rio Final n&atilde;o est&aacute; autorizado a permitir que: (i) copiem, editem ou usem qualquer parte dos produtos e da documenta&ccedil;&atilde;o da Gatefy para outras atividades que n&atilde;o tenham rela&ccedil;&atilde;o com a Gatefy; (ii) fa&ccedil;am engenharia reversa ou tentem descobrir o c&oacute;digo-fonte dos produtos Gatefy; (iii) aluguem ou transfiram direitos dos produtos Gatefy; (iv) permitam que os produtos Gatefy sejam testados ou avaliados a fim de an&aacute;lise comparativa destinada &agrave; publica&ccedil;&atilde;o; ou (v) nomeiem terceiros para comercializar ou distribuir os produtos Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.5</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final pode permitir que terceiros utilizem os softwares e produtos licenciados somente se o uso (i) for em nome e benef&iacute;cio do pr&oacute;prio Usu&aacute;rio Final, (ii) for para opera&ccedil;&atilde;o interna do Usu&aacute;rio Final e (iii) estiver em conformidade com este EULA. Neste caso, o Usu&aacute;rio Final assume responsabilidade sobre qualquer a&ccedil;&atilde;o e atividade tomada por esse terceiro que tenha rela&ccedil;&atilde;o com os produtos e servi&ccedil;os Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.6.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final concorda que a Gatefy pode fornecer produtos e servi&ccedil;os de qualquer data center que lhe convier, podendo a Gatefy a qualquer momento e por qualquer motivo transferir os produtos e servi&ccedil;os para outra instala&ccedil;&atilde;o. A Gatefy n&atilde;o garante que tal datacenter seja de uso exclusivo do Usu&aacute;rio Final.&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.7</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy reserva-se o direito de a qualquer momento alterar (i) os produtos e servi&ccedil;os Gatefy; (ii) o valor cobrado por produtos e servi&ccedil;os; e (iii) a documenta&ccedil;&atilde;o relacionada aos produtos e servi&ccedil;os, desde que tais altera&ccedil;&otilde;es sejam notificadas por escrito via e-mail com pelo menos 30 dias de anteced&ecirc;ncia at&eacute; que sejam efetivadas.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Obriga&ccedil;&otilde;es do Usu&aacute;rio Final</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final se compromete e garante que, sempre que solicitado, vai fornecer, de maneira completa e precisa, as informa&ccedil;&otilde;es necess&aacute;rias sobre contatos, usu&aacute;rios, caixas postais, dados t&eacute;cnicos e outras informa&ccedil;&otilde;es similares para que a Gatefy possa fornecer os produtos e servi&ccedil;os.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">O Usu&aacute;rio Final se compromete e garante que n&atilde;o usar&aacute; os produtos e servi&ccedil;os Gatefy (i) para atuar como um open relay; (ii) para enviar e receber e-mails em massa, ou bulk email; (iii) para enviar spam; e (iv) para propagar diferentes tipos de amea&ccedil;as, tais quais v&iacute;rus, trojan e ransomware.&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final se compromete e garante, sabendo que as informa&ccedil;&otilde;es enviadas de e para o Usu&aacute;rio Final v&atilde;o trafegar pelos produtos e servi&ccedil;os Gatefy, que (i) cumpre com todas as leis e regulamenta&ccedil;&otilde;es aplic&aacute;veis dentro do territ&oacute;rio que atua; (ii) que n&atilde;o pratica atividades enganosas ou anti&eacute;ticas; (iii) e que vai manter, caso necess&aacute;rio, registros cont&aacute;beis, jur&iacute;dicos e de negocia&ccedil;&atilde;o que tenham rela&ccedil;&atilde;o com este Contrato pelo per&iacute;odo de vig&ecirc;ncia deste Contrato ou por mais tempo conforme exigido por lei ou regulamento.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Termo comercial</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Se o Usu&aacute;rio Final adquiriu produtos e servi&ccedil;os por meio de um parceiro autorizado, os termos comerciais e de manuten&ccedil;&atilde;o e suporte para produtos e servi&ccedil;os Gatefy ser&atilde;o acordados entre o parceiro autorizado e o Usu&aacute;rio Final.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Se o Usu&aacute;rio Final adquiriu produtos e servi&ccedil;os diretamente da Gatefy, os termos e condi&ccedil;&otilde;es a se seguir se aplicam:</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy &eacute; respons&aacute;vel por faturar e cobrar o Usu&aacute;rio Final pelos produtos e servi&ccedil;os de acordo com as informa&ccedil;&otilde;es de cobran&ccedil;a fornecidas pelo Usu&aacute;rio Final.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Os termos de pagamento padr&atilde;o da Gatefy exigem que o pagamento por produtos e servi&ccedil;os sejam efetuados em at&eacute; 30 dias a partir do aceite do cliente, a menos que haja outro tipo de negocia&ccedil;&atilde;o por escrito entre a Gatefy e o Usu&aacute;rio Final. A Gatefy reserva-se o direito de alterar os seus termos de pagamento a qualquer momento, inclusive exigindo pagamento antecipado quando achar necess&aacute;rio.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy n&atilde;o oferece reembolsos, por quaisquer motivos, caso o contrato com o Usu&aacute;rio Final tenha sido encerrado antes do t&eacute;rmino previsto.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.4</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Caso o Usu&aacute;rio Final n&atilde;o efetue o pagamento integral na data prevista, a Gatefy pode interromper o fornecimento e tamb&eacute;m a atualiza&ccedil;&atilde;o de produtos e servi&ccedil;os. Se permitido em Lei, qualquer atraso implicar&aacute; na cobran&ccedil;a de juros ao m&ecirc;s de acordo com o m&aacute;ximo permitido por Lei a partir da data em que o pagamento &eacute; devido at&eacute; a data em que o pagamento &eacute; recebido.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.5</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A manuten&ccedil;&atilde;o e suporte para produtos e servi&ccedil;os Gatefy inclui suporte t&eacute;cnico b&aacute;sico, dispon&iacute;vel via portal de Customer Success. Pacotes de suporte t&eacute;cnico avan&ccedil;ado com atendimento telef&ocirc;nico e gestor exclusivo de suporte est&atilde;o dispon&iacute;veis mediante contrata&ccedil;&atilde;o e pagamento de taxa correspondente. A Gatefy reserva-se o direito de limitar o n&uacute;mero de usu&aacute;rios que podem contactar o seu suporte t&eacute;cnico.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Garantia e limita&ccedil;&atilde;o</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O software e produto Gatefy &eacute; fornecido &ldquo;NO ESTADO EM QUE SE ENCONTRA&rdquo;. A Gatefy n&atilde;o garante, em sua totalidade, que as fun&ccedil;&otilde;es contidas atender&atilde;o &agrave;s exig&ecirc;ncias do Usu&aacute;rio Final, nem que a opera&ccedil;&atilde;o dele ser&aacute; ininterrupta ou sem nenhum erro. Por&eacute;m, a Gatefy garante um total de disponibilidade do software de 99,5% (noventa e nove inteiros e cinco d&eacute;cimos por cento) do tempo, por m&ecirc;s. Se, por qualquer motivo, o software ficar totalmente indispon&iacute;vel al&eacute;m desse &iacute;ndice, a Gatefy ir&aacute; conceder um desconto proporcional ao tempo sem execu&ccedil;&atilde;o ao Usu&aacute;rio Final. Al&eacute;m disso, a Gatefy adotar&aacute; medidas de prote&ccedil;&atilde;o dos dados do Usu&aacute;rio Final quanto a uma poss&iacute;vel invas&atilde;o externa do sistema. Mas a Gatefy n&atilde;o pode garantir, em sua totalidade, que tal evento nunca ocorrer&aacute;. E, se ocorrer esse infort&uacute;nio, a Gatefy garante que j&aacute; possui planos de conting&ecirc;ncia para sanear, o mais r&aacute;pido poss&iacute;vel, os danos causados ao Usu&aacute;rio Final, uma vez que a seguran&ccedil;a da informa&ccedil;&atilde;o &eacute; cr&iacute;tica para o Usu&aacute;rio Final.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Os produtos e servi&ccedil;os Gatefy poder&atilde;o estar sujeitos a limita&ccedil;&otilde;es, atrasos e outros problemas inerentes &agrave; utiliza&ccedil;&atilde;o da Internet e comunica&ccedil;&otilde;es eletr&ocirc;nicas. A Gatefy n&atilde;o se responsabiliza por eventuais atrasos, falhas de entrega, ou outros danos decorrentes de problemas desse tipo.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A Gatefy n&atilde;o se responsabiliza e n&atilde;o tem qualquer obriga&ccedil;&atilde;o em termos de responsabilidade e tamb&eacute;m de suporte t&eacute;cnico a solucionar problemas e quest&otilde;es que tenham rela&ccedil;&atilde;o com a customiza&ccedil;&atilde;o ou a configura&ccedil;&atilde;o errada de seus produtos e servi&ccedil;os. Na m&aacute;xima extens&atilde;o permitida por Lei, em hip&oacute;tese alguma, a Gatefy, seus licenciantes e fornecedores ser&atilde;o respons&aacute;veis por quaisquer danos reais, diretos, indiretos, incidentais, espec&iacute;ficos, consequenciais, ou punitivos, incluindo perda de lucros, perda de economias, custos de aquisi&ccedil;&atilde;o de produtos ou servi&ccedil;os substitutos ou outros danos resultantes do uso ou da incapacidade de uso do software, mesmo se avisada da possibilidade de tais danos.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.4</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final reconhece e concorda que a Gatefy pode utilizar softwares p&uacute;blicos e de terceiros em seus produtos. O uso destes softwares est&atilde;o sujeitos aos termos e condi&ccedil;&otilde;es dos contratos fornecidos por tais softwares. No caso de tal acordo n&atilde;o puder ser fornecido, a Gatefy (i) n&atilde;o oferece nenhuma garantia de qualquer tipo em rela&ccedil;&atilde;o ao funcionamento dos softwares; e (ii) n&atilde;o ser&aacute; respons&aacute;vel por quaisquer danos que possam surgir por causa deles.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Privacidade e confidencialidade&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy obriga-se a manter o mais completo e absoluto sigilo sobre quaisquer mensagens ou dados do Usu&aacute;rio Final que tenham trafegado ou que venham trafegar pelos sistemas da Gatefy. Todas as credenciais de login do software e produto s&atilde;o criptografadas e somente o Usu&aacute;rio Final tem acesso aos seus dados. N&atilde;o podendo a Gatefy, sob qualquer pretexto, acessar, divulgar, reproduzir ou utilizar tais dados, a qualquer tempo, sob as penas da Lei.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy poder&aacute; compartilhar as informa&ccedil;&otilde;es confidenciais e pessoais do Usu&aacute;rio Final caso seja obrigada por ordem judicial (como liminares, mandados de busca, etc.). Neste caso, a Gatefy envidar&aacute; os m&aacute;ximos esfor&ccedil;os permitidos pela Lei e pela decis&atilde;o judicial para avisar o Usu&aacute;rio Final de modo a permitir que busque a revoga&ccedil;&atilde;o da ordem judicial que exp&otilde;e seus dados.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Na hip&oacute;tese de venda da Gatefy, como um todo ou de parte dos neg&oacute;cios da Gatefy, a Gatefy somente ir&aacute; transferir suas informa&ccedil;&otilde;es ao novo propriet&aacute;rio dos neg&oacute;cios caso este concorde com as mesmas obriga&ccedil;&otilde;es contidas nesta cl&aacute;usula, com o que o Usu&aacute;rio Final desde j&aacute; concorda.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.4</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Respeitando as obriga&ccedil;&otilde;es e exig&ecirc;ncias do territ&oacute;rio em que atua, o Usu&aacute;rio Final declara que conhece e respeita a Lei, as pol&iacute;ticas e as regras de coleta, reten&ccedil;&atilde;o, processamento e seguran&ccedil;a de dados e informa&ccedil;&otilde;es confidenciais e pessoais, e privacidade.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Rescis&atilde;o e expira&ccedil;&atilde;o</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Seja por qualquer descumprimento deste Contrato ou de um acordo feito por escrito, qualquer uma das partes pode rescindir este Contrato a qualquer momento, desde que haja notifica&ccedil;&atilde;o por escrito via e-mail.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy pode rescindir este Contrato caso haja uma mudan&ccedil;a de controle do Usu&aacute;rio Final.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ap&oacute;s a data de expira&ccedil;&atilde;o ou rescis&atilde;o deste Contrato, quaisquer produtos e servi&ccedil;os Gatefy que tenham sido comercializados antes do encerramento deste Contrato continuam v&aacute;lidos de acordo com os termos comerciais.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.4</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final concorda que a Gatefy n&atilde;o &eacute; respons&aacute;vel pelos danos que possam incorrer como resultado da rescis&atilde;o ou expira&ccedil;&atilde;o deste Contrato e se responsabiliza por pagar quaisquer saldos devidos.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Interrup&ccedil;&atilde;o de produtos e servi&ccedil;os</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;A Gatefy reserva-se o direito de informar o Usu&aacute;rio Final e de interromper parcialmente ou completamente todo produto e servi&ccedil;o fornecido ao Usu&aacute;rio Final at&eacute; que a quest&atilde;o seja solucionada em caso (i) de constatar que o Usu&aacute;rio Final permite open relay, (ii) de constatar que o Usu&aacute;rio Final envia spam ou e-mails em massa, e (iii) de constatar que o sistema do Usu&aacute;rio Final pode comprometer a seguran&ccedil;a de outros servi&ccedil;os e produtos Gatefy, inclusive colocando em risco outros Usu&aacute;rios Finais.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final concorda e tem conhecimento de que, a qualquer momento durante o fornecimento de produtos e servi&ccedil;os, a Gatefy pode testar o seu sistema para avaliar ou detectar algum tipo de problema citado na cl&aacute;usula 7.1.&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Se por qualquer motivo o fornecimento de produtos e servi&ccedil;os for suspenso, a Gatefy n&atilde;o se responsabiliza por configura&ccedil;&otilde;es que possam ter sido exclu&iacute;das de produtos e servi&ccedil;os, cabendo ao Usu&aacute;rio Final a responsabilidade de configur&aacute;-los novamente.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Termos finais</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final aceita e permite que a Gatefy armazene e use dados e informa&ccedil;&otilde;es de e-mails suspeitos, indesejados e maliciosos, a exemplo de URLs e de arquivos anexados, para cont&iacute;nua melhoria e aperfei&ccedil;oamento da seguran&ccedil;a de produtos e servi&ccedil;os Gatefy e, consequentemente, da seguran&ccedil;a do pr&oacute;prio Usu&aacute;rio Final. Tal uso e armazenamento pode estender-se por at&eacute; 100 dias a partir da detec&ccedil;&atilde;o do problema, dependendo do tipo de amea&ccedil;a.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final n&atilde;o pode ceder ou transferir este Contrato sem o consentimento por escrito da Gatefy.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.3</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Este Contrato ser&aacute; regido e interpretado pelo Foro Central da Comarca de Curitiba, capital do Paran&aacute;, no Brasil, para dirimir as eventuais disputas decorrentes do presente Contrato, renunciando expressamente a qualquer outro, por mais privilegiado que possa ser. As partes concordam expressamente que a Conven&ccedil;&atilde;o das Na&ccedil;&otilde;es Unidas sobre Contratos para a Venda Internacional de Mercadorias n&atilde;o se aplica.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.4</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final concorda em fornecer para a Gatefy, a qualquer momento mediante solicita&ccedil;&atilde;o, evid&ecirc;ncias apropriadas por escrito e outros tipos de documento de que est&aacute; em conformidade com este Contrato e qualquer cl&aacute;usula deste Contrato. A falha em fornecer tais certifica&ccedil;&otilde;es pode ser considerada causa para a rescis&atilde;o imediata deste Contrato.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.5</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;O Usu&aacute;rio Final autoriza a Gatefy a exibir o nome, logotipo, ind&uacute;stria e descri&ccedil;&atilde;o geral do Usu&aacute;rio Final no website da Gatefy, bem como em qualquer outra m&iacute;dia ou meio de divulga&ccedil;&atilde;o e marketing; e aceita receber materiais promocionais de produtos e servi&ccedil;os Gatefy. Se o Usu&aacute;rio Final n&atilde;o concordar com as permiss&otilde;es desta cl&aacute;usula, deve notificar a Gatefy via e-mail partner@gatefy.com especificando qual permiss&atilde;o n&atilde;o &eacute; concedida.&nbsp;</span></p>
<p style="text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.6</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Se a Gatefy permitir que o Usu&aacute;rio Final conduza uma avalia&ccedil;&atilde;o gratuita de produto e servi&ccedil;o, o Usu&aacute;rio Final pode conduzi-la pelo prazo estipulado pela Gatefy por escrito. Neste caso, o Usu&aacute;rio Final fica sujeito aos termos e condi&ccedil;&otilde;es deste Contrato e, caso decida n&atilde;o adquirir os produtos, os direitos de uso ser&atilde;o rescindidos ap&oacute;s a expira&ccedil;&atilde;o do per&iacute;odo de avalia&ccedil;&atilde;o.</span></p>`;

export const eula_enUS = `<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:16pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User License Agreement - EULA</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Read with attention the terms and conditions before agreeing to this agreement. By selecting the &ldquo;Accept the Terms of Agreement&rdquo; option, you will indicate that you have accepted these terms and conditions in their entirety. If you don&rsquo;t agree with these terms and conditions, please select the &ldquo;Cancel&rdquo; option.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This document and any addenda or attachments constitute the end user agreement (&ldquo;Agreement&rdquo;) with legal value between Gatefy Inform&aacute;tica Ltda. (&ldquo;Gatefy&rdquo;), with thirst on Professora Maria Jos&eacute; Godoy Street, 361, Bom Retiro, Curitiba, Paran&aacute;, registered with the CNPJ 30.348.743/0001-18 and the entity you represent.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Agreement was last updated on July 1th, 2019.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By agreeing to this Agreement, YOU confirm, under the penalties of the Law, that You have the authority to bind such entity to these&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TERMS AND CONDITIONS</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The purpose of this agreement is to determine the conditions and activities of anyone using Gatefy&rsquo;s products and services as an end user and is governed by the following clauses:</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Definitions</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Documentation&rdquo; means information relating to Gatefy&apos;s products and services, trade secrets, plans, projects, opportunities and operations.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Confidential and Person Information&rdquo; means information relating to trade secrets or personal information regulated by law, by policy privacy and data security.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Open relay&rdquo; means an email server configured so that anyone can send emails to it. I.e, the sender is an unknown user who is not authorized to send messages.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Authorized Partner&rdquo; means the third party authorized by Gatefy to resell Gatefy&rsquo;s products and services.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Spam&rdquo; and &ldquo;Bulk Email&rdquo; means, in terms of this agreement, unwanted emails and can be dangerous to data security and information of the end user.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Commercial Term&rdquo; means the proposal, containing information such as the name of the reseller, name of the customer and details of products and services and terms.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;End User&rdquo; means the company that purchases Gatefy&rsquo;s products and services for its own use and that of its direct employees.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.License and Law</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Gatefy grants the end user a limited, non-exclusive and non-transferable to use the Gatefy&rsquo;s products and services in accordance with the number of mailboxes and services contracted, and only for the End User&rsquo;s own internal use. The End User is responsible for all actions and activities of its users that are related to Gatefy&apos;s products and services.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.2</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;End User acknowledges and agrees that the software and all supporting materials, documentation and programs incorporated in Gatefy&apos;s products, including patents, trade secrets and other proprietary rights, are the exclusive property of Gatefy and its suppliers and/or licensors .</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy acknowledges and agrees that the End User owns the rights and ownership of the data and information that the End User provides when using Gatefy&apos;s products and services.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Unless otherwise provided in this Agreement or in writing from Gatefy, End User is not authorized to allow: (i) to copy, edit or use any part of Gatefy&apos;s products and documentation for other activities unrelated to Gatefy; (ii) reverse engineer or attempt to discover the source code of Gatefy products; (iii) rent or transfer rights to Gatefy products; (iv) allow Gatefy products to be tested or evaluated for comparative analysis for publication; or (v) appoint third parties to market or distribute Gatefy products.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.5&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User may allow third parties to use the licensed software and products only if the use (i) is for the End User&apos;s own name and benefit, (ii) is for End User&apos;s internal operation and (iii) is in compliance with this EULA . In this case, the End User assumes responsibility for any action and activity taken by such third party in connection with Gatefy&apos;s products and services.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.6&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The End User agrees that Gatefy may provide products and services from any data center that suits it, and Gatefy may at any time and for any reason transfer the products and services to another facility. Gatefy does not guarantee that such datacenter is for the exclusive use of the End User.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.7&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy reserves the right at any time to change (i) Gatefy&apos;s products and services; (ii) the amount charged for products and services; and (iii) the documentation related to the products and services, provided that such changes are notified in writing via email at least 30 days in advance until they are effective.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. End User Obligations</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The End User undertakes and warrants that, whenever requested, it will provide, in a complete and accurate manner, the necessary information about contacts, users, mailboxes, technical data and other similar information so that Gatefy can provide the products and services.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User undertakes and warrants that it will not use Gatefy&apos;s products and services (i) to act as an open relay; (ii) to send and receive bulk emails, or bulk emails; (iii) to send spam; and (iv) to spread different types of threats, such as viruses, trojans and ransomware.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The End User undertakes and guarantees, knowing that the information sent to and from the End User will travel through Gatefy&apos;s products and services, that (i) it complies with all applicable laws and regulations within the territory in which it operates; (ii) that you do not engage in deceptive or unethical activities; (iii) and that it will maintain, if necessary, accounting, legal and trading records relating to this Agreement for the term of this Agreement or for such longer period as required by law or regulation.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Commercial Term</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If End User has purchased products and services through an authorized partner, the commercial and maintenance and support terms for Gatefy products and services will be agreed between the authorized partner and End User.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If the End User has purchased products and services directly from Gatefy, the following terms and conditions apply:</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy is responsible for billing and billing the End User for products and services in accordance with the billing information provided by the End User.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy&apos;s standard payment terms require payment for products and services to be made within 30 days of customer acceptance, unless otherwise agreed in writing between Gatefy and the End User. Gatefy reserves the right to change its payment terms at any time, including requiring advance payment when deemed necessary.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy does not offer refunds, for any reason, if the contract with the End User has been terminated before the scheduled termination.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If the End User does not pay in full on the due date, Gatefy may discontinue the supply and also the updating of products and services. If permitted by Law, any delay will charge interest per month to the maximum permitted by Law from the date payment is due until the date payment is received.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.5&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Maintenance and support for Gatefy products and services includes basic technical support, available via the Customer Success portal. Advanced technical support packages with telephone service and exclusive support manager are available upon contracting and payment of a corresponding fee. Gatefy reserves the right to limit the number of users who can contact its technical support.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5. Warranty and Limitation</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Gatefy software and product is provided &ldquo;AS IS&rdquo;. Gatefy does not guarantee, in its entirety, that the functions contained therein will meet the requirements of the End User, nor that its operation will be uninterrupted or error free. However, Gatefy guarantees total software availability of 99.5% (ninety-nine integers and five-tenths percent) of the time, per month. If, for any reason, the Software becomes totally unavailable beyond this rate, Gatefy will grant a prorated discount to the End User. In addition, Gatefy will adopt measures to protect the End User&apos;s data against a possible external invasion of the system. But Gatefy cannot fully guarantee that such an event will never occur. And, if this misfortune occurs, Gatefy guarantees that it already has contingency plans to remedy, as quickly as possible, the damage caused to the End User, since information security is critical for the End User.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy&apos;s products and services may be subject to limitations, delays and other problems inherent in using the Internet and electronic communications. Gatefy is not responsible for any delays, delivery failures, or other damages arising from such problems.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy is not responsible and has no obligation in terms of responsibility and also technical support to solve problems and issues related to the customization or the wrong configuration of its products and services. To the fullest extent permitted by Law, under no circumstances shall Gatefy, its licensors and suppliers be liable for any actual, direct, indirect, incidental, specific, consequential, or punitive damages, including lost profits, lost savings, acquisition costs of substitute products or services or other damages resulting from the use or inability to use the software, even if advised of the possibility of such damages.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User acknowledges and agrees that Gatefy may use public and third party software in its products. Use of this software is subject to the terms and conditions of the agreements provided by such software. In the event that such an agreement cannot be provided, Gatefy (i) makes no warranties of any kind with respect to the functioning of the Software; and (ii) will not be liable for any damages that may arise from them.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6. Privacy and confidentiality</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy undertakes to maintain the most complete and absolute secrecy of any messages or data from the End User that have passed through or come to pass through Gatefy&apos;s systems. All software and product login credentials are encrypted and only the End User has access to their data. Gatefy cannot, under any pretext, access, disclose, reproduce or use such data, at any time, under the penalties of the Law.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy may share the End User&apos;s confidential and personal information if required by a court order (such as injunctions, search warrants, etc.). In this case, Gatefy will use the maximum efforts permitted by Law and the court decision to notify the End User in order to allow him to seek the revocation of the court order that exposes his data.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In the event of sale of Gatefy, as a whole or part of Gatefy&apos;s business, Gatefy will only transfer its information to the new business owner if he agrees with the same obligations contained in this clause, with which the End User already agrees.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Respecting the obligations and requirements of the territory in which it operates, the End User declares that it knows and respects the Law, the policies and rules for the collection, retention, processing and security of confidential and personal data and information, and privacy.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Termination and Expiration</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Whether for any breach of this Agreement or a written agreement, either party may terminate this Agreement at any time, provided there is written notice via email.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy may terminate this Agreement in the event of a change in End User control.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">After the expiration or termination date of this Agreement, any Gatefy products and services that have been marketed prior to the termination of this Agreement remain valid in accordance with the commercial terms.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User agrees that Gatefy is not responsible for damages that may incur as a result of the termination or expiration of this Agreement and is responsible for paying any balances due.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Discontinuation of products and services</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Gatefy reserves the right to inform the End User and to partially or completely discontinue any product and service provided to the End User until the matter is resolved in case (i) it is found that the End User allows open relay, (ii) to verify that the End User sends spam or bulk emails, and (iii) to verify that the End User&apos;s system may compromise the security of other Gatefy services and products, including putting other End Users at risk.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User agrees and acknowledges that, at any time during the provision of products and services, Gatefy may test its system to assess or detect any type of issue referred to in clause 7.1.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If for any reason the supply of products and services is suspended, Gatefy is not responsible for settings that may have been excluded from products and services, and the End User is responsible for configuring them again.</span></p>
<p style="text-align: justify;"><br></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.Final Terms</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.1&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The End User accepts and allows Gatefy to store and use data and information from suspicious, unwanted and malicious emails, such as URLs and attached files, for the continuous improvement and improvement of the security of Gatefy&apos;s products and services and, consequently, of the End User&apos;s own security. Such usage and storage can extend for up to 100 days from detection of the issue, depending on the type of threat.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.2&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User may not assign or transfer this Agreement without Gatefy&apos;s written consent.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.3&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Agreement will be governed and interpreted by the Central Court of the District of Curitiba, capital of Paran&aacute;, Brazil, to settle any disputes arising from this Agreement, expressly waiving any other, however privileged it may be. The parties expressly agree that the United Nations Convention on Contracts for the International Sale of Goods does not apply.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.4&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User agrees to provide to Gatefy, at any time upon request, appropriate evidence in writing and other types of document that it is in compliance with this Agreement and any provision of this Agreement. Failure to provide such certifications may be grounds for immediate termination of this Agreement.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.5&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">End User authorizes Gatefy to display End User&apos;s name, logo, industry and general description on Gatefy&apos;s website, as well as in any other media or means of advertising and marketing; and agree to receive promotional materials for Gatefy products and services. If the End User does not agree to the permissions in this clause, it must notify Gatefy via email partner@gatefy.com specifying which permission is not granted.</span></p>
<p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: justify;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.6&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If Gatefy allows the End User to conduct a free evaluation of the product and service, the End User may conduct it for the period stipulated by Gatefy in writing. In this case, the End User is subject to the terms and conditions of this Agreement and, if it decides not to purchase the products, the use rights will terminate after the expiration of the trial period.</span></p>`;

export const eulaHTML = (eula, innerHTML) => {
  return `
    <div 
      id="eula-wrapper" 
      class="text-left border p-4" 
      style="
        height: 500px; 
        overflow: auto; 
        user-select: none;
      "
    >
      ${eula}
    </div>
    <div 
      id="eula-check" 
      class="form-row float-left"
    >
      ${innerHTML}
    </div>
  `;
};
