import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UserInfoService } from '../../../user-info/services/user-info.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements AfterViewInit {
  public licensesInUse: number;
  public licensesAuthorized: number;
  public message: string;
  public width: number;
  public loading = true;
  public showAlert = false;
  @Output() eulaSet: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _userInfoService: UserInfoService) {}

  ngAfterViewInit() {
    setInterval(() => {
      this._userInfoService.updateUserInfo();
    }, 300000);
    setTimeout(() => {
      this.getLicense();
    });
  }

  public getLicense(): void {
    const userInfo = this._userInfoService.userInfo;

    if (!userInfo) {
      setTimeout(() => {
        this.getLicense();
      }, 500);
      return;
    }

    this.eulaSet.emit(userInfo.eula);

    this.licensesInUse = userInfo.licensesInUse;
    this.licensesAuthorized = userInfo.licensesAuthorized;
    this.message = userInfo.message;
    this.setWidth();
    this.loading = false;
  }

  public setWidth(): void {
    this.width = Math.round(
      (this.licensesInUse * 100) / this.licensesAuthorized
    );
  }

  public onMouseEnter(): void {
    this.showAlert = true;
  }

  public onMouseLeave(): void {
    this.showAlert = false;
  }

  public get hasLicense(): boolean {
    return this.licensesInUse <= this.licensesAuthorized;
  }

  public get notHasLicense(): boolean {
    return this.licensesInUse > this.licensesAuthorized;
  }

  public get svgIcon(): string {
    if (this.hasLicense) {
      return 'icon-check';
    }

    if (this.notHasLicense) {
      return 'icon-error';
    }
  }
}
