<div
  id="div1"
  *ngIf="!wrap"
  [ngClass]="[expanded ? 'tags-expanded' : 'tags-width']"
>
  <div
    *ngFor="let tag of tagList; index as i"
    class="tag"
    title="{{ tagValue(tag) }}"
    [ngClass]="{ 'tag-editing': editing.array[i] }"
    (dblclick)="editTag(i)"
  >
    <p class="tag-text">{{ tagValue(tag) }}</p>
    <span class="delete-icon" (click)="deleteTag(i)">
      <i class="fas fa-times"></i>
    </span>
  </div>
  <div *ngIf="expanded" class="tag expand-tags" (click)="reduce()">
    <i class="fas fa-chevron-circle-left center"></i>
  </div>
</div>

<div id="div2" class="tags-width" *ngIf="wrap">
  <div
    *ngFor="let tag of shortTagList; index as i"
    class="tag"
    (dblclick)="editTag(i)"
    [ngClass]="{ 'tag-editing': editing.array[i] }"
  >
    <p title="{{ tagValue(tag) }}" class="tag-text">
      {{ tagValue(tag) }}
    </p>
    <span class="delete-icon" (click)="deleteTag(i)">
      <i class="fas fa-times"></i>
    </span>
  </div>
  <div class="tag reduce-tags" (click)="expand()">
    <p class="tag-text">+ {{ tagList.length - shortTagList.length }}</p>
  </div>
</div>
