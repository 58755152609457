import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseFormService } from '../../../../../core/_ui/components/form/base-form.service';

@Injectable({
  providedIn: 'root'
})
export class EmailPoliciesService extends BaseFormService {

  constructor(protected _http: HttpClient) {
    super(_http);
    super.setUrlPath('/v1/email');
  }
}
