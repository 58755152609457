import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileMenuService {

  url: string;
  dataAvatar: Observable<any>;

  constructor(private http: HttpClient) {
    this.url = '/v1/me/avatar';
  }

  getDataAvatar() {
    if (this.dataAvatar) {
      return this.dataAvatar;
    } else {
      return this.findDataAvatar()
        .pipe(
          tap(result => {
            this.dataAvatar = of(result);
            return this.dataAvatar;
          }),
          share()
        );
    }
  }

  clearDataAvatar() {
    this.dataAvatar = null;
  }

  private findDataAvatar(): Observable<any> {
    return this.http
      .get<any>(this.url)
      .pipe(map((res: any) => res.fields));
  }

}
