/**
 * Remove os delimitadores de uma expressão regular
 *
 * @param regexp A expressão regular a ser modificada
 * @param start Se deve remover o delimitador de início
 * @param end Se deve remover o delimitador de fim
 */
export function removeRegExpDelimiters(regexp: RegExp, start: boolean = true, end: boolean = true): string {
  let value = regexp.source;
  if (start) {
    value = value.replace(/^([^\^]*)\^/, '$1');
  }
  if (end) {
    value = value.replace(/\$([^\$]*)$/, '$1');
  }
  return value;
}
