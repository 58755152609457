import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { FieldsModel } from '../../models/form/field-form.model';
import { FieldListModel } from '../../models/list/field-list.model';
import { Injectable } from '@angular/core';
import { BaseFormService } from './base-form.service';

@Injectable({
  providedIn: 'root',
})
export class BaseFormWithRootService extends BaseFormService {

  protected customCustomerId: number | null = null;

  constructor(protected _http: HttpClient) {
    super(_http);
    this.customCustomerId = null;
  }

  setCustomCustomerId(id: number | null) {
    this.customCustomerId = id;
  }

  getCustomCustomerIdParamUri(): string {
    return `customerId=${this.customCustomerId}`;
  }

  getCustomCustomerIdParamBody(fields: Object): Object {
    fields['customerId'] = this.customCustomerId;
    return fields;
  }

  public getOne(id): Observable<FieldsModel> {
    return this._http.get<FieldsModel>(`${this.urlPath}/${id}?${this.getCustomCustomerIdParamUri()}`);
  }

  public getList(page = 1, pageSize = 10): Observable<FieldListModel> {
    return this._http.get<FieldListModel>(`${this.urlPath}?${this.getCustomCustomerIdParamUri()}` + '&page=' + page + '&per-page=' + pageSize);
  }

  public manipulateRecord(fields: FieldsModel, id = null, isCopy = false): Observable<FieldsModel> {
    const customerIdAndFields = this.getCustomCustomerIdParamBody(fields);
    if (id === null || isCopy) {
      return this._http.post<FieldsModel>(this.urlPath, customerIdAndFields);
    }

    return this._http.put<FieldsModel>(this.urlPath + '/' + id, customerIdAndFields);
  }

  public delete(id): Observable<any> {
    return this._http.delete(this.urlPath + '/' + id, { body: this.getCustomCustomerIdParamBody({}) });
  }
}
