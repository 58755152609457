import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { PipesModule } from '../_pipes/pipes.module';
import { MessageModule } from '../../gws/shared/message/message.module';
import { LicenseModule } from 'src/app/gws/shared/license/license.module';
import { TranslatePipe } from '../_pipes/pipes/translate.pipe';
import { CollapseDirective } from './directives/collapse/collapse.directive';
import { TemplateDirective } from '../_directive/template.diretive';
import { DraggableDirective } from './directives/draggable/draggable.directive';
import { DroppableDirective } from './directives/droppable/droppable.directive';
import { DroppableListDirective } from '../_directive/droppable-list.directive';
import { ReorderableRowDirective } from '../_directive/reorderable-row.directive';
import { ReorderableRowHandleDirective } from '../_directive/reorderable-row-handle.directive';

import { MenuComponent } from './components/menu/menu.component';
import { UiInputComponent } from './components/form/input/input.component';
import { LoadingComponent } from './components/loading/loading.component';
import { UiModalComponent } from './components/modal/modal.component';
import { UiChartComponent } from './components/charts/chart.component';
import { UiSelectComponent } from './components/form/select/select.component';
import { UiSwitchComponent } from './components/form/switch/switch.component';
import { UiLabeledComponent } from './components/form/labeled/labeled.component';
import { UiJsonListComponent } from './components/json-list/list.component';
import { UiCollapseComponent } from './components/collapse/collapse.component';
import { UiTextareaComponent } from './components/form/textarea/textarea.component';
import { UiCheckboxComponent } from './components/form/check/checkbox.component';
import { UiPieChartComponent } from './components/charts/components/pie-chart/pie-chart.component';
import { UiBarChartComponent } from './components/charts/components/bar-chart/bar-chart.component';
import { UiModalInfoComponent } from './components/modal-info/modal-info.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { UiModalFormComponent } from './components/modal-form/modal-form.component';
import { UiErrorFormComponent } from './components/form/error/error.component';
import { UiLineChartComponent } from './components/charts/components/line-chart/line-chart.component';
import { UiPolicyListComponent } from './components/policy-list/list.component';
import { SettingsFormComponent } from './components/settings-policy/settings-form.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle.switch.component';
import { UiPaginationComponent } from './components/pagination/pagination.component';
import { UiDragAndDropComponent } from './components/form/drag-and-drop/drag-and-drop.component';
import { OperatorArrayComponent } from './components/array/array.component';
import { UiModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { UiLabeledInputComponent } from './components/form/labeled-input/labeled-input.component';
import { DateTimePickerComponent } from './components/form/date-time-picker/date-time-picker.component';
import { UiLabeledSelectComponent } from './components/form/labeled-select/labeled-select.component';
import { UiInputPasswordComponent } from './components/form/input-password/input-password.component';
import { UiLabeledSwitchComponent } from './components/form/labeled-switch/labeled-switch.component';
import { UiLabeledTextareaComponent } from './components/form/labeled-textarea/labeled-textarea.component';
import { UiLabeledDragAndDropComponent } from './components/form/labeled-drag-and-drop/labeled-drag-and-drop.component';
import {
  UiLabeledInputPasswordComponent,
} from './components/form/labeled-input-password/labeled-input-password.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import {
  UiLabeledTextAndDropdownComponent,
} from './components/form/labeled-text-and-dropdown/labeled-text-and-dropdown.component';
import { SelectPageSizeComponent } from './components/select-amount/select-page-size.component';
import { MultiOperatorArrayComponent } from './components/multi-array/multi-array.component';
import { SupportBtnComponent } from './components/support/support-btn/support-btn.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {
  LabeledSearchableSelectComponent,
} from './components/form/labeled-searchable-select/labeled-searchable-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CheckDomainComponent } from 'src/app/core/_ui/components/check-domain/check-domain.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { MatIconModule } from '@angular/material/icon';
import { LowercaseDirective } from '../_directive/lowercase.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
  UiLabeledInputVariablesComponent,
} from './components/form/labeled-input-variables/labeled-input-variables.component';
import { BaseFormService } from './components/form/base-form.service';
import { MentionModule } from 'angular-mentions';
import {
  LabeledHtmlEditorWVariablesComponent,
} from './components/form/labeled-html-editor-w-variables/labeled-html-editor-w-variables.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { TimeRangeModalComponent } from './components/time-range-modal/time-range-modal.component';
import { MatInputModule } from '@angular/material/input';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { UserGuideComponent } from './components/menu/pages/user-guide/user-guide.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = () => {
  return {
    validation: false,
  };
};

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'l, LTS',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    NgbModule,
    FormsModule,
    PipesModule,
    CommonModule,
    RouterModule,
    MessageModule,
    LicenseModule,
    MatTableModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
    NgxMaskModule.forRoot(options),
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MentionModule,
    AngularEditorModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MatInputModule,
    MatButtonModule,
  ],
  declarations: [
    MenuComponent,
    UiInputComponent,
    LoadingComponent,
    UiModalComponent,
    UiChartComponent,
    UiSwitchComponent,
    CollapseDirective,
    UiSelectComponent,
    TemplateDirective,
    DraggableDirective,
    DroppableDirective,
    UiLabeledComponent,
    UiTextareaComponent,
    UiCheckboxComponent,
    UiJsonListComponent,
    UiCollapseComponent,
    UiPieChartComponent,
    UiBarChartComponent,
    ProfileMenuComponent,
    UiModalFormComponent,
    UiErrorFormComponent,
    UiModalInfoComponent,
    UiLineChartComponent,
    UiPolicyListComponent,
    SettingsFormComponent,
    ToggleSwitchComponent,
    UiPaginationComponent,
    DroppableListDirective,
    UiDragAndDropComponent,
    OperatorArrayComponent,
    UiModalConfirmComponent,
    UiLabeledInputComponent,
    DateTimePickerComponent,
    ReorderableRowDirective,
    UiLabeledSelectComponent,
    UiLabeledSwitchComponent,
    UiInputPasswordComponent,
    UiLabeledTextareaComponent,
    ReorderableRowHandleDirective,
    UiLabeledDragAndDropComponent,
    UiLabeledInputPasswordComponent,
    DateRangeFilterComponent,
    UiLabeledTextAndDropdownComponent,
    SelectPageSizeComponent,
    MultiOperatorArrayComponent,
    SupportBtnComponent,
    LabeledSearchableSelectComponent,
    CheckDomainComponent,
    AccordionComponent,
    LowercaseDirective,
    UiLabeledInputVariablesComponent,
    LabeledHtmlEditorWVariablesComponent,
    TimeRangeModalComponent,
    UserGuideComponent,
  ],
  exports: [
    NgbModule,
    FormsModule,
    PipesModule,
    MenuComponent,
    UiInputComponent,
    LoadingComponent,
    SupportBtnComponent,
    UiModalComponent,
    TemplateDirective,
    UiSwitchComponent,
    CollapseDirective,
    UiSelectComponent,
    UiLabeledComponent,
    DraggableDirective,
    DroppableDirective,
    UiTextareaComponent,
    UiCheckboxComponent,
    UiJsonListComponent,
    UiCollapseComponent,
    UiPieChartComponent,
    UiBarChartComponent,
    UiModalInfoComponent,
    UiModalFormComponent,
    UiErrorFormComponent,
    UiLineChartComponent,
    UiPolicyListComponent,
    SettingsFormComponent,
    ToggleSwitchComponent,
    UiPaginationComponent,
    DroppableListDirective,
    UiDragAndDropComponent,
    OperatorArrayComponent,
    MultiOperatorArrayComponent,
    DateTimePickerComponent,
    ReorderableRowDirective,
    UiModalConfirmComponent,
    UiLabeledInputComponent,
    UiLabeledSelectComponent,
    UiLabeledSwitchComponent,
    UiInputPasswordComponent,
    UiLabeledTextareaComponent,
    LabeledSearchableSelectComponent,
    ReorderableRowHandleDirective,
    UiLabeledDragAndDropComponent,
    UiLabeledInputPasswordComponent,
    DateRangeFilterComponent,
    UiLabeledTextAndDropdownComponent,
    SelectPageSizeComponent,
    CheckDomainComponent,
    AccordionComponent,
    UiLabeledInputVariablesComponent,
    LabeledHtmlEditorWVariablesComponent,
  ],
  providers: [
    DecimalPipe,
    TranslatePipe,
    BaseFormService,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class UiModule {
}
