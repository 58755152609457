<app-ui-labeled
  [hasError]="formService.hasError(control, id)"
  [id]="id"
  [label]="label"
  [colClass]="colClass"
>
  <ng-container [formGroup]="control">
    <mat-form-field
      appearance="fill"
      [ngClass]="{ error: formService.hasError(control, id) }"
    >
      <mat-select
        #singleSelect
        [formControl]="searchControl"
        [id]="id"
        [disabled]="readonly"
        [placeholder]="placeholder"
        [(ngModel)]="selectModel"
        [value]="selectValue"
        (valueChange)="selectValueChange.emit($event)"
        (selectionChange)="onChange($event)"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholder"
            [formControl]="searchFilterCtrl"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let bank of filteredCollection | async" [value]="bank">
          {{ bank.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-ui-error-form
      [hasError]="formService.hasError(control, id)"
      [control]="control.controls[id]"
    ></app-ui-error-form>
  </ng-container>
</app-ui-labeled>
