import { Component, Input, OnInit } from '@angular/core';

import { OriginalService } from '../../services/original/original.service';

@Component({
  templateUrl: './original.component.html',
  styleUrls: ['./original.component.scss'],
})
export class OriginalComponent implements OnInit {
  @Input() emailId;
  @Input() readonly = false;

  downloaded = false;
  loading = true;
  rawEmail;

  constructor(private _originalService: OriginalService) {}

  ngOnInit(): void {
    this._originalService.getOriginal(this.emailId).subscribe((sub) => {
      this.rawEmail = sub['fields']['raw_email'];
      this.loading = false;
    });
  }

  showEvent(event) {
    switch (event) {
      case 'save':
        this.download();
        this.downloaded = true;
    }
  }

  public download() {
    const a = document.createElement('a');
    const emailB64 = btoa(this.rawEmail);
    const blob = this.b64toBlob(emailB64);
    const blobUrl = URL.createObjectURL(blob);

    a.href = blobUrl;
    a.download = 'original-message.eml';
    a.click();
  }

  public b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
