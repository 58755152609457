import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CheckDomainService } from '../../../../gws/system/email-flow/inbound/services/check-domain.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmailFlowInboundService } from '../../../../gws/system/email-flow/inbound/services/inbound.service';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/gws/shared/message/service/message.service';
import { AppInjector } from 'src/app/app-injector.service';
import { InboundFormComponent } from 'src/app/gws/system/email-flow/inbound/pages/inbound-form/inbound-form.component';

@Component({
  selector: 'app-check-domain',
  templateUrl: './check-domain.component.html',
  styleUrls: ['./check-domain.component.scss'],
})
export class CheckDomainComponent implements OnInit {
  @ViewChild('spanElement') private _spanElement: ElementRef;
  @Input() public domain: string;
  @Input() public dnsHash: string;
  @Input() public showButton = true;
  @Input() public showHelp = true;
  @Input() public custom = false;
  @Input() public customEvent = false;
  @Output() public event = new EventEmitter<boolean>();
  @Output() public clicked = new EventEmitter<boolean>();

  public isDNSValid = false;
  public loading: boolean;
  public success;

  constructor(
    private _checkDomainService: CheckDomainService,
    private _inboundService: EmailFlowInboundService
  ) {}

  ngOnInit() {
    if (!this.dnsHash) {
      this.getDnsHash();
    }
  }

  public getDnsHash(): void {
    this._checkDomainService.getDnsHash().subscribe({
      next: (success) => {
        this.dnsHash = success['fields']['dnsHash'];
      },
      error: (error) => {
        this.dnsHash = error['message'];
      },
    });
  }

  public validateDomainOwnership(): void {
    this.loading = true;
    this.checkDomain();
  }

  public checkDomain() {
    this._checkDomainService.validateDomainOwnership(this.domain).subscribe({
      next: (res) => {
        this.isDNSValid = true;
        this.event.emit(true);
        this.loading = false;
        this.success = true;
      },
      error: (err) => {
        this.isDNSValid = false;
        this.event.emit(false);
        this.loading = false;
        this.success = false;
      },
    });
  }

  public copy(): void {
    const copyText = this._spanElement.nativeElement;
    const textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  public hasClicked() {
    this.clicked.emit(true);
  }
}
