import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

import { UiModule } from '../../../core/_ui/ui.module';
import { PipesModule } from '../../../core/_pipes/pipes.module';
import { MessageModule } from '../message/message.module';

import { ListComponent } from './pages/list/list.component';
import { ListFormComponent } from './pages/list-form/list-form.component';
import { ListRecordComponent } from './components/list-record/record.component';
import { ListColumnComponent } from './components/list-column/column.component';
import { ListHeaderComponent } from './components/list-header/header.component';
import { ListActionComponent } from './components/list-action/action.component';
import { ListCheckboxComponent } from './components/list-checkbox/list-checkbox.component';
import { BeautifyDataPipe } from './pipes/beautify-data.pipe';
import { ListInboundColumnComponent } from './components/list-inbound-column/list-inbound-column.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MaterialModule } from '../../../material.module';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  imports: [
    UiModule,
    FormsModule,
    PipesModule,
    CommonModule,
    MessageModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSlideToggleModule,
    MatRippleModule,
    MaterialModule,
    MatSortModule,
  ],
  declarations: [
    ListComponent,
    ListFormComponent,
    ListHeaderComponent,
    ListActionComponent,
    ListRecordComponent,
    ListColumnComponent,
    ListCheckboxComponent,
    BeautifyDataPipe,
    ListInboundColumnComponent,
  ],
  exports: [
    ListComponent,
    ListFormComponent,
    ListCheckboxComponent,
    BeautifyDataPipe,
  ],
})
export class ListModule {}
