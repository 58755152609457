import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';

import { UiModule } from '../../core/_ui/ui.module';
import { PipesModule } from '../../core/_pipes/pipes.module';
import { MessageModule } from '../shared/message/message.module';
import { EmailRoutingModule } from './email-routing.module';
// Components
import { EmailListComponent } from './components/email-list/email-list.component';
import { EmailFilterComponent } from './components/email-filter/email-filter.component';
import { EmailListRowComponent } from './components/email-list-row/email-list-row.component';
import { EmailQuickFinderComponent } from './components/email-quick-finder/email-quick-finder.component';
import { SearchHelpModalComponent } from './components/search-help-modal/search-help-modal.component';
import { SearchTagFilterComponent } from './components/search-tag-filter/search-tag-filter.component';
import { ModalTabsModule } from './components/email-tracker-modal/modal-tabs/modal-tabs.module';
import { EmailActionModule } from './components/email-action/email-action.module';
// Pages
import { OriginalComponent } from './components/email-original/original.component';
import { EmailReadComponent } from './components/email-read/email-read.component';
import { QuarantineComponent } from './pages/quarantine/quarantine.component';
import { EmailTrackerComponent } from './pages/tracker/tracker.component';
import { EmailRedirectComponent } from './components/email-redirect/email-redirect.component';
import { FilterDragDropComponent } from './components/filter-drag-drop/filter-drag-drop.component';
import { EmailTrackerModalComponent } from './components/email-tracker-modal/email-tracker-modal.component';
import { TagComponent } from './components/tag/tag.component';
import { SearchModule } from '../shared/search/search.module';
import { ListModule } from '../shared/list/list.module';
import { TimelineCardComponent } from './components/timeline-card/timeline-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    UiModule,
    NgbModule,
    FormsModule,
    PipesModule,
    CommonModule,
    MessageModule,
    EmailRoutingModule,
    ReactiveFormsModule,
    TagInputModule,
    ModalTabsModule,
    EmailActionModule,
    SearchModule,
    ListModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    OriginalComponent,
    EmailListComponent,
    EmailReadComponent,
    QuarantineComponent,
    EmailFilterComponent,
    EmailTrackerComponent,
    EmailListRowComponent,
    EmailRedirectComponent,
    EmailRedirectComponent,
    FilterDragDropComponent,
    EmailTrackerModalComponent,
    EmailQuickFinderComponent,
    SearchHelpModalComponent,
    SearchTagFilterComponent,
    TagComponent,
    TimelineCardComponent,
  ],
})
export class EmailTrackerModule {}
