<div #modalContent class="modal-body">
  <iframe
    #userGuideFrame
    class="col-12 user-guide-iframe"
    frameborder="0"
    src="about:blank"
  ></iframe>
  <button
    (click)="cancel()"
    [class.btn-light]="!(readonly || btnCancelMain)"
    [class.btn-primary]="readonly || btnCancelMain"
    [class.cancel-btn]="!(readonly || btnCancelMain)"
    class="btn user-guide-btn-close"
    type="button"
    [disabled]="buttonLoading"
  >
    {{ btnCancelText | translate }}
  </button>
</div>
