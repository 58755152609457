<div class="menu-icons unhider">
  <div class="avatar-icon avatar-wrapper">
    <i class="far fa-user"></i>
  </div>
  <div class="info-wrapper">
    <span class="avatar-name">{{ avatar?.name }}</span>
    <span class="avatar-company">{{ avatar?.customerName }}</span>
    <div class="chevron-wrapper">
      <i class="fas fa-chevron-down"></i>
    </div>
  </div>
  <div *ngIf="isOwner" class="hidden-pad">
    <div class="hidden-menu d-flex justify-content-center flex-column">
      <div class="separator"></div>
      <div class="hidden-item account-item">
        <a routerLink="/admin/account" class="hidden-btn">
          {{ 'editProfile' | translate: 'labels' }}
        </a>
      </div>
      <div class="hidden-item logout-item">
        <a (click)="logout()" routerLink="/login" class="hidden-btn">
          {{ 'logout' | translate: 'labels' }}
        </a>
      </div>
    </div>
  </div>
</div>
