<!--
  If there's not enough space in the first row, create a separate label row. We mark this row as
  aria-hidden because we don't want it to be read out as one of the weeks in the month.
-->
<tr *ngIf="_firstRowOffset < labelMinRequiredCells" aria-hidden="true">
  <td class="mat-calendar-body-label"
      [attr.colspan]="numCols"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
    {{label}}
  </td>
</tr>

<!-- Create the first row separately so we can include a special spacer cell. -->
<tr *ngFor="let row of rows; let rowIndex = index" role="row">
  <!--
    This cell is purely decorative, but we can't put `aria-hidden` or `role="presentation"` on it,
    because it throws off the week days for the rest of the row on NVDA. The aspect ratio of the
    table cells is maintained by setting the top and bottom padding as a percentage of the width
    (a variant of the trick described here: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp).
  -->
  <td *ngIf="rowIndex === 0 && _firstRowOffset"
      class="mat-calendar-body-label"
      [attr.colspan]="_firstRowOffset"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
    {{_firstRowOffset >= labelMinRequiredCells ? label : ''}}
  </td>
  <td *ngFor="let item of row; let colIndex = index"
      role="gridcell"
      class="mat-calendar-body-cell"
      [ngClass]="item.cssClasses"
      [tabindex]="_isActiveCell(rowIndex, colIndex) ? 0 : -1"
      [attr.data-mat-row]="rowIndex"
      [attr.data-mat-col]="colIndex"
      [class.mat-calendar-body-disabled]="!item.enabled"
      [class.mat-calendar-body-active]="_isActiveCell(rowIndex, colIndex)"
      [class.mat-calendar-body-range-start]="_isRangeStart(item.compareValue)"
      [class.mat-calendar-body-range-end]="_isRangeEnd(item.compareValue)"
      [class.mat-calendar-body-in-range]="_isInRange(item.compareValue)"
      [class.mat-calendar-body-comparison-bridge-start]="_isComparisonBridgeStart(item.compareValue, rowIndex, colIndex)"
      [class.mat-calendar-body-comparison-bridge-end]="_isComparisonBridgeEnd(item.compareValue, rowIndex, colIndex)"
      [class.mat-calendar-body-comparison-start]="_isComparisonStart(item.compareValue)"
      [class.mat-calendar-body-comparison-end]="_isComparisonEnd(item.compareValue)"
      [class.mat-calendar-body-in-comparison-range]="_isInComparisonRange(item.compareValue)"
      [class.mat-calendar-body-preview-start]="_isPreviewStart(item.compareValue)"
      [class.mat-calendar-body-preview-end]="_isPreviewEnd(item.compareValue)"
      [class.mat-calendar-body-in-preview]="_isInPreview(item.compareValue)"
      [attr.aria-label]="item.ariaLabel"
      [attr.aria-disabled]="!item.enabled || null"
      [attr.aria-selected]="_isSelected(item.compareValue)"
      [attr.aria-current]="todayValue === item.compareValue ? 'date' : null"
      (click)="_cellClicked(item, $event)"
      [style.width]="_cellWidth"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
      <div class="mat-calendar-body-cell-content mat-focus-indicator"
        [class.mat-calendar-body-selected]="_isSelected(item.compareValue)"
        [class.mat-calendar-body-comparison-identical]="_isComparisonIdentical(item.compareValue)"
        [class.mat-calendar-body-today]="todayValue === item.compareValue">
        {{item.displayValue}}
      </div>
      <div class="mat-calendar-body-cell-preview"></div>
  </td>
</tr>
