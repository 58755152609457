import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { FieldsModel } from '../../models/form/field-form.model';
import { FieldListModel } from '../../models/list/field-list.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class BaseFormService {
  public urlPath: string;
  public urlFields: string;

  constructor(protected _http: HttpClient) { }

  public setUrlPath(url): void {
    this.urlPath = url;
  }

  public setUrlFields(url): void {
    this.urlFields = url;
  }

  public getFields(): Observable<FieldsModel> {
    const url = this.urlFields === undefined ? this.urlPath : this.urlFields;
    return this._http.get<FieldsModel>(url + '/fields');
  }

  public getDefault(): Observable<FieldsModel> {
    return this._http.get<FieldsModel>(this.urlPath + '/default');
  }

  public getOne(id): Observable<FieldsModel> {
    return this._http.get<FieldsModel>(this.urlPath + '/' + id);
  }

  public getList(page = 1, pageSize = 10): Observable<FieldListModel> {
    return this._http.get<FieldListModel>(this.urlPath + '?page=' + page + '&per-page=' + pageSize);
  }

  public manipulateRecord(fields: FieldsModel, id = null, isCopy = false): Observable<FieldsModel> {
    if (id === null || isCopy) {
      return this._http.post<FieldsModel>(this.urlPath, fields);
    }

    return this._http.put<FieldsModel>(this.urlPath + '/' + id, fields);
  }

  public delete(id): Observable<any> {
    return this._http.delete(this.urlPath + '/' + id);
  }

  public getDefinitions(): Observable<any> {
    return this._http.get<any>(this.urlPath.replace('/v1/', '/v1/definitions/'));
  }

  public getAllDefinitions(): Observable<any> {
    return this._http.get<any>('/v1/definitions/rules');
  }

  public getFlags(): Observable<any> {
    return this._http.get<any>('/v1/rules-flags');
  }

  public getSettings(): Observable<any> {
    return this._http.get<any>(this.urlPath + '/settings');
  }

  public saveSettings(fields): Observable<any> {
    return this._http.put<any>(this.urlPath + '/settings', fields);
  }

  public search(objectToSearch): Observable<FieldListModel> {
    return this._http.get<FieldListModel>(this.urlPath, { params: objectToSearch });
  }
}
