import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IP_REGEXP, IP_SUBNET_MASK_REGEXP, IPv4_SUBNET_MASK_REGEXP } from './ipValidator';
import { removeRegExpDelimiters } from '../regExpHelper';

export const EMAIL_WITHOUT_DOMAIN_REGEXP: RegExp =
  /(^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*$)/;

export const DOMAIN_REGEXP: RegExp =
  /(^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$)/;

export const EMAIL_REGEXP: RegExp = new RegExp(
  '(^' + removeRegExpDelimiters(EMAIL_WITHOUT_DOMAIN_REGEXP) + '@' + removeRegExpDelimiters(DOMAIN_REGEXP) + '$)',
);

export function arrayValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value.length < 1) {
      return null;
    }
    const values = control.value.split(';').map((value) => value.trim());
    const duplicate = new Set(values).size !== values.length;
    let hasDuplicate = false;

    if (duplicate) {
      hasDuplicate = true;
    }

    if (hasDuplicate) {
      return { repeatedValue: { value: control.value } };
    }

    return null;
  };
}

export function multipleEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value.length < 3) {
      return null;
    }
    const emails = control.value.split(';').map((email) => email.trim());
    const duplicate = new Set(emails).size !== emails.length;
    let hasErrors = false;
    let hasDuplicate = false;
    emails.forEach((email) => {
      if (email.length && !EMAIL_REGEXP.test(email)) {
        hasErrors = true;
      }
    });
    if (duplicate) {
      hasDuplicate = true;
    }

    if (hasDuplicate) {
      return { repeatedEmails: { value: control.value } };
    }

    if (hasErrors) {
      return { onlyValidEmails: { value: control.value } };
    }
    return null;
  };
}

export function multipleDomainsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value.length < 3) {
      return null;
    }
    const domains = control.value.split(';').map((domain) => domain.trim());
    const duplicate = new Set(domains).size !== domains.length;
    let hasErrors = false;
    let hasDuplicate = false;
    domains.forEach((domain) => {
      if (domain.length && !DOMAIN_REGEXP.test(domain)) {
        hasErrors = true;
      }
    });
    if (duplicate) {
      hasDuplicate = true;
    }

    if (hasDuplicate) {
      return { repeatedValues: { value: control.value } };
    }

    if (hasErrors) {
      return { domain: { value: control.value } };
    }
    return null;
  };
}

export function scheduleValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return { schedule: { value: control.value } };
    }
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = EMAIL_REGEXP.test(control.value);
    return valid ? null : { email: { value: control.value } };
  };
}

export function emailWithoutDomainValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = EMAIL_WITHOUT_DOMAIN_REGEXP.test(control.value);
    return valid ? null : { email: { value: control.value } };
  };
}

export function IP4OrIP6Validator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = IP_REGEXP.test(control.value);
    return !control.value || valid
      ? null
      : { IP4OrIP6: { value: control.value } };
  };
}

export function IPOrSubNetValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid =
      IP_REGEXP.test(control.value) ||
      IP_SUBNET_MASK_REGEXP.test(control.value);
    return !control.value || valid
      ? null
      : { IpOrSubNet: { value: control.value } };
  };
}

export function domainValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = DOMAIN_REGEXP.test(control.value);
    return !control.value || valid
      ? null
      : { domain: { value: control.value } };
  };
}

export function ipv4Validator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = IP_REGEXP.test(control.value);
    return valid ? null : { ip: { value: control.value } };
  };
}

export function validateIpOrDomain(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const ipValid = IP_REGEXP.test(control.value);
    const domainValid = DOMAIN_REGEXP.test(control.value);

    return ipValid || domainValid
      ? null
      : { domainOrIp: { value: control.value } };
  };
}

export function validateEmailIpOrDomain(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const ipValid = IP_REGEXP.test(control.value);
    const domainValid = DOMAIN_REGEXP.test(control.value);
    const emailValid = EMAIL_REGEXP.test(control.value);

    return ipValid || domainValid || emailValid
      ? null
      : { emailDomainOrIp: { value: control.value } };
  };
}

export function validateEmailIpSubNetOrDomain(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const ipValid = IP_REGEXP.test(control.value) || IP_SUBNET_MASK_REGEXP.test(control.value);
    const domainValid = DOMAIN_REGEXP.test(control.value);
    const emailValid = EMAIL_REGEXP.test(control.value);

    return (ipValid || domainValid || emailValid)
      ? null
      : { emailDomainIpOrSubNet: { value: control.value } };
  };
}

export function negativeNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return +control.value < 0
      ? { negativeNumber: { value: control.value } }
      : null;
  };
}

export function isRegex(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isRegExp = (string) => {
      if (!string.length) {
        return false;
      }

      try {
        return new Function(`
              "use strict";
              try {
                  new RegExp(/${string.replaceAll('(?i)', '').replaceAll('(?s)', '').replaceAll('(?m)', '').replaceAll('(?x)', '').replaceAll('(?n)', '').replaceAll('(?-ismxn)', '').replaceAll('(?ismxn:group)', '')}/);
                  return true;
              } catch (e) {
                  return false;
              }
          `)();
      } catch (e) {
        return false;
      }
    };
    return !control.value || isRegExp(control.value)
      ? null
      : { notRegex: { value: control.value } };
  };
}

export const isRegExp = (string) => {
  try {
    if (!string.length) {
      return false;
    }
    return new Function(`
          "use strict";
          try {
              new RegExp(/${string.replaceAll('(?i)', '').replaceAll('(?s)', '').replaceAll('(?m)', '').replaceAll('(?x)', '').replaceAll('(?n)', '').replaceAll('(?-ismxn)', '').replaceAll('(?ismxn:group)', '')}/);
              return true;
          } catch (e) {
              return false;
          }
      `)();
  } catch (e) {
    return false;
  }
};
