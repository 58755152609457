import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '../../../_services/storage.service';
import { MenuService } from '../../services/menu/menu.service';
import { MessageService } from '../../../../gws/shared/message/service/message.service';
import { AppInjector } from '../../../../app-injector.service';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';
import { eula_enUS, eula_ptBR, eulaHTML } from './utils/eula';
import { UserInfoService } from 'src/app/gws/shared/user-info/services/user-info.service';
import { TranslatePipe } from '../../../_pipes/pipes/translate.pipe';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { modalDefaultOptions } from '../../../_models/modal/modal-default-options.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('heroState', [
      state(
        'inactive',
        style({
          backgroundColor: '#eee',
          transform: 'scale(1)',
        }),
      ),
      state(
        'active',
        style({
          backgroundColor: '#cfd8dc',
          transform: 'scale(1.1)',
        }),
      ),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out')),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  @ViewChild('wrapper') public wrapper: ElementRef;
  @ViewChild('matCB') public materialCheckbox: ElementRef;
  public eulaAccept = false;
  public eula: string;
  public iconSrc = '../../../assets/icones/menu/';
  public iconOn = null;
  public hasMenuOpen = false;
  public menus: any = [];
  public menu: any = [];
  public submenu: any = [];
  public subsubmenu: any = [];
  public currentUrl = '';
  public headers: any;
  public active: any = {
    menu: null,
    submenu: null,
    subsubmenu: null,
  };

  constructor(
    private _menuService: MenuService,
    private _storageService: StorageService,
    private _http: HttpClient,
    private _messageService: MessageService,
    private _sweetAlertService: SweetAlertService,
    private _userService: UserInfoService,
    private _router: Router,
    private _translate: TranslatePipe,
    protected _ngbModal: NgbModal,
  ) {
    const injector = AppInjector.getInjector();
    this._messageService = injector?.get(MessageService);
    this._storageService.watchMenuStorage().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      });
    });
  }

  public setEula(eula: boolean) {
    if (!eula) {
      const language = this._storageService.getItem('language');
      this.showEULA(language);
    }
  }

  ngOnInit() {
    this.menus = this._menuService.formattedMenu();
    this.currentUrl = this._router.url;
    this.menu = JSON.parse(this._storageService.getItem('menu'));

    const currentUrl = this.currentUrl;
    const menuObj = [];
    const menuFlags = [];

    // Temporário, até que o menu advanced seja usado
    this.menus = this.menus.filter(
      (element) => element.name.toLowerCase() !== 'menu_advancedmodule',
    );

    this.menus.map((menu, menuIndex) => {
      let active = false;
      menuFlags[menuIndex] = false;
      if (menu['subMenu']) {
        const objSubMenu = menu['subMenu'][0];
        if (objSubMenu) {
          objSubMenu.map((subMenu, subMenuIndex) => {
            const arraySubSubMenus = [];
            for (let i = 0; i < subMenu['subMenu'][0].length; i++) {
              const sub = subMenu['subMenu'][0][i];
              if (sub.href !== './') {
                if (currentUrl.startsWith(sub.href)) {
                  sub.active = active = true;
                }
                arraySubSubMenus.push(sub);
              }
            }
            if (arraySubSubMenus.length) {
              if (!menuFlags[menuIndex]) {
                menuFlags[menuIndex] = true;
                menuObj[menuIndex] = menu;
                menuObj[menuIndex]['subMenu'] = [];
              }
              menuObj[menuIndex].active = active;
              subMenu.active = active;
              subMenu['subMenu'] = arraySubSubMenus;
              menuObj[menuIndex]['subMenu'].push(subMenu);
            }
          });
        }
      } else if (!menuFlags[menuIndex]) {
        if (currentUrl.startsWith(menu.href)) {
          menu.active = active = true;
        }
        menuFlags[menuIndex] = true;
        menuObj[menuIndex] = menu;
      }
    });
    this.menus = menuObj;
  }

  public home(): void {
    this._router.navigate(['/dashboard']);
  }

  public showEULA(language: any) {
    const eula = language === 'pt-BR' ? eula_ptBR : eula_enUS; //TODO: mudar forma para traduzir eula
    const materialNativeElement: HTMLElement =
      this.materialCheckbox.nativeElement;
    this._sweetAlertService.alert(
      {
        title: 'EULA',
        text: 'default', // necessário por padrão
        icon: 'info',
        html: eulaHTML(eula, materialNativeElement.innerHTML),
        position: 'center',
        confirmButtonText: 'OK',
        showCancelButton: true,
        width: '70%',
        allowOutsideClick: false,
        didOpen: () => {
          const eulaCheck = document.getElementById('eula-check');
          const matCheckbox = eulaCheck.firstElementChild;
          const eulaWindow = document.getElementById('eula-wrapper');
          this.settingEvents(eulaWindow, eulaCheck, matCheckbox);
        },
      },
      (result) => {
        if (result.isConfirmed) {
          this._userService.acceptEULA({ eula: 1 }).subscribe((res) => {
            this._storageService.setItem('eula', true);
          });
        } else {
          this.logout();
          location.href = '/';
        }
      },
    );
  }

  public settingEvents(eulaWindow, eulaCheck, matCheckbox) {
    this._sweetAlertService.disableConfirmButton(true);
    eulaCheck.classList.add('cypress-check-eula');
    let hasRead = false;
    eulaWindow.onscroll = () => {
      if (
        eulaWindow.offsetHeight + eulaWindow.scrollTop >=
        eulaWindow.scrollHeight
      ) {
        hasRead = true;
        matCheckbox.classList.remove('mat-checkbox-disabled');
      }
    };
    matCheckbox.classList.add('mat-checkbox-disabled');

    eulaCheck.onclick = () => {
      if (hasRead) {
        const target = eulaCheck.firstElementChild;
        target.classList.toggle('mat-checkbox-checked');
        this.eulaAccept = !this.eulaAccept;
        this._sweetAlertService.disableConfirmButton(!this.eulaAccept);
      }
    };
  }

  public logout(): void {
    const url = '/v1/logout';
    this._http.get(url, { headers: this.headers }).subscribe(() => {
      localStorage.removeItem('token_root');
      localStorage.removeItem('menu');
      localStorage.removeItem('userMail');
      localStorage.removeItem('canResetPassword');
      localStorage.removeItem('token');
      localStorage.removeItem('keep');
      sessionStorage.clear();
    });
  }

  public backAsRoot(): void {
    const url = '/v1/logout';
    this._http.get(url, { headers: this.headers }).subscribe(() => {
      const tokenRoot = this._storageService.getItem('token_root');
      this._storageService.setItem('token', tokenRoot);
      this._storageService.removeItem('token_root');
      this._router.navigate(['/dashboard']).then(() => location.reload());
    });
  }

  public resetMessageService(): void {
    this._messageService?.resetMessageService();
  }

  public openMenu(currentMenu): void {
    this.closeEveryMenu();
    currentMenu.isCollapsed = true;
    this.hasMenuOpen = true;
  }

  public closeEveryMenu() {
    this.hasMenuOpen = false;
    this.menus.forEach((menu) => {
      menu.isCollapsed = false;
    });
  }

  get _isOwner(): boolean {
    const tokenRoot = JSON.parse(this._storageService.getItem('token_root'));
    return !!tokenRoot;
  }

  public openUserGuideModal() {
    const dialog: NgbModalRef = this._ngbModal.open(UserGuideComponent, modalDefaultOptions);
  }
}
