import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { UiModule } from './core/_ui/ui.module';
import { EmailTrackerModule } from './gws/email/email.module';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor } from './core/_helpers/jwt.interceptor';
import { ErrorInterceptor } from './core/_helpers/error.interceptor';
import { AppComponent } from './app.component';
import { RoutingStateService } from './gws/shared/screen-errors/services/routing-state.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { momentAdapterFactory } from './gws/shared/schedule/schedule.module';
import { MatNativeDateModule } from '@angular/material/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { TranslatePipe } from './core/_pipes/pipes/translate.pipe';
import { MaterialModule } from './material.module';
import { MentionModule } from 'angular-mentions';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#303747'
    },
    button: {
      background: 'transparent',
      border: '#FFF',
      link: 'none'
    }
  },
  blacklistPage: ['/', '/login', '/login?returnUrl=%2F'],
  revokable: false,
  theme: 'block',
  type: 'opt-out',
  position: 'top',
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message text-center">{{message}}
      <a id="cookieconsent:link" class="cc-link">{{privacyPolicyLink}}.</a>
      {{message_custom}}
    </span>
    `,
  },
  content:{
    message: TranslatePipe.prototype.transform('message', 'cookie'),
    policy: TranslatePipe.prototype.transform('cookie', 'cookie'),
    allow: TranslatePipe.prototype.transform('allow', 'cookie'),
    deny: TranslatePipe.prototype.transform('deny', 'cookie'),
    privacyPolicyLink: TranslatePipe.prototype.transform('privacyPolicyLink', 'cookie'),
    message_custom: TranslatePipe.prototype.transform('message_custom', 'cookie')
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    UiModule,
    FormsModule,
    EmailTrackerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    MatNativeDateModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MentionModule
  ],
  providers: [
    RoutingStateService,
    FormBuilder,
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
