import * as _ from 'lodash';
import { StorageService } from '../_services/storage.service';
import { environment } from '../../../environments/environment';

export enum secondsInOne {
  week = 604800,
  day = 86400,
  hour = 3600,
}

export function datePickerToDatetime(date) {
  return date
    ? date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
    : '';
}

export function datetimeToDatePicker(date) {
  const aDate = date.split('-');
  const year = parseInt(aDate[0]);
  const month = parseInt(aDate[1]);
  const day = parseInt(aDate[2].split(' ')[0]);

  return { year, month, day };
}

export function randomId() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(0, 5);
}

export function customerId(token?) {
  try {
    if (!token) {
      return jwtGet()['payload']['customerId'];
    }
    return jwtDecode(token)['payload']['customerId'];
  } catch (e) {
    return null;
  }
}

export function userId(token) {
  if (!token) return null;
  return jwtDecode(token)['payload']['userId'];
}

export function jwtGet() {
  const storageService = new StorageService();
  const tokenString = storageService.getItem('token');
  const token = JSON.parse(tokenString);
  return jwtDecode(token);
}

export function jwtDecode(t) {
  const token = {};
  if (t && typeof t === 'string') {
    token['raw'] = copyWithoutReference(t);
    token['header'] = JSON.parse(window.atob(t.split('.')[0]));
    token['payload'] = JSON.parse(window.atob(t.split('.')[1]));
  }
  return token;
}

export function dd(...args) {
  console.log(...args);
}

export function isRoot(token?) {
  const roleId = 1;
  return customerId(token) === roleId;
}

export function getPathPar(position, par = false) {
  if (!isNaN(position)) {
    return isNaN(par[0]) ? `[${position}]` : `[1][${position}]`;
  }

  const keys = position.split('|');
  let path = '';

  keys.forEach((key, i) => {
    if (i === 0) {
      if (!isNaN(par[0])) {
        path += `[1]`;
      }
    } else {
      path += `['par']`;
      path += !isNaN(_.get(par, path)[0]) ? `[1]` : '';
    }

    path += `[${key}]`;
  });

  return path;
}

export function copyWithoutReference(el) {
  return JSON.parse(JSON.stringify(el));
}

dateFormat.masks = {
  default: 'yyyy-mm-dd HH:MM:ss',
  shortDate: 'yyyy-mm-dd',
};

export function leftPad(str: string, len: number, ch = '.'): string {
  len = len - str.length + 1;
  return len > 0 ? new Array(len).join(ch) + str : str;
}

export function isInteger(value: any): value is number {
  return (
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  );
}

export function dateFormat(date, format?) {
  const token =
    /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZWN]|"[^"]*"|'[^']*'/g;
  date = date || new Date();

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  format = String(
    dateFormat.masks[format] || format || dateFormat.masks['default'],
  );
  const locale = 'get';
  const d = date[locale + 'Date']();
  const D = date[locale + 'Day']();
  const m = date[locale + 'Month']();
  const y = date[locale + 'FullYear']();
  const H = date[locale + 'Hours']();
  const M = date[locale + 'Minutes']();
  const s = date[locale + 'Seconds']();
  const L = date[locale + 'Milliseconds']();

  const flags = {
    d,
    dd: leftPad(d.toString(), 2, '0'),
    m: m + 1,
    mm: leftPad((m + 1).toString(), 2, '0'),
    yy: String(y).slice(2),
    yyyy: y,
    h: H % 12 || 12,
    hh: leftPad((H % 12 || 12).toString(), 2, '0'),
    H,
    HH: leftPad(H.toString(), 2, '0'),
    M,
    MM: leftPad(M.toString(), 2, '0'),
    s,
    ss: leftPad(s.toString(), 2, '0'),
    l: leftPad(L.toString(), 3, '0'),
    L: leftPad(Math.round(L / 10).toString(), 2, '0'),
  };

  return format.replace(token, (match) => {
    if (match in flags) {
      return flags[match];
    }
    return match.slice(1, match.length - 1);
  });
}

export function isObjectEmpty(obj): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function sentryJwtPayload(token) {
  return jwtDecode(token)['payload'];
}

export function toCamelCase(string) {
  return string.toLowerCase().replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
}

export function secondsToReadable(seconds: number): string {
  let returnString = '';

  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;

  const hours = Math.ceil(seconds / 3600);
  seconds -= hours * 3600;

  if (+days) {
    returnString = returnString.concat(`${days}d, `);
  }
  returnString = returnString.concat(`${hours}h`);
  return returnString;
}

export function convertTimeToSeconds(
  type: 'hours' | 'days' | 'weeks',
  expirationTime: number,
): number {
  switch (type) {
    case 'hours':
      expirationTime = expirationTime * secondsInOne.hour;
      break;
    case 'days':
      expirationTime = expirationTime * secondsInOne.day;
      break;
    case 'weeks':
      expirationTime = expirationTime * secondsInOne.week;
      break;
  }
  return expirationTime;
}

export function getExpirationType(
  expirationTimeInSeconds: number,
  hasWeeks = true,
): string {
  const expirationTypes = {
    hours: expirationTimeInSeconds / secondsInOne.hour,
    days: expirationTimeInSeconds / secondsInOne.day,
    weeks: expirationTimeInSeconds / secondsInOne.week,
  };
  if (hasWeeks && Number.isInteger(expirationTypes.weeks)) {
    return 'weeks';
  }
  if (Number.isInteger(expirationTypes.days)) {
    return 'days';
  }
  return 'hours';
}

export function getShortExpirationTime(
  expirationTimeInSeconds: number,
  hasWeeks = true,
): number {
  const expirationTypes = {
    hours: expirationTimeInSeconds / secondsInOne.hour,
    days: expirationTimeInSeconds / secondsInOne.day,
    weeks: null,
  };

  if (hasWeeks) {
    expirationTypes.weeks = expirationTimeInSeconds / secondsInOne.week;
  }

  return expirationTypes[getExpirationType(expirationTimeInSeconds)];
}

export function consoleLogDevEnv(message?: any, ...optionalParams: any[]) {
  if (!environment.production) {
    console.log('DevEnv>', message, ...optionalParams);
  }
}
