import { Component, Input, OnInit } from '@angular/core';
import * as Moment from 'moment';

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.scss'],
})
export class TimelineCardComponent implements OnInit {
  @Input() message;
  constructor() {}

  ngOnInit(): void {}

  public checkIfNull(messageName: string) {
    return messageName ? true : false;
  }

  public getIcon(messageName: string): string {
    switch (messageName) {
      case 'released':
        return 'share-square';
      case 'timeout':
        return 'times';
      case 'deliver':
        return 'envelope-open';
      case 'discard':
        return 'trash-alt';
      case 'received':
        return 'envelope';
      case 'reject':
        return 'ban';
      case 'retry':
        return 'undo';
      case 'relay':
        return 'exchange-alt';
    }
  }

  public getIconPrefix(messageName: string): string {
    switch (messageName) {
      case 'reject':
        return 'fas';
      case 'retry':
        return 'fas';
      case 'relay':
        return 'fas';
      default:
        return 'far';
    }
  }

  public formatDate(messageDate: string): string {
    return Moment.utc(messageDate, 'YYYY/MM/DD hh:mm:ss')
      .local()
      .format('hh:mm a - DD/MM');
  }
}
