import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { customerId } from '../../../_helpers/util';
import { AppInjector } from '../../../../app-injector.service';
import { StorageService } from '../../../_services/storage.service';
import { MessageService } from '../../../../gws/shared/message/service/message.service';
import { FieldListModel } from '../../models/list/field-list.model';
import { CronService } from 'src/app/lib/crontab/services/cron.parsing.service';

export interface ColumnsModel {
  id: string;
  label: string;
}

export enum AdditionalActionModel {
  IMPORT_NOW = 1,
  SIGN_IN = 2,
  RESET_PASSWORD = 3,
  SEND_DIGEST = 4,
  SIGNIN_CUSTOMER = 5,
  UNLOCK_ADMIN = 6,
}

@Directive()
export abstract class AbstractListDirective {
  @Input() public checkboxModel = [];
  @Input() public checkboxAll: any[] | boolean = false;
  @Input() public list: FieldListModel;
  @Input() public title = null;
  @Input() public additionalAction: AdditionalActionModel[] = [];
  @Input() public hasAdd = true;
  @Input() public hasDelete = true;
  @Input() public checkDisabled = [];
  @Output() public actionTrigger = new EventEmitter();
  @Output() public singleCheckboxClick = new EventEmitter();
  @Output() public checkboxAllClick = new EventEmitter();
  @Output() public checkboxReset = new EventEmitter();

  public additionalActionList = AdditionalActionModel;
  public columns: ColumnsModel[];
  public rows: object[];
  public showPagination = false;
  public sizeExpanded = [];

  private _messageService: MessageService;
  private _storageService: StorageService;
  private _cronService: CronService;

  constructor() {
    const injector = AppInjector.getInjector();
    this._messageService = injector?.get(MessageService);
    this._storageService = injector?.get(StorageService);
    this._cronService = injector?.get(CronService);
    this._emitResetCheckbox();
  }

  public isDisabled(index: number) {
    if (this.checkDisabled.length > 0) {
      return this.checkDisabled[index];
    }
    return false;
  }

  public setColumns(): void {
    const columns = [];
    if (this.list) {
      for (const key in this.list.columns) {
        if (key === 'json') {
          this.list.columns[key].forEach((json) => {
            columns.push({ id: json.id, label: json.label });
          });
        } else {
          columns.push({ id: key, label: this.list.columns[key] });
        }
      }
    }

    this.columns = columns;
  }

  public setRows(): void {
    try {
      this.list.rows = this._cronService?.makeCronReadable(
        'schedule',
        this.list?.rows,
      );
      this.rows = this.list.rows;
    } catch (e) {
    }
  }

  public setValueEnable(): void {
    this.rows?.forEach((r) => {
      Object.keys(r).forEach((k) => {
        if (k === 'enabled') {
          r[k] = r[k] === '0' ? false : true;
        }
      });
    });
  }

  public isCollumnEnable(column): boolean {
    return column === 'enabled';
  }

  public isJsonTable(column): boolean {
    column = typeof column !== 'string' ? JSON.stringify(column) : column;

    try {
      column = JSON.parse(column);
    } catch (e) {
      return false;
    }

    return column !== null && typeof column === 'object' && column !== null;
  }

  public setConfigJsonList(row, column): { column: any; value: any } {
    const columnJsonList = this.list.columns['json'].filter(
      (col) => col.id === column.id,
    )[0].columns;

    return {
      column: columnJsonList,
      value: JSON.parse(row['json'])[column.id],
    };
  }

  public getValue(row): any[] {
    const returnRow = [];

    this.columns.forEach((column) => {
      const value =
        row[column.id] === undefined
          ? this.setConfigJsonList(row, column)
          : row[column.id];
      returnRow.push(value);
    });

    return returnRow;
  }

  public setAction(action, id = null, connectionType = null): void {
    this._messageService?.resetMessageService();
    this.actionTrigger.emit({ action, id, connectionType });
  }

  public baseCheckPermission(key, permission): boolean {
    if (this.list.permissions.hasOwnProperty(key)) {
      return (
        this.list.permissions[key].filter((value) => value === permission)
          .length > 0
      );
    }
    return false;
  }

  public checkCustomerIdPermission(row = null): boolean {
    let checkCustomerId = true;
    const token = this._storageService.getItem('token');

    if (token === null) {
      return false;
    }
    if (row !== null && row.hasOwnProperty('customerId')) {
      checkCustomerId = +customerId(JSON.parse(token)) === +row['customerId'];
    }

    return checkCustomerId;
  }

  public showForm(): boolean {
    return this.baseCheckPermission('R', 'form');
  }

  public showNew(): boolean {
    return (
      this.baseCheckPermission('C', 'exec') && this.showForm() && this.hasAdd
    );
  }

  public showEdit(row = null): boolean {
    return (
      this.baseCheckPermission('U', 'exec') &&
      this.showForm() &&
      this.checkCustomerIdPermission(row)
    );
  }

  public showDelete(row = null): boolean {
    return (
      this.baseCheckPermission('D', 'exec') &&
      this.checkCustomerIdPermission(row) &&
      this.hasDelete
    );
  }

  public showCopy(): boolean {
    return this.showNew() && this.showEdit();
  }

  public showAdditionalAction(
    id: AdditionalActionModel,
    signIn = false,
  ): boolean {
    if (this.additionalAction.length === 0) {
      return false;
    }

    if (AdditionalActionModel.SIGN_IN === id && !signIn) {
      return false;
    }

    return (
      this.additionalAction.filter((additionalId) => additionalId === id)
        .length > 0
    );
  }

  public changeStatus(row): void {
    const enabled = row.enabled ? '0' : '1';
    this.actionTrigger.emit({ action: 'change_status', id: row.id, enabled });
  }

  public setPagination(): void {
    this.showPagination = this.list.totalPages > 1;
  }

  public setPageSize(pageSize: number): void {
    this.actionTrigger.emit({ action: 'change_page_size', pageSize });
  }

  public get currentTitle(): string {
    return this.title === null ? this.list.title : this.title;
  }

  public setCheckboxModel(): void {
    if (this.list) {
      this.checkboxModel.length = this.list.rows.length;
      this.sizeExpanded.length = this.list.rows.length;
    }
    this.checkboxModel.fill(false);
    this.sizeExpanded.fill(false);
  }

  public onCheckboxAllClick(): void {
    this._normalizeCheckboxModel();
    const checkboxAllValue = !!this.checkboxAll;

    this.checkboxModel.length = this.list.rows.length;
    this.checkboxModel.fill(checkboxAllValue);
    this.checkDisabled.forEach((disabled, i) => {
      if (disabled) {
        this.checkboxModel[i] = false;
      }
    });
    this._emitCheckboxAll();
  }

  public onSingleCheckboxClick(row, index): void {
    this._normalizeCheckboxModel(false);
    const numberOfChecked = this.checkboxModel.filter(Boolean).length;
    const isAtLeastOneChecked = !!numberOfChecked;

    if (!isAtLeastOneChecked) {
      this.checkboxAll = false;
    }

    if (numberOfChecked === this.list.rows.length) {
      this.checkboxAll = true;
    } else {
      this.checkboxAll = false;
    }

    this._emitSingleCheckbox(row, index);
  }

  protected _emitSingleCheckbox(row, index): void {
    this.singleCheckboxClick.emit({
      row,
      index,
      checkboxModel: this.checkboxModel,
      list: this.list,
    });
  }

  protected _emitCheckboxAll(): void {
    this.checkboxAllClick.emit({
      list: this.list,
      checkboxModel: this.checkboxModel,
    });
  }

  private _normalizeCheckboxModel(setModel = true): void {
    if (setModel) {
      this.setCheckboxModel();
    }

    this.checkboxModel = this.checkboxModel.map((checkbox) => {
      if (checkbox && typeof checkbox === 'object') {
        checkbox = !!checkbox.length;
      }

      return checkbox;
    });
  }

  protected _emitResetCheckbox(): void {
    setTimeout(() => {
      this.checkboxReset.emit();
    });
  }
}
