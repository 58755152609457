import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Injectable()
export class RoutingStateService {

  private history = [];

  constructor(private router: Router, private ccService: NgcCookieConsentService) { }

  loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        if (urlAfterRedirects === '/dashboard' && !this.ccService.hasAnswered()) {
          this.ccService.open();
        } else if (urlAfterRedirects === '/login') {
          this.ccService.close(false);
        }

        this.history = [...this.history, urlAfterRedirects];
      });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/dashboard';
  }
}
