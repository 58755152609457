import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { FormService } from '../../../services/form/form.service';

@Component({
  selector: 'app-ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
})
export class UiInputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input() public id: string;
  @Input() public randomId: string;
  @Input() public min = 1;
  @Input() public max = 255;
  @Input() public step: number;
  @Input() public type = 'text';
  @Input() public mask = '';
  @Input() public title: string;
  @Input() public placeholder = '';
  @Input() public autocomplete = '';
  @Input() public readonly = false;
  @Input() public control: FormGroup;
  @Input() public cy: string;
  @Input() public inputClass = 'form-control form-control-sm';
  @Input() public isModal = false;
  @Input() public prefixText = null;
  @Input() public lowercase = false;
  @Input() public disabled = false;
  @Output() public blur = new EventEmitter();
  public loaded = false;
  public value = '';
  public width = '12px';

  @ViewChild('inputElement', { static: true })
  private _inputElement: ElementRef;
  @ViewChild('prefix')
  private _prefix: ElementRef;

  constructor(private _renderer: Renderer2, public formService: FormService) {}

  ngOnInit(): void {
    if (this.readonly) {
      this.disableField();
    }

    this.randomId =
      this.randomId === undefined || this.randomId === null
        ? this.id
        : this.randomId;
  }

  ngAfterViewInit() {
    this.loaded = true;
    setTimeout(() => {
      if (this.loaded && this.prefixText) {
        this.width = this._prefix.nativeElement.offsetWidth + 12 + 'px';
      }
    });
  }

  public get getWidth() {
    return this.width;
  }

  public disableField(): void {
    setTimeout(() => {
      this.control.get(this.id).disable();
    });
  }

  public get inputElement(): ElementRef {
    return this._inputElement;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._inputElement.nativeElement,
      'disabled',
      isDisabled
    );
  }

  public onChange(event: any): void {
    this._onChange(event.target.value);
  }

  public onKeyup(event: any): void {
    this._onChange(event.target.value);
  }

  public onBlur(event: any): void {
    this._onTouched();
    this.blur.emit(event);
  }

  private _onChange(eventValue: any) {
    try {
      const value = JSON.parse(`{"${this.id}": "${eventValue}"}`);
      this.control.patchValue(value);
    } catch (error) {}
  }

  private _onTouched() {}
}
