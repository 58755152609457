import { SearchHelpItem } from '../search-help/search-help-item.model';

export class TagMerged {
  searchItem: SearchHelpItem;
  complement: string;
  id: string;

  get display() {
    return this.searchItem.searchItem + ': ' + this.complement;
  }

  get value() {
    return this;
  }

  constructor(searchHelpItem: SearchHelpItem, complement: string = '') {
    this.searchItem = searchHelpItem;
    this.complement = complement;
  }
}
