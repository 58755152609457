import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SwitchToggleSettings } from '../../models/types/switch-toggle-settings.model';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle.switch.component.html',
  styleUrls: ['./toggle.switch.component.scss']
})
export class ToggleSwitchComponent {
  private _initialSetting = {
    name: 'switchToggle',
    value: 1,
    id: 'switchToggle'
  };

  @Input() public setting: SwitchToggleSettings = this._initialSetting;
  @Input() public hasMiddle = true;
  @Output() public settingEvent = new EventEmitter();

  public setValue(element): void {
    this.setting.value = element;
    this.settingEvent.emit(this.setting);
  }
}
