<ng-container *ngIf="!timePicker">
  <mat-date-range-input
    [formGroup]="range"
    [ngClass]="inputHidden ? 'no-input-btn' : 'custom-separator'"
    [rangePicker]="picker"
    [min]="options.minDate"
    [max]="options.maxDate"
  >
    <input formControlName="start" matStartDate readonly />
    <input matEndDate formControlName="end" readonly />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker
    #picker
    [startAt]="options.endDate"
  ></mat-date-range-picker>
</ng-container>

<ng-container *ngIf="timePicker">
  <button class="calendar-button" (click)="openPicker()">
    <svg
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="currentColor"
      focusable="false"
    >
      <path
        d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"
      ></path>
    </svg>
  </button>
</ng-container>
