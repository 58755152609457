import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserGuideService } from '../../../../services/user-guide/user-guide.service';
import { UserGuideModel } from '../../../../models/user-guide/user-guide.model';
import { UiModalFormComponent } from '../../../modal-form/modal-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
})
export class UserGuideComponent extends UiModalFormComponent implements OnInit {

  @ViewChild('userGuideFrame') protected _userGuideFrame: ElementRef;

  public userGuide: string | null = null;

  @Input() public btnCancelMain = true;
  @Input() public btnCancelText: 'btn_cancel' | 'btn_close' = 'btn_close';

  constructor(
    protected _ngbModal: NgbModal,
    public router: Router,
    public activeModal: NgbActiveModal,
    protected _userGuideService: UserGuideService,
  ) {
    super(_ngbModal, router, activeModal);
  }

  ngOnInit(): void {
    this._userGuideService.getUserGuide().subscribe((userGuide: UserGuideModel) => {
      this.userGuide = userGuide.fields?.content;
      this._setUserGuideContent();
    });
  }

  protected _setUserGuideContent(): void {
    const frameSetAction = () => {
      if (!this._userGuideFrame) {
        return setTimeout(frameSetAction, 150);
      }

      try {
        const userGuideFrame: HTMLIFrameElement = this._userGuideFrame.nativeElement;
        userGuideFrame.src = 'data:application/pdf;base64,' + this.userGuide;
      } catch (e) {
        // ---
      }
    };

    frameSetAction();
  }
}
