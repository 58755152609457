export class ParsedCron {
  cronValue: any;
  parsedDate: Date;
  timeZoneCorrectDate: Date;
  hours: number | string;
  minutes: number | string;
  dayOfWeek: string;
  monthDays: string;
  periodicity: {
    periodicity: string;
    period: number;
  };
}
