import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AclService } from '../_guards/acl/services/acl.service';
import { StorageService } from './storage.service';
import { consoleLogDevEnv, copyWithoutReference } from '../_helpers/util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private _http: HttpClient,
    private _aclService: AclService,
    private _storageService: StorageService,
  ) {
  }

  public thirdStep = false;

  public validateMFA(login: string, code: string): Observable<any> {
    return this._http.post<any>('/v1/two_factor/validate_code', {
      login,
      code,
    });
  }

  public login(
    username: string,
    password: string,
    keep: false,
    force: boolean = false,
    only_validate: boolean = false,
  ): Observable<any> {
    const path =
      '/v1/login/' +
      this._storageService.getItem('language') +
      (force ? '?force=1' : '');

    return this._http.post<any>(path, { login: username, password, only_validate }).pipe(
      map((response) => {
        try {
          if (response && response.fields?.token && !this.thirdStep) {
            this.localStorageFunctions(response, keep);
          }
        } catch (e) {
          consoleLogDevEnv(e);
        }

        return response;
      }),
    );
  }

  public localStorageFunctions(response, keep) {
    const filter = copyWithoutReference(response.menu);

    const menu = JSON.stringify(filter);
    this._aclService.setModules(JSON.parse(menu));
    this._storageService.setItem('menu', menu);
    this._storageService.setItem(
      'token',
      JSON.stringify(response.fields.token),
    );
    this._storageService.setItem('keep', keep);
  }

  public logout(): void {
    this._storageService.removeItem('menu');
    this._storageService.removeItem('token');
  }

  public getLoginType(email: string): Observable<any> {
    return this._http.post('/v1/login-type', { email });
  }

  public sendResetPassword(adminMail: string): Observable<any> {
    return this._http.post('/v1/forgot-password', { email: adminMail });
  }

  public setNewPassword(token: string, password: string): Observable<any> {
    return this._http.post(`/v1/set-password?token=${token}`, { password });
  }

  public getPasswordOptions(token: string): Observable<any> {
    return this._http.get(`/v1/set-password/options?token=${token}`);
  }
}
