import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AclGuard } from '../../core/_guards/acl/guard/acl.guard';

import { QuarantineComponent } from './pages/quarantine/quarantine.component';
import { EmailTrackerComponent } from './pages/tracker/tracker.component';

const emailRoutes: Routes = [
  {
    path: 'email-tracker',
    component: EmailTrackerComponent,
    canActivate: [AclGuard],
    data: {
      title: 'Email Tracker | Gatefy',
      acl: {
        locale: 'shallowModule',
        permission: 'emailTrackerMenu',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(emailRoutes)],
  exports: [RouterModule],
})
export class EmailRoutingModule {}
