<div class="verified" *ngIf="element.verified === '1'">
  {{ 'verified' | translate }}
</div>
<div
  class="btn btn-outline-danger btn-sm"
  *ngIf="element.verified === '0'"
  (click)="pressed = !pressed"
  #notVerifiedButton
>
  {{ 'verify' | translate }}
</div>
<div *ngIf="pressed" class="check-domain-popup" #checkDomain>
  <span class="triangle">▲</span>
  {{ 'not_verified_yet' | translate: 'labels' }}
  <app-check-domain
    [dnsHash]="element.token"
    [domain]="element.domain"
    [showHelp]="false"
    [custom]="true"
    (event)="checked($event)"
  ></app-check-domain>
</div>
