<div class="gatefy-content-box gatefy-encapsulated gatefy-list mt-4 mb-4">
  <div class="row mb-3">
    <div class="btns-wrapper policy-btn actions-container" *ngIf="showNew()">
      <button
        type="button"
        data-cy="btnListAdd"
        class="btn btn-primary btn-sm btn-list-add"
        (click)="setAction('new', this.getRows().length, true)"
      >
        <span class="fa fa-plus"></span> {{ 'btn_add' | translate }}
      </button>
    </div>
  </div>
  <div>
    <div class="table-wrapper">
      <div class="body table-responsive-sm">
        <table mat-table [dataSource]="fields" #table>
          <ng-container matColumnDef="position" *ngIf="reoder">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              class="drag-handle-container table-reorderablerow-handle"
              mat-cell
              *matCellDef="let element"
            >
              <mat-icon class="drag-handle">drag_handle</mat-icon>
            </td>
          </ng-container>

          <ng-container *ngFor="let column of columns; let i = index">
            <ng-container [matColumnDef]="column.id">
              <th mat-header-cell *matHeaderCellDef>
                {{ column.id | translate: 'labels' }}
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column.id !== 'enab'">
                  {{ element[column.id] }}
                </ng-container>
                <ng-container *ngIf="column.id === 'enab'">
                  <mat-slide-toggle
                    #enabToggle
                    (change)="changeStatus(element, $event.checked)"
                    [checked]="element[column.id]"
                  ></mat-slide-toggle>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th class="actions-column" mat-header-cell *matHeaderCellDef>
              {{ 'list_actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let index = index">
              <div class="action-wrapper">
                <td class="list-btn">
                  <button
                    class="action-btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-h"></i>
                  </button>

                  <ng-container>
                    <div
                      class="options-wrapper dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        *ngIf="!showEdit()"
                        class="btn gatefy-btn"
                        data-cy="btnListShowRule"
                        (click)="setAction('show', index)"
                      >
                        <span class="fas fa-eye btn-icon"></span>
                        <p class="btn-text">{{ 'tooltip_edit' | translate }}</p>
                      </a>
                      <a
                        *ngIf="showEdit()"
                        class="btn gatefy-btn"
                        data-cy="btnListEditRule"
                        (click)="setAction('edit', index)"
                      >
                        <span class="fas fa-pencil-alt btn-icon"></span>
                        <p class="btn-text">{{ 'tooltip_edit' | translate }}</p>
                      </a>
                      <a
                        *ngIf="showCopy()"
                        class="btn gatefy-btn"
                        data-cy="btnListCopyRule"
                        (click)="setAction('copy', index)"
                      >
                        <span class="fas fa-copy btn-icon"></span>
                        <p class="btn-text">{{ 'tooltip_copy' | translate }}</p>
                      </a>
                      <a
                        *ngIf="showDelete()"
                        class="btn gatefy-btn delete-btn"
                        data-cy="btnListDeleteRule"
                        (click)="!readonly && setAction('delete', index)"
                      >
                        <span class="fas fa-times btn-icon"></span>
                        <p class="btn-text">
                          {{ 'tooltip_delete' | translate }}
                        </p>
                      </a>
                    </div>
                  </ng-container>
                </td>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; index as i; columns: displayedColumns"
            [appUiReorderableRow]="i"
            [reorderableRowDisabled]="!reoder"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
