<div
  class="mt-3 mb-3 content-wrapper"
  [ngClass]="{
    'not-empty':
      formData?.value?.length > 0 &&
      (id === 'notify_blocking' || id === 'notify_releasing')
  }"
>
  <div class="d-flex flex-row justify-content-between mb-4">
    <form
      class="field-wrapper"
      [ngClass]="{
        'long-field': !formData?.value || formData?.value?.length === 0
      }"
    >
      <div
        class="input-wrapper d-inline-block"
        [ngClass]="{
          'input-wrapper-100':
            id === 'notify_blocking' || id === 'notify_releasing'
        }"
      >
        <app-ui-labeled-input
          [id]="'value'"
          [control]="entityForm"
          [placeholder]="
            'condition_' + id + '_placeholder' | translate: 'labels'
          "
          [inputClass]="'form-control'"
          [label]="getLabels() | translate: 'labels'"
          [tooltip]="id === 'notify_blocking' || id === 'notify_releasing'"
          [tooltipMessage]="id + '_help'"
        ></app-ui-labeled-input>
      </div>
      <button
        (click)="addEmail()"
        [disabled]="entityForm.controls['value'].value === ''"
        class="btn btn-primary wide-btn btn-add d-inline-block"
        data-cy="listAdd"
        type="submit"
      >
        <span class="fa fa-plus"></span>
      </button>
    </form>
  </div>

  <ng-container *ngIf="formData?.value?.length > 0">
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li
            *ngFor="let item of formData?.value; index as i"
            [id]="formId(i)"
            [type]="type"
            class="list-group-item d-flex justify-content-between"
          >
            <p class="d-flex align-items-center m-0">{{ item }}</p>
            <button
              *ngIf="formData?.value?.length > 0"
              (click)="deleteEmail(i)"
              [ngbTooltip]="'Delete'"
              class="btn btn-primary d-inline-block text-right"
              type="submit"
            >
              <span class="fa fa-times"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!formData?.value || formData?.value?.length === 0">
    <div class="alert alert-info" role="alert">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      <ng-container>
        {{ 'noRecordsRedirectList' | translate: 'fe' }}
      </ng-container>
    </div>
  </ng-container>
</div>
