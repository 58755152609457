import { SearchHelpItem } from '../search-help/search-help-item.model';

export const searchItems: SearchHelpItem[] = [
  new SearchHelpItem('general'),
  new SearchHelpItem('subject'),
  new SearchHelpItem('sender'),
  new SearchHelpItem('senderMTA'),
  new SearchHelpItem('senderHeader'),
  new SearchHelpItem('emailAddress'),
  new SearchHelpItem('status'),
  new SearchHelpItem('flags'),
  new SearchHelpItem('recipient'),
];
