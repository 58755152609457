import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RequestOptionsModel } from '../../_models/api-communicator/request-options.model';

@Injectable({ providedIn: 'root' })
export class ApiCommunicatorService {
  public baseUrl = '/v1/';
  protected _callUrl = '';

  constructor(protected _http: HttpClient) {
    this._init_();
  }

  protected _init_() {}

  public apiGet(extraParam: string = '') {
    return this._http.get<any>(this._path(extraParam), this._requestOptions());
  }

  public apiPut(extraParam: string = '', fields: object) {
    return this._http.put<any>(
      this._path(extraParam),
      fields,
      this._requestOptions()
    );
  }

  public apiDelete(extraParam: string = '') {
    return this._http.delete(this._path(extraParam), this._requestOptions());
  }

  public apiPost(extraParam: string = '', fields: object) {
    return this._http.post<any>(
      this._path(extraParam),
      fields,
      this._requestOptions()
    );
  }

  public set callUrl(uri: string) {
    this._callUrl = this._removeBar(uri);
  }

  protected _requestOptions(): RequestOptionsModel {
    return {
      params: new HttpParams(),
    };
  }

  protected _removeBar(item: string | number): string {
    if ( typeof item === 'number') { return item.toString(); }
    return item.replace(/^\/+/, '');
  }

  protected _path(extraParam: string = ''): string {
    let path = this.baseUrl + this._callUrl;
    if (extraParam || extraParam.length) {
      path += '/' + this._removeBar(extraParam);
    }
    return path;
  }
}
