import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-error-form',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class UiErrorFormComponent {
  @Input() public control: FormGroup;
  @Input() public hasError: FormGroup;

  public params: string[];

  public errorMessage = {
    required: { message: 'required_field', params: [] },
    email: { message: 'email_field', params: [] },
    IP4OrIP6: { message: 'ip4_ip6_field', params: [] },
    IpOrSubNet: { message: 'ip_netmask_field', params: [] },
    domain: { message: 'domain_field', params: [] },
    minlength: { message: 'minLength', params: [] },
    maxlength: { message: 'maxLength', params: [] },
    domainOrIp: { message: 'domain_ip_field', params: [] },
    emailDomainOrIp: { message: 'email_domain_ip_field', params: [] },
    emailDomainIpOrSubNet: { message: 'email_domain_ip_field', params: [] },
    ip: { message: 'ip_field', params: [] },
    negativeNumber: { message: 'negativeNumber', params: [] },
    notRegex: { message: 'notRegex', params: [] },
    onlyValidEmails: { message: 'onlyValidEmails', params: [] },
    repeatedEmails: { message: 'repeatedEmails', params: [] },
    repeatedValues: { message: 'repeatedValues', params: [] },
  };

  public getErrors(errors) {
    const errorsName = Object.keys(errors);

    errorsName.forEach((name) => {
      this.setParams(name, errors[name]);
    });

    return errorsName;
  }

  public setParams(name, error) {
    switch (name) {
      case 'minlength':
      case 'maxlength':
        this.errorMessage[name].params.push(error.requiredLength);
        break;
      case 'aliasExists':
        this.errorMessage[name] = { message: error, params: [] };
        break;
    }
  }
}
