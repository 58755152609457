// IPv4

const IPv4_SEG: string = /(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)/.source; // 0-255
const IPv4_ADDR: string = `(${IPv4_SEG}(\\.${IPv4_SEG}){3})`;

export const IPv4_REGEXP: RegExp = new RegExp(`(^${IPv4_ADDR}$)`);

export const IPv4_SUBNET_MASK_REGEXP: RegExp = new RegExp(
  IPv4_REGEXP.source.replace(/\$/, /\/([12]?\d|3[0-2])$/.source), // 0-32
);

// IPv6

const HEXADECIMAL: string = /[0-9A-Fa-f]/.source;
const IPv6_SEG: string = `${HEXADECIMAL}{1,4}`;

// Lógica de validação obtida de:
// https://stackoverflow.com/questions/53497/regular-expression-that-matches-valid-ipv6-addresses
export const IPv6_REGEXP: RegExp = new RegExp([
  '(^((', [
    `(${IPv6_SEG}:){7,7}${IPv6_SEG}`,              // 1:2:3:4:5:6:7:8
    `(${IPv6_SEG}:){1,7}:`,                        // 1::                                 1:2:3:4:5:6:7::
    `(${IPv6_SEG}:){1,6}:${IPv6_SEG}`,             // 1::8               1:2:3:4:5:6::8   1:2:3:4:5:6::8
    `(${IPv6_SEG}:){1,5}(:${IPv6_SEG}){1,2}`,      // 1::7:8             1:2:3:4:5::7:8   1:2:3:4:5::8
    `(${IPv6_SEG}:){1,4}(:${IPv6_SEG}){1,3}`,      // 1::6:7:8           1:2:3:4::6:7:8   1:2:3:4::8
    `(${IPv6_SEG}:){1,3}(:${IPv6_SEG}){1,4}`,      // 1::5:6:7:8         1:2:3::5:6:7:8   1:2:3::8
    `(${IPv6_SEG}:){1,2}(:${IPv6_SEG}){1,5}`,      // 1::4:5:6:7:8       1:2::4:5:6:7:8   1:2::8
    `${IPv6_SEG}:((:${IPv6_SEG}){1,6})`,           // 1::3:4:5:6:7:8     1::3:4:5:6:7:8   1::8
    `:((:${IPv6_SEG}){1,7}|:)`,                    // ::2:3:4:5:6:7:8    ::2:3:4:5:6:7:8  ::8       ::
    `[fF][eE]80:(:${IPv6_SEG}){0,4}%[0-9a-zA-Z]+`, // fe80::7:8%eth0     fe80::7:8%1  (link-local IPv6 addresses with zone index)
    `::([fF]{4}(:0{1,4})?:)?${IPv4_ADDR}`,         // ::255.255.255.255  ::ffff:255.255.255.255  ::ffff:0:255.255.255.255 (IPv4-mapped IPv6 addresses and IPv4-translated addresses)
    `(${IPv6_SEG}:){1,4}:${IPv4_ADDR}`,            // 2001:db8:3:4::192.0.2.33  64:ff9b::192.0.2.33 (IPv4-Embedded IPv6 Address)
  ].join(')|('),
  '))$)',
].join(''));

export const IPv6_SUBNET_MASK_REGEXP: RegExp = new RegExp(
  IPv6_REGEXP.source.replace(/\$/, /\/([89]|[1-5]\d|6[0-4])$/.source), // 8-64
);

// IP (IPv4 ou IPv6)

export const IP_REGEXP: RegExp = new RegExp([
  IPv4_REGEXP.source,
  IPv6_REGEXP.source,
].join('|'));

export const IP_SUBNET_MASK_REGEXP: RegExp = new RegExp([
  IPv4_SUBNET_MASK_REGEXP.source,
  IPv6_SUBNET_MASK_REGEXP.source,
].join('|'));
