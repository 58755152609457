import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';

@Component({
  selector: 'app-select-page-size',
  templateUrl: './select-page-size.component.html',
  styleUrls: ['./select-page-size.component.scss'],
})
export class SelectPageSizeComponent implements OnInit {
  @Input() public numbers: number[] = [];
  @Output() public valueSelected = new EventEmitter();
  @Input() public currentPageSize: number;
  public collection: { id: number; name: string }[];
  public pageForm: FormGroup;

  constructor(private _formBuilder: FormBuilder, protected _translate: TranslatePipe) {}

  ngOnInit() {
    this.collection = this.numbers.map(number => {
      return {
        id: number,
        name: `${this._translate.transform('items_per_page', 'labels')} ${number}`
      };
    });

    this.pageForm = this._formBuilder.group({
      size: [this.numbers[0]]
    });
  }

  public emitAction(): void {
    this.currentPageSize = this.pageForm.getRawValue().size;
    this.valueSelected.emit(this.currentPageSize);
  }
}
