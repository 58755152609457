<div class="modal-body">
  <button (click)="ngbActiveModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="container text-center text-primary mt-3 mb-4">
    <p>
      <i class="fa fa-info-circle fa-5x"></i>
    </p>
  </div>
  <div class="container">
    <h3>
      {{ 'ET_searchHelp' | translate:'titles' }}
    </h3>
    <p class="mb-4">
      {{ 'ET_searchHelp_description_header' | translate }}
    </p>
    <p *ngFor="let searchHelpItem of searchHelpItems; index as i" class="mb-2">
      <kbd>{{ searchHelpItem.searchItem.charAt(0).toUpperCase() + searchHelpItem.searchItem.slice(1) }}</kbd>
      {{searchHelpItem.searchText}}
    </p>
    <p class="mt-4">
      {{ textSplitter('ET_searchHelp_description_body')[0] }}
      <kbd class="tag">{{ 'ET_tag_example' | translate }}</kbd>
      {{ textSplitter('ET_searchHelp_description_body')[1] }}
    </p>
    <p>
      {{ textSplitter('ET_searchHelp_description_end')[0] }}
      <kbd>{{ 'enter' | translate }}</kbd>
      {{ textSplitter('ET_searchHelp_description_end')[1] }}
    </p>
  </div>
</div>
<div class="modal-footer">
  <button
    (click)="ngbActiveModal.dismiss('Close click')"
    class="btn btn-primary"
    type="button"
  >
    {{ 'btn_close' | translate }}
  </button>
</div>
