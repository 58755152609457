<app-ui-modal-form
  [title]="'archiveFlags' | translate: 'titles'"
  (modalEvent)="modalEvent($event)"
>
  <div class="container">
    <app-loading *ngIf="!flagLoaded; else loadFlag"></app-loading>
    <ng-template #loadFlag>
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group list-group-flush">
            <li *ngFor="let flag of showArchiveFlags" class="list-group-item">
              <p class="float-left">{{ flag.name }}</p>
              <app-toggle-switch
                (settingEvent)="changeFlags()"
                [hasMiddle]="false"
                [setting]="flag"
              >
              </app-toggle-switch>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>
</app-ui-modal-form>
