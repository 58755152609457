import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '../../../../core/_pipes/pipes.module';
import { UiModule } from '../../../../core/_ui/ui.module';
import { EmailActionComponent } from './email-action.component';
import { ArchiveFlagActionComponent } from './archiveFlags/archive-flag-action.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    PipesModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
  ],
  declarations: [EmailActionComponent, ArchiveFlagActionComponent],
  exports: [EmailActionComponent, ArchiveFlagActionComponent],
})
export class EmailActionModule {}
