import { Flag } from '../../../administration/archive/flags/models/flag';

export const userSettings = [
  'roleId',
  'languageId',
  'endUserRolesId',
  'spamProfileId',
  'outSpamProfileId',
  'virusProfileId',
  'digestProfileId',
  'emailProfileId',
  'outboundEmailProfileId',
];

export interface UserSettingsComponentConfig {
  isCustomer?: boolean;
  isDomain?: boolean;
  isGroup?: boolean;
  isMailbox?: boolean;
  domainId?: string;
  refId?: string;
}
