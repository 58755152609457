import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArchiveMessage, Fields } from '../../models/archive-message';
import { Email } from '../../models/email.model';
import { EmailFilter } from '../../models/email-filter';
import { Flag } from 'src/app/gws/administration/archive/flags/models/flag';
import { TypeReport } from '../../models/type-report';
import { TypeActionArchive } from '../../models/type-action-archive';
import { ActionArchives } from '../../models/action-archives';
import { TypeList } from '../../models/type-list';
import { customerId } from '../../../../core/_helpers/util';
import { StorageService } from '../../../../core/_services/storage.service';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root',
})
export class QuarantineService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) {
  }

  getEmails(emailFilter: EmailFilter): Observable<Email[]> {
    let params = new HttpParams({
      fromObject: {
        page: emailFilter.page.toString(),
      },
    });

    if (emailFilter.subject) {
      params = params.append('subject', emailFilter.subject);
    }

    if (emailFilter.sender) {
      params = params.append('sender', emailFilter.sender);
    }

    if (emailFilter.startDate) {
      params = params.append(
        'startDate',
        this.convertDate(emailFilter.startDate),
      );
    }

    if (emailFilter.endDate) {
      params = params.append('endDate', this.convertDate(emailFilter.endDate));
    }

    if (emailFilter.recipient) {
      params = params.append('recipient', emailFilter.recipient);
    }

    if (emailFilter.flagsAllow || emailFilter.flagsDenny) {
      if (emailFilter.flagsAllow.length > 0) {
        emailFilter.flagsAllow.forEach((f) => {
          params = params.append('allowedFlags[]', f.id.toString());
        });
      }

      if (emailFilter.flagsDenny.length > 0) {
        emailFilter.flagsDenny.forEach((f) => {
          params = params.append('deniedFlags[]', f.id.toString());
        });
      }
    }

    return this.http.get<Email[]>(`/v1/archive-messages/archive-messages`, {
      params,
    });
  }

  getArchiveFlags(): Observable<Flag[]> {
    return this.http
      .get<Flag[]>('/v1/archive-messages/flags')
      .pipe(map((res: any) => res.rows));
  }

  getAttachment(emailId: number, attachName: string): any {
    const name = Base64.encode(attachName);
    return this.http.get(
      '/v1/archive-messages/' + emailId + '/attachments/' + name,
    );
  }

  getEmail(id: number): Observable<Fields> {
    return this.http
      .get<ArchiveMessage>('/v1/archive-messages/archive-messages/' + id)
      .pipe(map((res: ArchiveMessage) => res.fields));
  }

  executeMessageAction(
    messageIds: ActionArchives,
    typeAction: TypeActionArchive,
  ): Observable<any> {
    return this.http.post(
      `v1/archive-messages/${typeAction.toString()}`,
      messageIds,
    );
  }

  removeMessages(messageIds: { messagesIds: string[] }): Observable<any> {
    return this.http.request('delete', `v1/archive-messages`, {
      body: messageIds,
    });
  }

  removeMessage(id: number): Observable<any> {
    const url = `v1/archive-messages/${id}`;
    return this.http.delete(url);
  }

  reportMessages(
    messageIds: { messagesIds: string[] },
    type: TypeReport,
  ): Observable<any> {
    return this.http.post(
      `v1/archive-messages/report-${type.toString()}`,
      messageIds,
    );
  }

  blockedOrTrustedList(
    elasticIds: string[],
    safe: TypeList,
    type: TypeReport,
  ): Observable<any> {
    const list = safe ? 'trusted' : 'blocked';
    return this.http.post('v1/' + list + '-lists/via-tracker/customer/' + customerId(JSON.parse(this.storage.getItem('token'))),
      { elasticIds, safe, type });
  }

  saveFlags(emails): Observable<any> {
    return this.http.post('/v1/archive-messages/set-flags', emails);
  }

  convertDate(date: any): string {
    const month = this.leftPad(date.month.toString(), 2, '0');
    const day = this.leftPad(date.day.toString(), 2, '0');
    return `${date.year}-${month}-${day} 23:59:59`;
  }

  leftPad(str: string, len: number, ch = '.'): string {
    len = len - str.length + 1;
    return len > 0 ? new Array(len).join(ch) + str : str;
  }
}
