import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProfileMenuService } from '../../services/profile-menu/profile-menu.service';

@Component({
  selector: 'app-ui-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  @Output() logoutEvent = new EventEmitter();
  @Input() isOwner: boolean;
  avatar: any;

  constructor(private service: ProfileMenuService) {}

  ngOnInit() {
    this.service.getDataAvatar().subscribe((result) => (this.avatar = result));
  }

  logout() {
    this.service.clearDataAvatar();
    this.logoutEvent.emit();
  }
}
