<ng-container>
  <div
    class="d-flex justify-content-between align-items-center rounded p-3 ml-1 mt-3 mb-2 valid-dns-background"
  >
    <div [ngClass]="{ custom: custom }">
      <span *ngIf="showHelp" class="valid-dns-title text-muted">
        {{ 'validDnsTitle' | translate }}
      </span>
      <br *ngIf="showHelp" />
      <em [ngClass]="{ hash: custom }" #spanElement>{{ dnsHash }}</em>
      <i
        class="far fa-copy ml-2 text-muted pointer"
        (click)="copy()"
        placement="bottom"
        ngbTooltip="{{ 'copied' | translate }}"
        triggers="click:blur"
      ></i>
    </div>
    <div class="" *ngIf="!custom">
      <ng-container *ngIf="!loading">
        <span *ngIf="isDNSValid" class="badge badge-outline-success badge-sm">{{
          'verified' | translate
        }}</span>
        <span *ngIf="!isDNSValid" class="badge badge-outline-danger badge-sm">{{
          'notVerified' | translate
        }}</span>
      </ng-container>

      <span *ngIf="loading" class="badge badge-outline-danger badge-sm">
        <span class="spinner-border spinner-border-sm"></span>
      </span>
    </div>
  </div>
  <div
    *ngIf="showButton"
    [ngClass]="{ 'text-center mt-3': custom, 'my-3': !custom }"
    class="text-right"
  >
    <button
      *ngIf="success === undefined"
      (click)="validateDomainOwnership()"
      type="button"
      class="btn btn-sm"
      [ngClass]="{
        'btn-primary': custom,
        'btn-outline-primary': !custom,
        teste: (loading && custom) || customEvent
      }"
      data-cy="checkDomainModal"
    >
      {{ 'checkDomain' | translate }}
    </button>
    <i *ngIf="success && custom" class="fas fa-check success"></i>
    <i *ngIf="success === false && custom" class="fas fa-times failure"></i>
    <button
      *ngIf="customEvent"
      (click)="hasClicked()"
      type="button"
      class="btn btn-sm btn-outline-primary"
      data-cy="checkDomain"
    >
      {{ 'checkDomain' | translate }}
    </button>
    <span
      *ngIf="loading && custom"
      class="badge badge-outline-primary badge-sm p-2"
    >
      <span class="spinner-border spinner-border-sm"></span>
    </span>
  </div>
</ng-container>
