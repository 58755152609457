import { Component, EventEmitter, HostListener, Output } from '@angular/core';

import { SearchTagModel } from '../../models/email-tracker/search-tag/search-tag.model';
import { EmailFilterTracker } from '../../models/email-filter-tracker';
import { SearchHelpItem } from '../../models/email-tracker/search-help/search-help-item.model';
import { TagMerged } from '../../models/email-tracker/search-tag/tag-merged.model';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import * as Moment from 'moment';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  public tagList: SearchTagModel[] = [];
  public shortTagList: SearchTagModel[] = [];
  public wrap = false;
  @Output() tagContent: EventEmitter<SearchTagModel> = new EventEmitter();
  @Output() search: EventEmitter<EmailFilterTracker> = new EventEmitter();
  @Output() cancelEdit: EventEmitter<any> = new EventEmitter();
  shorter = false;
  expanded: boolean;

  constructor(protected _translate: TranslatePipe) {}

  initialDate;
  start = true;
  editing: {
    index: number;
    array: boolean[];
  } = { index: null, array: [] };

  protected _convertTagListToFilter(): EmailFilterTracker {
    const filter = new EmailFilterTracker();
    this.tagList.forEach((tagItem: any) => {
      if (tagItem instanceof TagMerged) {
        let filterItem =
          tagItem.id === 'released'
            ? 'RELEASED_AT'
            : tagItem.searchItem.searchItem;
        if (filterItem === 'recipient') {
          filterItem = filterItem + 's';
        }
        if (filterItem) {
          if (!filter[filterItem]) {
            filter[filterItem] = tagItem.id || tagItem.complement;
          } else {
            filter[filterItem] += ',';
            filter[filterItem] +=
              filterItem !== 'flags' ? tagItem.complement : tagItem.id;
          }
        }
      } else if (typeof tagItem === 'string') {
        if (!filter.searchAll) {
          filter.searchAll = tagItem;
        } else {
          filter.searchAll += ' ' + tagItem;
        }
      } else if (this.instanceOfDate(tagItem)) {
        filter.startDate = tagItem.start;
        filter.endDate = tagItem.end;
      }
    });

    if (this.tagList.length === 0 || !this.instanceOfDate(this.tagList[0])) {
      filter.startDate = this.initialDate.start;
      filter.endDate = this.initialDate.end;
    }
    return filter;
  }

  tagValue(item: SearchTagModel) {
    if (item instanceof TagMerged) {
      return (
        this._translate.transform(item.display.split(':')[0], 'labels', []) +
        ': ' +
        this._translate.transform(item.display.split(': ')[1], 'labels', [])
      );
    }

    if (item instanceof SearchHelpItem) {
      return item.searchItem;
    }

    if (this.instanceOfDate(item)) {
      return this.formatDate(item);
    }

    return item;
  }

  public formatDate(item) {
    const startDate = Moment(item.start)
      .format('DD/MM/YYYY (HH:mm:ss)')
      .toString();
    const endDate = Moment(item.end).format('DD/MM/YYYY (HH:mm:ss)').toString();
    return startDate + ' - ' + endDate;
  }

  public editTag(i: number) {
    this.editing.array.fill(false);
    if (!this.instanceOfDate(this.tagList[i])) {
      this.tagContent.emit(this.tagList[i]);
      this.editing.array[i] = true;
      this.editing.index = i;
    }
  }

  public deleteTag(i: number) {
    this.tagList.splice(i, 1);
    this.refreshShortTagList();
    setTimeout(() => {
      this.adjustTagPresentation();
    });
    this.search.next(this._convertTagListToFilter());

    if (this.editing.array.filter(Boolean).length) {
      this.editing.array.length = this.tagList.length;
      this.editing.array.fill(false);
      this.cancelEdit.emit();
    }
  }

  public refreshShortTagList() {
    this.shorter = this.tagList.length - this.shortTagList.length === 0;
    if (this.shorter) {
      this.shortTagList = [];
      this.expanded = false;
      this.wrap = false;
    } else {
      this.shortTagList = this.tagList.slice(0, this.shortTagList.length);
    }
  }

  public checkIfTagIsRepeated(item: SearchTagModel): boolean {
    const check = this.tagList.filter(
      (i) => this.tagValue(i) === this.tagValue(item)
    );
    if (check.length && !this.instanceOfDate(item)) {
      this.editing.array.length = this.tagList.length;
      this.editing.array.fill(false);
      return true;
    }
    return false;
  }

  public addingWhenEditing(item: SearchTagModel): void {
    const itemLength = this.tagValue(item);
    if (itemLength.length > 0) {
      if (!this.instanceOfDate(item)) {
        this.tagList[this.editing.index] = item;
      }
    } else {
      this.tagList.splice(this.editing.index, 1);
    }
    this.refreshShortTagList();
  }

  public addingDateTag(item: SearchTagModel): void {
    if (this.instanceOfDate(this.tagList[0])) {
      this.tagList[0] = item;
    } else {
      this.tagList.unshift(item);
      if (this.wrap || this.expanded) {
        this.shortTagList.pop();
        this.shortTagList.unshift(item);
      }
    }
  }

  public addingGeneralTag(item: SearchTagModel): void {
    if (this.instanceOfDate(item) && this.start === true) {
      this.initialDate = { item };
      this.start = false;
    } else {
      this.tagList.push(item);
    }
  }

  public pushItemToList(item: SearchTagModel) {
    if (this.checkIfTagIsRepeated(item)) {
      return;
    }
    if (this.editing.array.filter(Boolean).length) {
      this.addingWhenEditing(item);
    } else if (this.instanceOfDate(item) && this.tagList.length > 0) {
      this.addingDateTag(item);
    } else {
      this.addingGeneralTag(item);
    }
    this.editing.array.length = this.tagList.length;
    this.editing.array.fill(false);
    this.search.next(this._convertTagListToFilter());
    setTimeout(() => {
      this.adjustTagPresentation();
    });
  }

  instanceOfDate(object: any) {
    return !!object.start;
  }

  @HostListener('window:resize')
  adjustTagPresentation() {
    const doc =
      document.getElementById('div1') !== null
        ? document.getElementById('div1')
        : document.getElementById('div2');
    const pai = doc !== null ? doc.parentElement.parentElement : null;
    if (doc && doc.offsetWidth > pai.offsetWidth * 0.8 && !this.expanded) {
      if (this.wrap === false) {
        this.shortTagList = [...this.tagList];
        this.shortTagList.pop();
        this.wrap = true;
        setTimeout(() => {
          this.adjustTagPresentation();
        });
      } else if (doc.offsetWidth > pai.offsetWidth) {
        this.shortTagList.pop();
        setTimeout(() => {
          this.adjustTagPresentation();
        });
      }
    }
    if (doc && doc.offsetWidth < pai.offsetWidth * 0.8) {
      if (this.expanded === true) {
        this.expanded = false;
      } else if (
        this.wrap &&
        doc.offsetWidth < pai.offsetWidth * 0.6 &&
        this.tagList.length - this.shortTagList.length > 1
      ) {
        this.shortTagList.push(this.tagList[this.shortTagList.length]);
        setTimeout(() => {
          this.adjustTagPresentation();
        });
      }
    }
  }

  expand() {
    this.expanded = true;
    this.wrap = false;
  }

  reduce() {
    this.expanded = false;
    this.wrap = true;
  }

  clearTags() {
    this.tagList = [];
    this.shortTagList = [];
    this.wrap = false;
    this.expanded = false;
  }
}
