import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Flag } from 'src/app/gws/administration/archive/flags/models/flag';

import { CurrentFlag } from '../../../models/current-flag.model';
import { EmailFlags } from '../../../models/email-flags';
import { QuarantineService } from '../../../services/quarantine/quarantine.service';
import { ModalActionModel } from '../../../../../core/_models/modal/modal-action.model';

interface ToSaveInterface {
  email: number;
  flags: number[];
  elasticId: string;
}

@Component({
  selector: 'app-archive-flag-action',
  templateUrl: './archive-flag-action.component.html',
  styleUrls: ['./archive-flag-action.component.scss'],
})
export class ArchiveFlagActionComponent implements OnInit {
  @Input() public selectedEmails: EmailFlags[];
  @Output() public valueChange = new EventEmitter();
  @Output() public saveEvent = new EventEmitter();
  public flagLoaded = false;
  public flags: Flag[];
  public showArchiveFlags = [];
  public result: { success: boolean; msg: string } = {
    success: false,
    msg: '',
  };
  private _toSave: ToSaveInterface[] = [];

  constructor(private quarantineService: QuarantineService) {}

  ngOnInit(): void {
    if (this.selectedEmails) {
      this.configToShowArchiveFlags();
      this.valueChange.emit([]);
    }
  }

  public getArchiveFlags(): void {
    this.quarantineService.getArchiveFlags().subscribe((response) => {
      this.flags = response;
    });
  }

  public configToShowArchiveFlags(): void {
    this.quarantineService.getArchiveFlags().subscribe((response) => {
      let allFlags = JSON.parse(JSON.stringify(response));
      allFlags = allFlags.map((flag) => {
        flag.value = this.getValue(parseInt(flag.id));
        return flag;
      });

      this.showArchiveFlags = allFlags;
      this.flagLoaded = true;
    });
  }

  public modalEvent(event) {
    if (event === ModalActionModel.SAVE) {
      this.saveEvent.emit(this._toSave);
    }
  }

  public changeFlags(): void {
    const selectedEmails = this.selectedEmails;

    this._toSave = selectedEmails.map((obj) => {
      const currentFlags = obj.currentFlags;
      let toSave = this.removeFlags(currentFlags);
      toSave = this.addFlags(toSave);
      return {
        email: obj.id,
        flags: toSave.sort((a, b) => a - b),
        elasticId: obj.esId,
      };
    });
    this.valueChange.emit(this._toSave);
  }

  private getValue(id): number {
    let count = 0;

    const disable = 0;
    const enable = 1;
    const legacy = 2;

    this.selectedEmails.forEach((email) => {
      count =
        count +
        email.currentFlags.filter((currentFlag) => currentFlag.id === id)
          .length;
    });

    if (this.selectedEmails.length === count) {
      return enable;
    } else if (count > 0 && this.selectedEmails.length !== count) {
      return legacy;
    }

    return disable;
  }

  private addFlags(currentFlags: number[]): number[] {
    this.managementFlags(1).forEach((flagId) => {
      if (currentFlags.indexOf(flagId) === -1) {
        currentFlags.push(flagId);
      }
    });

    return currentFlags;
  }

  private removeFlags(currentFlags: CurrentFlag[]): number[] {
    const returnFlags = [];

    currentFlags
      .filter((obj) => {
        return this.managementFlags(0).indexOf(obj.id) === -1;
      })
      .forEach((obj) => {
        returnFlags.push(obj.id);
      });

    return returnFlags;
  }

  // 0 - DisabledFlags, 1 - EnabledFlags, 2 - LegacyFlags
  private managementFlags(id): number[] {
    return this.showArchiveFlags
      .map((obj) => {
        if (obj.value === id) {
          return parseInt(obj.id);
        }
      })
      .filter((obj) => obj !== undefined);
  }
}
