import { Component, OnChanges, SimpleChanges } from '@angular/core';

import { ModalTabsBaseDirective } from '../modal-tabs.base';
import { RulesMatched } from '../../../../models/archive-finder';
import { FieldListModel } from 'src/app/core/_ui/models/list/field-list.model';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { ColumnsTypeModel } from '../../../../../shared/list/models/columns-type.model';

export interface VerdictData {
  label: string;
  data: any[];
}

@Component({
  selector: 'app-email-tracker-modal-verdict',
  templateUrl: './verdict.component.html',
  styleUrls: ['./verdict.component.scss'],
})
export class VerdictComponent
  extends ModalTabsBaseDirective
  implements OnChanges {
  public allInfo: VerdictData[] = [];
  public list = new FieldListModel();

  constructor(private _translate: TranslatePipe) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && changes.fields.currentValue.sentence) {
      this._setInfo();
    }
  }

  public get columns(): ColumnsTypeModel[] {
    return [
      { id: 'module', label: 'module' },
      { id: 'rules', label: 'rules' },
    ];
  }

  private _setInfo(): void {
    try {
      const rulesMatched = this.fields.sentence.rulesMatched as RulesMatched;
      Object.keys(rulesMatched).forEach((key: string) => {
        this.allInfo.push({
          label: key,
          data: rulesMatched[key],
        });
      });
    } catch (e) {}

    try {
      this.allInfo.push({
        label: 'action',
        data: this.fields.sentence.actionsExecuted,
      });
    } catch (e) {}

    this._setTable();
  }

  private _setTable(): void {
    this.allInfo.forEach((info) => {
      this.list.rows.push({
        module: this._translate.transform(info.label, 'labels'),
        rules: info.data,
      });
    });
    if (this.fields.archiveData) {
      this.list.rows.push({
        module: 'DB ID',
        rules: this.fields.archiveData.messageId,
      });
    }
    this.list.rows.push({
      module: 'Disposition',
      rules: this.fields.sentence['disposition']['name'],
    });
    this.list.rows.push({
      module: 'Return Code',
      rules: this.fields.sentence['disposition']['message'],
    });
    this.list.rowsCount = this.allInfo.length;
    this.list.totalCount = this.allInfo.length;
    this.list.totalPages = 1;
    this.loading = false;
  }
}
