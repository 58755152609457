import { ElementRef, Injectable, Input, OnInit, Renderer2, Directive } from '@angular/core';
import { ControlValueAccessor, FormGroup } from '@angular/forms';

@Directive()
@Injectable({ providedIn: 'root' })
export class UiBaseDirective implements OnInit, ControlValueAccessor {
  @Input() public id: string;
  @Input() public placeholder = '';
  @Input() public control: FormGroup;
  @Input() public readonly = false;
  public value: any = '';

  protected _element;

  constructor(protected _renderer: Renderer2) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    const self = this;
    this._element.inputElement.nativeElement.addEventListener(
      'change',
      (event) => {
        self._onChange(this.value);
      }
    );
    this._element.inputElement.nativeElement.addEventListener(
      'keyup',
      (event) => {
        self._onChange(this.value);
      }
    );
    this._element.inputElement.nativeElement.addEventListener(
      'blur',
      (event) => {
        self._onTouched();
      }
    );
  }

  public get element(): ElementRef {
    if (this._element instanceof ElementRef) {
      return this._element;
    }

    try {
      return this._element['element'];
    } catch (e) {
      return null;
    }
  }

  public get nativeElement(): any {
    try {
      return this.element.nativeElement;
    } catch (e) {
      return null;
    }
  }

  public onChange(event: any) {
    this._onChange(event.target.value);
  }

  public onKeyup(event: any) {
    this._onChange(event.target.value);
  }

  public onBlur(event: any) {
    this._onTouched();
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (this._element instanceof ElementRef) {
      this._renderer.setProperty(
        this._element.nativeElement,
        'disabled',
        isDisabled
      );
    } else {
      this._element.setDisabledState(isDisabled);
    }
  }

  protected _onChange(_: any) {}
  protected _onTouched() {}
}
