import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieTreatService {

  public deleteCookie(name) {
    const domain = '.gatefy.com';
    const subdomain = window.location.hostname;
    if (name === '_hjid') {
      const a = document.cookie.split('; ');
      a.forEach((x) => {
        if (x.match(/^_hj[^=]*/i)) {
          document.cookie =  x.match(/^_hj[^=]*/i) + '=;domain=' + domain + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
          document.cookie =  x.match(/^_hj[^=]*/i) + '=;domain=' + subdomain + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
      });
    } else if( this.get_cookie( name ) ) {
      document.cookie = name + '=;domain='+ domain +';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  }

  public get_cookie(name){
    return document.cookie.split(';').some(c => {
      return c.trim().startsWith(name + '=');
    });
  }

  public deleteAllCookies() {
    const domain = '.gatefy.com';
    const subdomain = window.location.hostname;
    const cookies =
      document.cookie.split(';').reduce(
        (cookies, cookie) => {
          const [name, val] = cookie.split(
            '=').map(c => c.trim());
          cookies[name] = val;
          return cookies;
        }, {});
    JSON.stringify(cookies);
    const cookieNames = Object.keys(cookies);
    cookieNames.forEach((name) => {
      if (name !== 'cookieconsent_status') {
        document.cookie = name + '=;domain='+ domain +';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = name + '=;domain='+ subdomain +';expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
    });
  }
}
