import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';

export class ModalDefaultOptionsModel implements NgbModalOptions {

  /**
   * If `true`, the backdrop element will be created for a given modal.
   *
   * Alternatively, specify `'static'` for a backdrop which doesn't close the modal on click.
   *
   * Default value is `static`.
   */
  backdrop: boolean | 'static' = 'static';

  /**
   * If `true`, the modal will be closed when `Escape` key is pressed
   *
   * Default value is `false`.
   */
  keyboard = false;

  /**
   * Size of a new modal window.
   *
   * Default value is `lg`.
   */
  size: 'sm' | 'lg' | 'xl' | string = 'lg';

  /**
   * A custom class to append to the modal window.
   *
   * Default value is `modal-xlg`.
   */
  windowClass = 'modal-xlg';
}

export const modalDefaultOptions = new ModalDefaultOptionsModel();
