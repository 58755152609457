import { FormArray } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { OperatorArrayComponent } from 'src/app/core/_ui/components/array/array.component';

@Component({
  selector: 'app-email-redirect',
  templateUrl: './email-redirect.component.html',
  styleUrls: ['./email-redirect.component.scss'],
})
export class EmailRedirectComponent implements AfterViewInit {
  @Input() readonly = false;
  @Output() eventSave = new EventEmitter();

  @ViewChild('array') array: OperatorArrayComponent;

  form: FormArray;

  emails = null;

  constructor() {}

  ngAfterViewInit(): void {
    this.form = this.array?.formData;
  }

  save(event) {
    if (event !== 'save') {
      return;
    }
    this.eventSave.emit(this.array?.formData.value);
  }

  get valid(): boolean {
    return this.array?.formData.value.length > 0;
  }
}
