<div [formGroup]="actionsForm" class="d-inline-block select-wrapper">
  <app-ui-labeled-select
    [formControlName]="'action'"
    [control]="actionsForm"
    [id]="'action'"
    [label]="''"
    [colClass]="'col-sm-12 p-0'"
    [collection]="options"
    [placeholder]="'btn_actions' | translate"
    (change)="onSelectChange()"
  ></app-ui-labeled-select>
</div>
