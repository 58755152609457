import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Moment from 'moment';
import { EmailFilterTracker } from '../../models/email-filter-tracker';
import { SearchTagModel } from '../../models/email-tracker/search-tag/search-tag.model';
import { TagMerged } from '../../models/email-tracker/search-tag/tag-merged.model';
import { EmailActionLocation, EmailActionService } from '../../services/email-action/email-action.service';
import { EmailAction, EmailActionOption } from '../../models/email-action.model';

@Component({
  selector: 'app-email-quick-finder',
  templateUrl: './email-quick-finder.component.html',
  styleUrls: ['./email-quick-finder.component.scss'],
})
export class EmailQuickFinderComponent implements OnInit {
  @ViewChild('tagControl') protected _tag;
  @Output() public filterEvent = new EventEmitter();
  @Output() public releaseSelected = new EventEmitter<EmailAction>();
  @Output() public refresh = new EventEmitter();
  @Input() public massActionsActive = false;
  @Input() public checked: number;
  @Input() public buttonLoading = true;
  @Input() public loading: boolean;
  @Input() public moreThanOneMail = false;

  @ViewChild('tagSearch') protected _tagSearch;

  public filter = new EmailFilterTracker();
  public startDate: Moment.Moment = null;
  public endDate: Moment.Moment = null;
  public returnDateOnStart = true;
  public useCustomDateRanges = true;
  public size;
  public actionsForm: FormGroup = this._formBuilder.group({
    action: [''],
  });

  public actionsCollection: EmailActionOption[] = this._emailActions.getOption(EmailActionLocation.TRACKER_LIST);

  public massActionsCollection = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _emailActions: EmailActionService
  ) {
  }

  ngOnInit() {
    this.size = window.innerWidth >= 0;
    this.massActionsCollection = [...this.actionsCollection];
    this.massActionsCollection.shift();
  }

  @HostListener('window:resize')
  public updateDisplay() {
    this.size = window.innerWidth >= 0;
  }

  public showChecked(): string {
    if (+this.checked > 0) {
      return ' ' + this.checked.toString() + ' ';
    }
    return ' ';
  }

  public refreshList(): void {
    this.refresh.emit();
  }

  public applyDateRange(dateRange): void {
    this.filter.startDate = Moment(dateRange.start);
    this.filter.endDate = Moment(dateRange.end);
    this.sendDataToTag(dateRange);
  }

  public doAction(event: Event): void {
    const form = this.actionsForm.getRawValue();
    this.releaseSelected.emit(form.action);
  }

  public search(filter?: EmailFilterTracker): void {
    if (filter) {
      this.filter.subject = filter.subject;
      this.filter.sender = filter.sender;
      this.filter.senderMTA = filter.senderMTA;
      this.filter.senderHeader = filter.senderHeader;
      this.filter.recipients = filter.recipients;
      this.filter.status = filter.status;
      this.filter.searchAll = filter.searchAll;
      this.filter.startDate = filter.startDate;
      this.filter.endDate = filter.endDate;
      this.filter.flags = filter.flags;
      this.filter.emailAddress = filter.emailAddress;
      this.filter.RELEASED_AT = filter.RELEASED_AT;
    }

    this.filterEvent.emit(this.filter);
  }

  public sendDataToTag(event: SearchTagModel): void {
    this._tag.pushItemToList(event);
  }

  public setTagSearchInputValue(tag: SearchTagModel): void {
    if (tag instanceof TagMerged) {
      this._tagSearch.setOption(tag.searchItem.searchItem);
      this._tagSearch.checkSelect();
      if (this._tagSearch.useSelect) {
        this._tagSearch.setField(tag);
      } else {
        setTimeout(() => {
          this._tagSearch.setValue(tag.complement);
        });
      }
    } else {
      this._tagSearch.setOption('general');
      this._tagSearch.checkSelect();
      setTimeout(() => {
        this._tagSearch.setValue(tag);
      });
    }
  }

  public cancelEdit(): void {
    this._tagSearch.cancelEdit();
  }

  public getFilter(): EmailFilterTracker {
    return this.filter;
  }

  public replaceElement(translation: string, toReplace: string): string {
    return translation.replace('%s', toReplace);
  }

  public emitReleaseSelected(): void {
    this.releaseSelected.emit();
  }
}
