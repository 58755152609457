<td class="list-btn">
  <button
    class="action-btn dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-cy="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="fas fa-ellipsis-h"></i>
  </button>
  <ng-container>
    <div
      class="options-wrapper dropdown-menu"
      aria-labelledby="dropdownMenuButton"
    >
      <ng-container *ngIf="saveMode === false">
        <a
          *ngIf="showForm() && (!showEdit(row) || readonly)"
          (click)="setAction(listAction.SHOW)"
          class="btn gatefy-btn"
        >
          <span class="fas fa-eye btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_show' | translate }}</p>
        </a>
        <a
          *ngIf="showEdit(row) && !readonly"
          (click)="setAction(listAction.EDIT)"
          class="btn gatefy-btn"
          data-cy="btnListEdit"
        >
          <span class="fas fa-pencil-alt btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_edit' | translate }}</p>
        </a>
        <a
          *ngIf="showCopy() || readonly"
          (click)="setAction(listAction.COPY)"
          class="btn gatefy-btn"
          data-cy="btnListCopy"
        >
          <span class="fas fa-copy btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_copy' | translate }}</p>
        </a>
        <a
          (click)="
            setAction({
              event: listAction.IMPORT_NOW,
              connectionType: row.connectionType
            })
          "
          *ngIf="showImport() && !readonly"
          class="btn gatefy-btn"
          data-cy="btnListImport"
        >
          <span class="fas fa-upload btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_import_now' | translate }}</p>
        </a>
        <a
          (click)="
            setAction({
              event: listAction.SEND_DIGEST
            })
          "
          *ngIf="showDigest() && !readonly"
          class="btn gatefy-btn"
          data-cy="btnListImport"
        >
          <span class="fa fa-envelope btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_send_digest' | translate }}</p>
        </a>
        <a
          (click)="
            setAction({
              event: listAction.SIGN_IN,
              connectionType: row.connectionType
            })
          "
          *ngIf="showSignin()"
          class="btn gatefy-btn"
          container="body"
          data-cy="btnListSignIn"
        >
          <span class="fas fa-sign-in-alt btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_sign_in' | translate }}</p>
        </a>
        <a
          *ngIf="showSignInCustomer() && !readonly"
          (click)="setAction(listAction.SIGN_IN_CUSTOMER)"
          class="btn gatefy-btn"
        >
          <span class="fas fa-sign-in-alt btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_sign_in' | translate }}</p>
        </a>
        <a
          *ngIf="showResetPassword() && !readonly"
          (click)="setAction(listAction.RESET_PASSWORD)"
          class="btn gatefy-btn"
          data-cy="btnListResetPassword"
        >
          <span class="fas fa-key btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_reset_password' | translate }}</p>
        </a>
        <a
          *ngIf="showUnlockAdmin() && !readonly"
          (click)="setAction(listAction.UNLOCK_ADMIN)"
          class="btn gatefy-btn"
          data-cy="btnListUnlockAdmin"
        >
          <span class="fas fa-key btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_unlock_admin' | translate }}</p>
        </a>
        <a
          *ngIf="showDelete(row) && !readonly"
          (click)="setAction(listAction.DELETE)"
          class="btn gatefy-btn delete-btn"
          data-cy="btnListDelete"
        >
          <span class="fas fa-times btn-icon"></span>
          <p class="btn-text">{{ 'tooltip_delete' | translate }}</p>
        </a>
      </ng-container>
    </div>
  </ng-container>
</td>
