import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'angular-calendar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { ScheduleComponent } from './components/schedule/schedule.component';
import { PipesModule } from 'src/app/core/_pipes/pipes.module';
import { UiModule } from 'src/app/core/_ui/ui.module';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import { MessageModule } from '../message/message.module';
import { ListModule } from '../list/list.module';
import { ScheduleInnerFormComponent } from './components/schedule-form/schedule-inner-form/schedule-inner-form.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  imports: [
    UiModule,
    NgbModule,
    PipesModule,
    CommonModule,
    ReactiveFormsModule,
    CalendarModule,
    MessageModule,
    ListModule
  ],
  declarations: [
    ScheduleComponent,
    ScheduleFormComponent,
    ScheduleInnerFormComponent
  ],
  exports: [
    ScheduleComponent
  ]
})
export class ScheduleModule { }
