import { TranslatePipe } from '../../../../../core/_pipes/pipes/translate.pipe';

export class SearchHelpItem {
  static TRANSLATE_PIPE: TranslatePipe;

  searchItem: string;

  private _translateBase = 'ET_searchHelp_';
  private _translateEgBase = 'Eg';
  private _searchItemName: string;

  constructor(searchItem: string) {
    this.searchItem = searchItem;
    this._searchItemName = searchItem;
  }

  private _getKey(eg = false) {
    let key = this._translateBase + this._searchItemName;
    if (eg) {
      key += this._translateEgBase;
    }
    return key;
  }

  private _translate(value: string, key: string = 'fe', args: any[] = []): any {
    try {
      return SearchHelpItem.TRANSLATE_PIPE.transform(value, key, args);
    } catch (e) {
      return '';
    }
  }

  get searchText(): string {
    return this._translate(
      this._getKey(),
    );
  }

  get searchTextArray(): string[] {
    return this.searchText.split('%s');
  }

  get searchEg(): string {
    return this._translate(
      this._getKey(true),
    );
  }
}
