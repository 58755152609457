import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OriginalService {

  constructor(protected _http: HttpClient) {
  }

  getOriginal(id) {
    return this._http.get('/v1/archive-messages/' + id + '/original');
  }

}
