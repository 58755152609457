import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CheckDomainService {

  constructor(protected _http: HttpClient) {
  }

  validateDomainOwnership(domain: string) {
    return this._http.post('/v1/inbound/dns-check', {domain});
  }

  getDnsHash() {
    return this._http.get('/v1/inbound/dns-hash');
  }


}
