import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../../../core/_pipes/pipes.module';

import { SearchTextComponent } from './components/search-text/search-text.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { SearchMultipleSelectComponent } from './components/search-multiple-select/search-multiple-select.component';
import { SearchListComponent } from './components/search-list/search-list.component';
import { UiModule } from 'src/app/core/_ui/ui.module';

@NgModule({
  imports: [
    PipesModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UiModule
  ],
  declarations: [
    SearchTextComponent,
    SearchSelectComponent,
    SearchMultipleSelectComponent,
    SearchListComponent,
  ],
  exports: [
    SearchTextComponent,
    SearchSelectComponent,
    SearchMultipleSelectComponent,
    SearchListComponent
  ]
})
export class SearchModule { }
