<div class="no-wrapper form-row mt-2">
  <div class="search-wrapper">
    <app-search-tag-filter
      #tagSearch
      [loading]="loading"
      (emitTag)="sendDataToTag($event)"
      (refresh)="refreshList()"
    >
    </app-search-tag-filter>
  </div>

  <div class="date-button bottom-row-items">
    <app-date-range-filter
      (applyDateRange)="applyDateRange($event)"
      [inputHidden]="size"
      [endDate]="endDate"
      [prependIcon]="true"
      [returnOnStart]="returnDateOnStart"
      [startDate]="startDate"
      [useCustomRanges]="useCustomDateRanges"
      [fromTracker]="true"
      [timePicker]="true"
      openPosition="left"
      parent="div#floatingHeader"
    ></app-date-range-filter>
  </div>

  <div
    [formGroup]="actionsForm"
    class="release-button bottom-row-items align-right"
  >
    <app-ui-labeled-select
      [formControlName]="'action'"
      [control]="actionsForm"
      [id]="'action'"
      [colClass]="'col-sm-12 p-0'"
      [isModal]="true"
      [collection]="moreThanOneMail ? massActionsCollection : actionsCollection"
      [readonly]="!massActionsActive"
      (change)="doAction($event)"
      [placeholder]="'select-action' | translate: 'labels'"
    ></app-ui-labeled-select>
  </div>
</div>

<div id="tag-container" class="tag-container">
  <app-tag
    #tagControl
    (tagContent)="setTagSearchInputValue($event)"
    (cancelEdit)="cancelEdit()"
    (search)="search($event)"
  ></app-tag>
</div>
