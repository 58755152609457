<div [formGroup]="pageForm" class="select-container">
  <app-ui-labeled-select
    [formControlName]="'size'"
    [control]="pageForm"
    [id]="'size'"
    [colClass]="'w-100'"
    [collection]="collection"
    [dropdownWidth]="100"
    (change)="emitAction()"
  ></app-ui-labeled-select>
</div>
