import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';

@Pipe({
  name: 'beautifyData',
})
export class BeautifyDataPipe implements PipeTransform {
  constructor(private _translate: TranslatePipe) {}

  public transform(value: string, ...args: any[]): string {
    if (args[0].isFullDate) {
      return this._getFullDate(value);
    }
    if (args[0].isDate) {
      return this._getDate(value);
    }
    if (args[0].isBoolean) {
      return this._getBoolean(value);
    }

    return value;
  }

  private _getDate(value: string): string {
    const date = Moment(value);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY');
    }

    return value;
  }

  private _getFullDate(value: string): string {
    const date = Moment(value);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY - HH:mm');
    }

    return value;
  }

  private _getBoolean(value: string): string {
    if (value.toLocaleLowerCase() === 'true' || +value === 1) {
      return this._translate.transform('yes', 'labels');
    }

    if (value.toLocaleLowerCase() === 'false' || +value === 0) {
      return this._translate.transform('no', 'labels');
    }

    return value;
  }
}
