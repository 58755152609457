<div class="py-2">
  <app-loading *ngIf="loading; else read"></app-loading>
  <ng-template #read>
    <ng-container *ngIf="(!archived || !hasMessage) && hasPermission">
      <div class="alert alert-warning" role="alert">
        {{ 'ET_not_archived' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="!hasPermission">
      <div class="alert alert-warning" role="alert">
        {{ 'message-permission' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="archived && hasMessage && hasPermission">
      <app-loading *ngIf="!emailLoaded; else readMail"></app-loading>
    </ng-container>
  </ng-template>
</div>
<ng-template #readMail>
  <div class="col-12 p-0 my-3 d-flex">
    <div class="my-auto">
      {{ 'archiving_markings' | translate: 'labels' }}:
      <!-- FLAGS START -->
      <span
        *ngFor="let flag of email.flags; last as isLast"
        [class.mr-1]="!isLast"
        class="flag-body"
      >
        {{ flag.name }}
      </span>
      <span *ngIf="!email.flags || !email.flags.length" class="flag-body">
        --
      </span>
      <!-- FLAGS END -->
    </div>
    <div class="ml-auto">
      <span
        [matTooltipDisabled]="email.canShowOriginal"
        matTooltip="{{ 'original-permission' | translate }}"
      >
        <button
          [disabled]="!email.canShowOriginal"
          (click)="openModal(emailId)"
          class="btn btn-outline-primary mr-3 btn-show-original"
          type="button"
        >
          {{ 'showEmailOriginal' | translate: 'titles' }}
        </button>
      </span>
      <app-email-action
        (valueChange)="onSaveChange($event)"
        [disableDropDown]="true"
        [emailsSelected]="selectedEmails"
        setBtnClass="btn-outline-primary"
      ></app-email-action>
    </div>
  </div>
  <iframe
    #emailFrame
    class="col-12 p-0 scrolling-iframe"
    frameborder="0"
    src="about:blank"
  ></iframe>
  <p
    *ngIf="email.attachments && email.attachments.length > 0"
    class="attachment-header-title"
  >
    {{ 'attachment' | translate: 'labels' }}
  </p>
  <hr
    class="attachment-divider"
    *ngIf="email.attachments && email.attachments.length > 0"
  />
  <div
    class="attachment-container"
    *ngIf="email.attachments && email.attachments.length > 0"
  >
    <ng-container *ngIf="email.attachments[0]['message']">
      <div class="col-12 alert alert-warning" role="alert">
        {{ 'attach-permission' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="!email.attachments[0]['message']">
      <div class="attachment-box" *ngFor="let att of email.attachments">
        <i class="far fa-file-{{ getType(att.name) }}"></i>
        <div class="attachment-text">
          <p class="attachment-title" [title]="att.name">
            {{ att.shortname ?? ('no_name' | translate: 'labels') }}
          </p>
          <p class="attachment-size">{{ formatSize(att.size) }}</p>
          <p class="attachment-link" (click)="downloadAttachment(att.name)">
            Download
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
