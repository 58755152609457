import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslatePipe } from '../../../../core/_pipes/pipes/translate.pipe';
import { SearchHelpItem } from '../../models/email-tracker/search-help/search-help-item.model';
import { searchItems } from '../../models/email-tracker/search-items/search-items.model';

@Component({
  templateUrl: './search-help-modal.component.html',
  styleUrls: ['./search-help-modal.component.scss'],
})
export class SearchHelpModalComponent {

  searchHelpItems: SearchHelpItem[] = searchItems;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    protected _translate: TranslatePipe,
  ) {
    SearchHelpItem.TRANSLATE_PIPE = _translate;
  }

  public textSplitter(input: string): string[] {
    input = this._translate.transform(input, 'fe');
    return input.split('%s');
  }
}
