<div [ngClass]="['form-group', colClass]">
  <label
    [for]="id"
    [attr.data-cy]="id"
    [ngClass]="{
      subtitle: isSubtitle,
      'is-invalid': hasError,
      'd-none': !label,
      'is-required': isRequired
    }"
  >
    {{ label | translate: 'labels' }}
    <i
      *ngIf="tooltip !== false"
      matTooltip="{{ tooltipMessage | translate: 'labels' }}"
      matTooltipPosition="right"
      class="far fa-question-circle ml-1"
    ></i>
  </label>
  <ng-content></ng-content>
</div>
